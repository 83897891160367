import Vue from "vue";
// Use it to import all Vue file containing this folder as Components
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component("home", require("./home").default);

Vue.component("login", require("./account/login").default);
Vue.component("register", require("./account/register").default);
Vue.component("forgot-password", require("./account/forgot-password").default);
Vue.component("reset-password", require("./account/reset-password").default);

Vue.component("pages-404", require("./utility/404").default);
Vue.component("pages-500", require("./utility/500").default);

// Dashboard
Vue.component("home", require("./dashboard/index").default);

// Emails
Vue.component("messages", require("./message/inbox").default);
Vue.component("message-view", require("./message/reademail").default);

// Projects
Vue.component("applications", require("./applications/list").default);
Vue.component("application-edit", require("./applications/edit").default);

// Users
Vue.component("users", require("./contacts/list").default);
Vue.component("user-view", require("./contacts/update").default);
Vue.component("me", require("./contacts/update").default);

// Roles
Vue.component("roles", require("./roles/list").default);
Vue.component("role-create", require("./roles/create").default);
Vue.component("edit-role", require("./roles/update").default);

// Validations
Vue.component("validations", require("./validations/list").default);

// Application Types
Vue.component("application-types", require("./application-types/index").default);

// Files Manager
Vue.component("filesmanager", require('./filesmanager/index').default)
