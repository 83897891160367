const chartsConfig = {
	lineCharts: {
		chart: {
			stacked: true,
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: true,
			},
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "15%",
				endingShape: "rounded",
			},
		},
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: [
				"Jan",
				"Fev",
				"Mars",
				"Avr",
				"Mai",
				"Juin",
				"Juill",
				"Août",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
		},
		legend: {
			position: "bottom",
		},
		fill: {
			opacity: 1,
		},
	},
	donutCharts: {
		chart: {
			type: "donut",
			height: 240
		},
		labels: null,
		colors: [],
		legend: {
			show: false
		},
		plotOptions: {
			pie: {
				donut: {
					size: "70%"
				}
			}
		}
	}
};

export default chartsConfig;