<script>

export default {
  props: {
    section: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sectionsTitles:  {
        'inbox': 'Boîte de réception',
        'favorites': 'Favoris',
        'trash': 'Corbeille',
      },
      sectionName: this.section
    };
  },
  created() {
    console.log(this.section);
  },
  methods: {
    getData(value) {
      if (this.sectionName == value)
        return;
      this.sectionName = value;
      // this.$emit('getEmails', value, this.sectionsTitles[value]);
    }
  }
};
</script>

<template>
  <div class="email-leftbar card">
    <div class="mail-list">
      <router-link to="/messages/inbox" @click.native="getData('inbox')" :class="{ 'active': sectionName === 'inbox'}">
        <i class="far fa-envelope me-2"></i> {{ sectionsTitles['inbox'] }}
      </router-link>
      <router-link to="/messages/favorites" @click.native="getData('favorites')" :class="{ 'active': sectionName === 'favorites'}">
        <i class="far fa-star me-2"></i> {{ sectionsTitles['favorites'] }}
      </router-link>
      <router-link to="/messages/trash" @click.native="getData('trash')" :class="{ 'active': sectionName === 'trash'}">
        <i class="fa fa-trash me-2"></i> {{ sectionsTitles['trash'] }}
      </router-link>
    </div>
  </div>
</template>
