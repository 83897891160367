<script>
import StepProgress from 'vue-step-progress';
import Layout from "../../layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "./component/page-header-with-button";

import 'vue-step-progress/dist/main.css';
import '../../../css/step-progress.css'
import $ from 'jquery'
import alertVue from '../message/templates/alert.vue';


const axios = require('axios');

/**
 * Projects-list component
 */
export default {
  components: { 
    Layout, 
    PageHeader,
    'step-progress': StepProgress,
    Multiselect
  },
  data() {
    return {
      title: "Validations",
      items: [],
      button_label: "Ajouter",
      validations: null,
      test:[],
      currentPage: 1,
      perPage: 10,
      startIndex: 1,
      endIndex: 1,
      showEdit: false,
      currentValidation: null,
      steps: null,
      users:[],
      submitted: false,
      errors: null,
      isUsed: false,
      currentUserPerms: [],
      userPerms:null,
      roleUserPerms:null,
    };
  },
  created(){
     axios.get('/api/validations?page=' + this.currentPage).then(response => {
      this.validations = response.data.data;
      this.startIndex = response.data.from;
      this.endIndex = response.data.to;
      this.perPage = response.data.per_page;
      this.currentPage = response.data.current_page;
      this.total = response.data.total;
    });
    
    axios.get('/api/users/list',{
        params: {
              paginate: false,
          }}).then( response => {
        var usersByRole = [];

      response.data.forEach(item => {
        if (! usersByRole[item.roles[0].name]) {
          usersByRole[item.roles[0].name] = [item];
        } else {
          usersByRole[item.roles[0].name].push(item)
        }
      });

      Object.keys(usersByRole).forEach(key => {
        this.users.push({
          role: key,
          users: usersByRole[key]
        });
      });
    });
    axios.get('/api/user').then( response => {
        this.userPerms = response.data.user_permissions.map(currentUserPerms => currentUserPerms.name);
        this.roleUserPerms = response.data.role_permissions.map(currentUserPerms => currentUserPerms.name);
        this.currentUserPerms = this.userPerms.concat(this.roleUserPerms);
    });
  },
  methods: {
    getSteps(validation) {
      return validation.validation_steps.map(item => item.name);
    },
    addStep() {
      this.steps.push({ name: "", delay: null, is_file_required: 0});
    },
    removeStep(index) {
      if (confirm("Voulez-vous vraiment supprimer cette étape ?"))
        this.steps.splice(index, 1);
    },
    editValidation(validation){
      axios.get('/api/validation/edit/' + validation.id).then(response => {
        this.currentValidation = response.data.validation;
        this.steps = response.data.steps;
        this.isUsed = response.data.validation_values.length > 0;
        if (this.isUsed) {
          this.$toaster.info("Ce workflow de validation est utilisé dans au moins une demande.");
        }
        this.showEdit = true;
      });
       
    },
    updateValidation(){
      if ( this.steps.length < 2) {
        this.$toaster.error("Le workflow de validations n'a pu être modifié.");
        return;
      }
      
      this.submitted = true;
      this.errors = {};

      axios.post('/api/validation/update/' + this.currentValidation.id , {
          name: this.currentValidation.name,
          description: this.currentValidation.description,
          steps: this.steps
        })
        .then(response => {
          this.showEdit = false;
          this.$toaster.success("Le workflow de validation à été mis à jour");
          this.currentValidation = response.data.validation;
          this.currentValidation.steps = response.data.steps.map(step => step.name).toString();

          const index = this.validations.findIndex((item) => item.id === this.currentValidation.id);
          this.validations[index] = this.currentValidation;
        })
        .catch(error => {
          this.errors = error.response.data;
          this.$toaster.error("Le workflow de validations n'a pu être modifié.");
        });
    },
    updateValidationsList(){
      axios.get('/api/validations?page=' + this.currentPage).then(response => {
        this.validations = response.data.data;
        this.startIndex = response.data.from;
        this.endIndex = response.data.to;
        this.perPage = response.data.per_page;
        this.currentPage = response.data.current_page;
        this.total = response.data.total;
      });
    },
    onPageChange() {
      axios.get('/api/validations?page=' + this.currentPage).then(response => {
      this.messagesData = response.data;
      this.messages = this.messagesData.data;
      this.startIndex = this.messagesData.from;
      this.endIndex = this.messagesData.to;
      this.perPage = this.messagesData.per_page;
      this.currentPage = this.messagesData.current_page;
      this.total = this.messagesData.total;
      });
    },
     currentUserCan(namePermission){
      return this.currentUserPerms.includes(namePermission);
    },
  },
};
</script>

<template>
  <div>
    <PageHeader :title="title" :items="items" :button_label="button_label" @updateValidations="updateValidationsList" />
    <div class="row">
      <div class="col-lg-12">
        <div class>
          <div class="table-responsive">
            <table
              class="table project-list-table table-nowrap table-centered table-borderless align-middle">
              <thead>
                <tr>
                  <th scope="col" style="width: 30%;">Nom</th>
                  <th scope="col" style="width: 70%; text-align: center;">Étapes</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="validation in validations" :key="validation.id">
                  <td>
                    <h5 class="text-truncate font-size-14">
                      <a @click="editValidation(validation)" class="text-dark" style="cursor: pointer;">{{ validation.name }}</a>
                    </h5>
                    {{ validation.description }}
                  </td>
                  <td>
                    <div>
                      <step-progress :steps="getSteps(validation)" icon-class="fa fa-check" active-color="#003da5"></step-progress>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row justify-content-md-between align-items-md-center" v-if="validations">
            <div class="col-xl-7">{{startIndex}} - {{endIndex}} of {{total}}</div>
            <!-- end col-->
            <div class="col-xl-5">
              <div class="text-md-end float-xl-end mt-2 pagination-rounded">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="total"
                  :per-page="perPage"
                  @input="onPageChange"
                ></b-pagination>
              </div>
            </div>
            <!-- end col-->
        </div>
        <b-modal size="lg" v-if="currentValidation !== null" v-model="showEdit" title="Modifier workflow de validation" centered>
          <form>
            <div class="mb-3">
              <input :disabled="!currentUserCan('application-update')" type="text"  v-model="currentValidation.name" class="form-control" placeholder="Nom du workflow" />
              <div v-if="submitted && errors !== null" style="color: red;">
                <span v-if="errors.name">{{ errors.name[0] }}</span>
              </div>
            </div>
            <div class="mb-3">
              <input :disabled="!currentUserCan('application-update')" type="text"  v-model="currentValidation.description" class="form-control" placeholder="Description du worflow" />
              <div v-if="submitted && errors !== null" style="color: red;">
                <span v-if="errors.description">{{ errors.description[0] }}</span>
              </div>
            </div>
            <h6 style="margin-top: 30px;margin-bottom: 20px;">Étapes</h6>
            <div>
              <div v-for="(step, index) in steps" :key="step.id" class="row" style="margin-bottom: 20px;">
                <div class="mb-3">
                  <input :disabled="!currentUserCan('application-update')" id="name" type="text" v-model="step.name" class="form-control" placeholder="Nom de l'étape" required :readonly="isUsed"/>
                  <div v-if="submitted && errors !== null" style="color: red;">
                    <span v-if="errors['steps.'+ index + '.name']">{{ errors['steps.'+ index + '.name'][0] }}</span>
                  </div>
                </div>
                <div class="mb-3">
                  <input :disabled="!currentUserCan('application-update')" id="delay" type="number" v-model="step.delay" class="form-control" placeholder="Délai" required :readonly="isUsed"/>
                  <div v-if="submitted && errors !== null" style="color: red;">
                    <span v-if="errors['steps.'+ index + '.delay']">{{ errors['steps.'+ index + '.delay'][0] }}</span>
                  </div>
                </div>
                <div class="mb-3">
                  <multiselect 
                  class="col"
                  :show-labels="false" 
                  label="full_name" 
                  placeholder="Choix de l'utilisateur" 
                  v-model="step.users" 
                  :multiple="true"
                  track-by="id"
                  :taggable="true"
                  group-values="users"
                  group-label="role"
                  :options="users" required :disabled="isUsed || !currentUserCan('application-update')">
                  </multiselect>
                </div>
                <div class="mb-3">
                  <b-form-checkbox class="mb-3" v-model="step.is_file_required" value=1 unchecked-value=0 checked plain :disabled="isUsed || !currentUserCan('application-update')">
                    Fichier requis
                  </b-form-checkbox>
                </div>
                <div class="col-lg-2 align-self-center">
                    <div class="d-grid">
                      <input  type="button" class="btn btn-primary btn-block" value="Supprimer" @click="removeStep(index)" :disabled="isUsed || !currentUserCan('application-update')"/>
                    </div>
                </div>
              </div>
            </div>
            <input
              type="button"
              class="btn btn-success mt-3 mt-lg-0"
              value="Ajouter"
              @click="addStep"
              :disabled="isUsed || !currentUserCan('application-update')"
            />
          </form>
          <template v-slot:modal-footer>
            <b-button variant="secondary" @click="showEdit = false">Fermer</b-button>
            <b-button :disabled="!currentUserCan('application-update')" variant="primary" @click="updateValidation()">Enregistrer</b-button>
          </template>
        </b-modal>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>
