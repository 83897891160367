<script>
import Layout from "../../layouts/main";
import PageHeader from "../../base/page-header.vue"
import lineChartsWidget from "@modules/Application/Resources/js/components/widgets/line-charts.vue";
import donutChartsWidget from "@modules/Application/Resources/js/components/widgets/donut-charts.vue";
import inProgressList from "@modules/Application/Resources/js/components/widgets/in-progress-list.vue";

export default {
	components: {
		Layout,
		PageHeader,
		inProgressList,
		donutChartsWidget,
		lineChartsWidget
	},
	data() {
		return {
			title: "Tableau de bord",
		}
	},

}
</script>
<template>
	<div>
		<page-header :title="title"/>
		<div class="row">
			<div class="col-xl-4">
				<donut-charts-widget />
			</div>
			<div class="col-xl-8">
				<line-charts-widget />
				<div class="card">
					<div class="card-body">
						<in-progress-list />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
