<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

import axios from 'axios';

/**
 * Contacts-Profile component
 */
export default {
  components: { Layout, PageHeader},
  props: {
        id: {
          type: String,
          required: false,
        },
    },
  created(){
       axios.get('/api/menus').then( response => {
         this.menus = response.data;
       } )
  },
  methods : {
     addRole(){
      axios.post('/api/roles/store', {role : this.name, permissions: this.selectedPermissions } )
      .then( response => {
           if(response.status == 200){
            this.$toaster.success(response.data.message);
            this.fields = []
            window.location.href = '/roles'

          }
        }
      ).catch((error) => {
       this.$toaster.error(error.response.data.message);
       this.fields = error.response.data.reason;
      });
    },
    
    save(permission) {
      if ((this.selectedPermissions.filter(element => element == permission)).length > 0) {
        this.selectedPermissions = this.selectedPermissions.filter(element => element != permission);
      }
      else {
        this.selectedPermissions.push(permission);
      }
    },
    can(permissionName) {
       return this.selectedPermissions.includes(permissionName);
    },
     haveError(label){
      if(this.fields.hasOwnProperty(label)){
       return true;
      }
    },
    showError(label){
       return this.fields[label][0];
    }
  },

  data() {
    return {
      fields: [],
      reason:null,
      response: null,
      roleData:null,
      permissionData:null,
      menus: null,
      allPermissions: [],
      permissions: [],
      selectedPermissions: [],
      items: [
        {
          text: "Roles",
          to: "/roles"
        },
        {
          text: "-",
          active: true
        }
      ],
      statData: [
        {
          icon: "bx bx-check-circle",
          title: "Completed Projects",
          value: "125"
        },
        {
          icon: "bx bx-hourglass",
          title: "Pending Projects",
          value: "12"
        },
        {
          icon: "bx bx-package",
          title: "Total Revenue",
          value: "$36,524"
        }
      ],
      options: [
        "Administrateur",
        "Observateur",
        "Commercial",
        "Responsable juridique",
        
      ],
      status: [
        "Actif",
        "Archivé",        
      ],
       defaultPerms : [
        {
          name :'Lire',
          en_name :'read',
        },
        {
          name :'Ajouter',
          en_name :'create',
        },
        {
          name :'Modifier',
          en_name :'update',
        },
        {
          name :'Assigner',
          en_name :'assigne',
        },
        {
          name :'Archiver',
          en_name :'archive',
        },
        {
          name :'Supprimer',
          en_name :'delete',
        },
      ],
      title: "Role & permissions",
      role:null,
      name: null,
    };
  }
};
</script>
<template>
  <div>
    <PageHeader :title="title" :items="items" />
  <div class="row" >
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-form>
              <b-form-group
                class="mb-4"
                label="Role *"
                label-for="horizontal-email-input"
                label-cols-sm="3"
              >
                <b-form-input
                  id="horizontal-register-id-input"
                  type="text"
                  name ="role"
                  v-model="name"
                ></b-form-input>
                 <div v-if="haveError('role')">
                    <p class="error-message">{{showError('role')}}</p>
                 </div>
              </b-form-group>
              <div class="row justify-content-end">
                 <div class="position-relative form-group">
                    <label for="title" class="">Permissions *</label>
                    <div class="row" v-if="menus">
                      <div class="col-sm-10">
                          <table class="table table-bordered">
                              <tr style="background: #f4f4f4; text-align: center">
                                  <td></td>
                                  <td style="text-align: center" v-for="defaultPerm in defaultPerms" :key="defaultPerm.name" >{{defaultPerm.name}} </td>
                              </tr>
                              <tr v-for="menu in menus" :key="menu.id">
                                  <td style="padding-left: 1rem;">{{ menu.title }}</td>
                                  <td style="text-align: center">
                                    <i v-if="menu.have_read == false" 
                                      class="far fa-times-circle icone-shape red">
                                    </i>
                                    <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                    <i v-else-if="can(menu.shortname + '-read') && selectedPermissions.includes(menu.shortname + '-read') " @click.prevent="save(menu.shortname + '-read')"
                                      class="far fa-check-circle icone-shape green">
                                    </i>
                                    <i v-else @click.prevent="save(menu.shortname + '-read')"
                                      class="far fa-check-circle icone-shape">
                                    </i>
                                  </td>
                                  <td style="text-align: center">
                                    <i v-if="menu.have_create == false" 
                                      class="far fa-times-circle icone-shape red">
                                    </i>
                                    <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                    <i v-else-if="can(menu.shortname + '-create')  && selectedPermissions.includes(menu.shortname + '-create') " @click.prevent="save(menu.shortname + '-create')"
                                      class="far fa-check-circle icone-shape green">
                                    </i>
                                    <i v-else @click.prevent="save(menu.shortname + '-create')"
                                      class="far fa-check-circle icone-shape">
                                    </i>
                                  </td>
                                  <td style="text-align: center">
                                    <i v-if="menu.have_update == false" 
                                     class="far fa-times-circle icone-shape red">
                                    </i>
                                    <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                    <i v-else-if="can(menu.shortname + '-update') && selectedPermissions.includes(menu.shortname + '-update') " @click.prevent="save(menu.shortname + '-update')"
                                     class="far fa-check-circle icone-shape green">
                                    </i>
                                    <i v-else @click.prevent="save(menu.shortname + '-update')"
                                      class="far fa-check-circle icone-shape">
                                    </i>
                                  </td>
                                  <td style="text-align: center">
                                    <i v-if="menu.have_assign == false" 
                                      class="far fa-times-circle icone-shape red">
                                    </i>
                                    <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                    <i v-else-if="can(menu.shortname + '-assign') && selectedPermissions.includes(menu.shortname + '-assign')" @click.prevent="save(menu.shortname + '-assign')"
                                      class="far fa-check-circle icone-shape green">
                                    </i>
                                    <i v-else @click.prevent="save(menu.shortname + '-assign')"
                                      class="far fa-check-circle icone-shape">
                                    </i>
                                  </td>
                                  <td style="text-align: center">
                                    <i v-if="menu.have_archive == false" 
                                     class="far fa-times-circle icone-shape red">
                                    </i>
                                    <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                    <i v-else-if="can(menu.shortname + '-archive') && selectedPermissions.includes(menu.shortname + '-archive')" @click.prevent="save(menu.shortname + '-archive')"
                                      class="far fa-check-circle icone-shape green">
                                    </i>
                                    <i v-else  @click.prevent="save(menu.shortname + '-archive')"
                                      class="far fa-check-circle icone-shape">
                                    </i>
                                  </td>
                                  <td style="text-align: center">
                                    <i v-if="menu.have_delete == false" 
                                      class="far fa-times-circle icone-shape red">
                                    </i>
                                    <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                    <i v-else-if="can(menu.shortname + '-delete') && selectedPermissions.includes(menu.shortname + '-delete')" @click.prevent="save(menu.shortname + '-delete')"
                                      class="far fa-check-circle icone-shape green">
                                    </i>
                                    <i v-else @click.prevent="save(menu.shortname + '-delete')"
                                      class="far fa-check-circle icone-shape">
                                    </i>
                                  </td>
                              </tr>
                                  
                                  
                          </table>
                          <div v-if="haveError('permissions')">
                              <p class="error-message">{{showError('permissions')}}</p>
                          </div>
                      </div>
                      <div class="col-sm-2" style="margin : auto;" >
                        <div class="mb-2">
                            <i class="far fa-check-circle icone-shape green" ></i> Valeur profil
                        </div>
                        <!-- <div class="mb-2">
                            <i class="far fa-check-circle" style="color: #231F5B; font-size: 18px; font-weight: bold"></i> Valeur utilisateurs
                        </div> -->
                        <div  class="mb-2">
                            <i class="far fa-check-circle icone-shape" ></i> Désactivé
                        </div>
                        <div  class="mb-2">
                            <i class="far fa-times-circle icone-shape red" ></i> Indisponible
                        </div>
                    </div>
                   </div>
                  </div>
                  <div class="col-sm-9" style="margin-top : 20px;">  
                    <b-button style="margin-left:70%;" @click.prevent="addRole" variant="primary">Valider</b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style scoped>
  .icone-shape {
    font-size: 18px; 
    font-weight: bold;
    cursor: pointer;
  }
  .red {
     color: red;
  }
  .green{
    color: #008000;
  }
  .error-message{
     color: red;
  }
</style>
