<script>
const axios = require('axios');
import Swal from "sweetalert2";

import Layout from "../../../layouts/main";

/**
 * Email-inbox component
 */
export default {
  components: {
    Layout,
  },
  props: {
    // section: {
    //   type: String,
    //   required: false,
    // },
  },
  data() {
    return {
      sectionsTitles:  {
        'inbox': 'Boîte de réception',
        'favorites': 'Favoris',
        'trash': 'Corbeille',
      },
      sectionTitle: "Boîte de réception",
      title: "Inbox",
      items: [
        {
          text: "Email",
          href: "/",
        },
        {
          text: "Inbox",
          active: true,
        },
      ],
      messagesData: null,
      messages: null,
      section: 'inbox',
      total: 0,
      // page number
      currentPage: 1,
      // default page size
      perPage: null,
      emailIds: [],
      // start and end index
      startIndex: 0,
      endIndex: null,
    };
  },
  created() {
    if (typeof this.$route.params.section != 'undefined')
      this.section = this.$route.params.section;

    axios.get('/api/messages?page=' + this.currentPage + '&section=' + this.section).then(response => {
      this.messagesData = response.data;
      this.messages = this.messagesData.data;
      this.startIndex = this.messagesData.from;
      this.endIndex = this.messagesData.to;
      this.perPage = this.messagesData.per_page;
      this.currentPage = this.messagesData.current_page;
      this.total = this.messagesData.total;
    });
  },
  methods: {
    setStar(id){
      axios.get('/api/message/update/' + id + '/star').then(response => {
        let message = response.data;

        if ((this.section == 'favorites') && (message.is_favory == false)) {
           this.messages = this.messages.filter( item => item.id != message.id);
           console.log(this.messages);
        }
        else {
          this.messages = this.messages.map((item) => {
            if (item.id === message.id)
              return {...item, is_favory: message.is_favory};
            return item;
          });
        }
      });
    },
    toTrash(id){
      Swal.fire({
        title: "Êtes-vous sûr.e ?",
        showCancelButton: true,
        confirmButtonColor: "#003da5",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler"
      }).then(result => {
        if (result.value) {
          axios.get('/api/message/update/' + id + '/trash').then(response => {
            this.messages = this.messages.filter((item) => item.id !== id);
          })
          .catch(error => {
            this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
          });
        }
      });
    },
    onPageChange() {
      axios.get('/api/messages?page=' + this.currentPage + '&section=' + this.section).then(response => {
        this.messagesData = response.data;
        this.messages = this.messagesData.data;
        this.startIndex = this.messagesData.from;
        this.endIndex = this.messagesData.to;
        this.perPage = this.messagesData.per_page;
        this.currentPage = this.messagesData.current_page;
        this.total = this.messagesData.total;
      });
    },
    updateEmailsList(section, title) {
      this.section = section;
      this.sectionTitle = title;
      this.currentPage = 1;
      axios.get('/api/messages?page=' + this.currentPage + '&section=' + this.section).then(response => {
        this.messagesData = response.data;
        this.messages = this.messagesData.data;
        this.startIndex = this.messagesData.from;
        this.endIndex = this.messagesData.to;
        this.perPage = this.messagesData.per_page;
        this.currentPage = this.messagesData.current_page;
        this.total = this.messagesData.total;
      });
    }
  },
};
</script>

<template>
  <div class="email-rightbar mb-3">
    <div class="card">
      <div class="btn-toolbar p-3">
        <h4>{{ sectionsTitles[this.section] }}</h4>
      </div>
      <div class="mt-1">
        <ul class="message-list">
          <li
            :class="{ 'unread': email.read_at === null }"
            v-for="(email,index) in messages"
            :key="index">
              <div class="row" style="padding: 0 1.5rem;">
                <div class="col-1">
                  <a v-if="section != 'trash'" href="javascript: void(0);" @click="setStar(email.id)">
                    <span 
                    :class="{'star-toggle': true, 'far fa-star': !email.is_favory, 'fa fa-star': email.is_favory }"
                    :style="{ 'color': email.is_favory ? '#ffd000': '#a4a4a4', 'padding-right': '0.25rem' }"></span>
                  </a>
                  <a v-if="section != 'trash'" href="javascript: void(0);" @click="toTrash(email.id)">
                    <span 
                    class="star-toggle fa fa-trash" 
                    style="color: #a4a4a4"></span>
                  </a>
                </div>
                <div class="col-11">
                  <router-link :to="`/messages/view/${email.id}`">
                    <div class="row" style="display: flex;">
                      <div class="col-4 title">{{ email.from_sender.dgdi_id + ' - ' + email.from_sender.firstname + ' ' + email.from_sender.lastname }}</div>
                      <div class="col-6 subject">{{email.object}}</div>
                      <div class="col-2">{{ email.created_at | moment("DD MMM YYYY") }}</div>
                    </div>
                  </router-link>
                </div>
              </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row justify-content-md-between align-items-md-center">
      <div class="col-xl-7">{{startIndex}} - {{endIndex}} sur {{total}}</div>

      <div class="col-xl-5">
        <div class="text-md-end float-xl-end mt-2 pagination-rounded">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            @input="onPageChange"
          ></b-pagination>
        </div>
      </div>

    </div>
  </div>
</template>
