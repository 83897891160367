<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const axios = require('axios');
import Swal from "sweetalert2";

import Layout from "../../../layouts/main";
import Sidepanel from "../sidepanel";

/**
 * Email-inbox component
 */
export default {
  components: {
    Layout,
    Sidepanel,
  },
  data() {
    return {
      sectionTitle: "Boîte de réception",
      title: "Inbox",
      items: [
        {
          text: "Email",
          href: "/",
        },
        {
          text: "Inbox",
          active: true,
        },
      ],
      messagesData: null,
      messages: null,
      section: 'inbox',
      total: 0,
      // page number
      currentPage: 1,
      // default page size
      perPage: null,
      emailIds: [],
      // start and end index
      startIndex: 0,
      endIndex: null,
    };
  },
  created() {
    axios.get('/api/messages?page=' + this.currentPage + '&section=' + this.section).then(response => {
      this.messagesData = response.data;
      this.messages = this.messagesData.data;
      this.startIndex = this.messagesData.from;
      this.endIndex = this.messagesData.to;
      this.perPage = this.messagesData.per_page;
      this.currentPage = this.messagesData.current_page;
      this.total = this.messagesData.total;
    });
  },
  methods: {
    setStar(id){
      axios.get('/api/message/update/' + id + '/star').then(response => {
        let message = response.data;
        this.messages = this.messages.map((item) => {
          if (item.id === message.id)
            return {...item, is_favory: message.is_favory};
          return item;
        });
      });
    },
    toTrash(id){
      Swal.fire({
        title: "Êtes-vous sûr.e ?",
        showCancelButton: true,
        confirmButtonColor: "#003da5",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler"
      }).then(result => {
        if (result.value) {
          axios.get('/api/message/update/' + id + '/trash').then(response => {
            this.messages = this.messages.filter((item) => item.id !== id);
          })
          .catch(error => {
            this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
          });
        }
      });
    },
    onPageChange() {
      axios.get('/api/messages?page=' + this.currentPage + '&section=' + this.section).then(response => {
        this.messagesData = response.data;
        this.messages = this.messagesData.data;
        this.startIndex = this.messagesData.from;
        this.endIndex = this.messagesData.to;
        this.perPage = this.messagesData.per_page;
        this.currentPage = this.messagesData.current_page;
        this.total = this.messagesData.total;
      });
    },
    updateEmailsList(section, title) {
      this.section = section;
      this.sectionTitle = title;
      this.currentPage = 1;
      axios.get('/api/messages?page=' + this.currentPage + '&section=' + this.section).then(response => {
        this.messagesData = response.data;
        this.messages = this.messagesData.data;
        this.startIndex = this.messagesData.from;
        this.endIndex = this.messagesData.to;
        this.perPage = this.messagesData.per_page;
        this.currentPage = this.messagesData.current_page;
        this.total = this.messagesData.total;
      });
    }
  },
};
</script>

<template>
  <div class="email-rightbar mb-3">
    <div class="card">
      <div class="btn-toolbar p-3">
        <h4>{{ sectionTitle }}</h4>
      </div>
      <div class="mt-1">
        <ul class="message-list">
          <li
            :class="{ 'unread': email.read_at === null }"
            v-for="(email,index) in messages"
            :key="index"
          >
            <a :href="`/message/view/${email.id}`">
              <div class="col-mail col-mail-1" style="padding-left: 1rem;">
                <a href="javascript: void(0);" @click="setStar(email.id)">
                  <span 
                  :class="{'star-toggle': true, 'far fa-star': !email.is_favory, 'fa fa-star': email.is_favory }"
                  :style="{ 'color': email.is_favory ? '#ffd000': '#a4a4a4', 'padding-right': '0.25rem' }"></span>
                </a>
                <a v-if="section != 'trash'" href="javascript: void(0);" @click="toTrash(email.id)">
                  <span 
                  class="star-toggle fa fa-trash" 
                  style="color: #a4a4a4"></span>
                </a>
                <a class="title">{{ email.from_sender.dgdi_id + ' - ' + email.from_sender.firstname + ' ' + email.from_sender.lastname }}</a>
              </div>
              <div class="col-mail col-mail-2">
                <a :href="`/message/view/${email.id}`" class="subject">{{email.object}}</a>
                <div class="date">{{ email.created_at | moment("DD MMM YYYY") }}</div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row justify-content-md-between align-items-md-center">
      <div class="col-xl-7">{{startIndex}} - {{endIndex}} sur {{total}}</div>
      <!-- end col-->
      <div class="col-xl-5">
        <div class="text-md-end float-xl-end mt-2 pagination-rounded">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            @input="onPageChange"
          ></b-pagination>
        </div>
      </div>
      <!-- end col-->
    </div>
  </div>
</template>
