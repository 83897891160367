<script>
import Layout from "../../layouts/main";
import PageHeaderWithButtonRole from "../roles/component/page-header-with-button";
const axios = require('axios');

import VueRouter from 'vue-router';

/**
 * Contacts-list component
 */
export default {
  name:'parentComponent',
  components: { Layout, PageHeaderWithButtonRole },
  //get data from API
  created(){
    axios.get('/api/roles')
    .then(response => {
      this.roles = response.data;
    }).catch((error) => {
       this.$toaster.error(error.response.data.message);
      });
    
  },
  data() {
    return {
      roles : null,
      title: "Roles",
      button_label: "Ajouter",
      showModal: false,
    };
  }
};
</script>

<template>
  <div>
    <PageHeaderWithButtonRole :title="title" :button_label="button_label" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover align-middle">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Roles</th>
                    <th scope="col">Nombre d'utilisateur</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="role in roles" :key="role.id">
                    <td>
                        <span style="font-weight: 800;"></span>
                        <router-link :to="`edit/role/${role.id}`" class="text-dark">{{role.name}}</router-link>
                    </td>
                    <td>
                        <span style="font-weight: 800;"></span>
                        <div  class="text-dark">{{role.users}}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="row justify-content-md-between align-items-md-center" v-if="roles">
              <div class="col-xl-7">{{startIndex}} - {{endIndex}} of {{total}}</div>
             end col
             <div class="col-xl-5">
              <div class="text-md-end float-xl-end mt-2 pagination-rounded">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="perPage"
                    @input="onPageChange"
                  ></b-pagination>
                </div>
              </div>
            end col
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
