<script>
const axios = require('axios');

/**
 * Applications component
 */
export default {
  created() {
    axios.get('/api/dashboard/applications?page=' + this.currentPage).then(response => {
      this.applications = response.data.data;
    });
  },
  data() {
    return {
      applications: [],
    };
  },
};
</script>

<template>
  <div>
    <h4 class="card-title mb-4">Demandes en cours</h4>
    <div class="table-responsive mb-0">
      <table class="table table-centered table-nowrap align-middle">
        <thead class="table-light">
          <tr>
            <th class="align-middle">#</th>
            <th class="align-middle">Type</th>
            <th class="align-middle">Objet</th>
            <th class="align-middle">Étape</th>
            <th class="align-middle">Status</th>
          </tr>
        </thead>
        <tbody>
          <router-link :to="`application/edit/${application.id}`" v-for="application in applications"
            :key="application.id" tag="tr">
            <td>
              <a :href="`application/edit/${application.id}`" style="font-weight: 700;">
                {{ application.metadata.numero }}
              </a>
            </td>
            <td>{{ application.type }}</td>
            <td>{{ application.metadata.objet }}</td>
            <td>{{ (application.validation_step.order + 1) + ' - ' + application.validation_step.name }}</td>
            <td>{{ application.status }}</td>
          </router-link>
        </tbody>
      </table>
    </div>
  </div>
</template>
