<script>
import Multiselect from "vue-multiselect";

const axios = require('axios')
const _ = require('lodash')

export default {
  components: {
    Multiselect,
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    access: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      tags: [],
      searchedTags: [],
      submitting: false
    }
  },
  created() {
    this.fetch()
  },
  computed: {
    hasTags() {
      return this.finalTags.length > 0
    },
    finalTags() {
      return this.tags.filter(tag => !tag.isTemporary)
    }
  },
  methods: {
    fetch() {
      this.loading = true;
      axios.get(`/api/filesmanager/tags/${this.element.id}`)
        .then(response => {
          console.log('response data', response.data)
          this.tags = response.data;
        })
        .catch(error => {
          console.log(error)
          alert('Erreur innatendue');
        })
        .finally(() => {
          this.loading = false
        })
    },
    search: _.debounce(function(query) {
      if(this.submitting) {
        return;
      }
      const vm = this;

      axios.get('/api/filesmanager/tags/search', {
        params: {
          q: query
        }
      })
          .then(response => {
            vm.searchedTags = response.data
          })
          .catch(error => {
            console.log(error)
            alert('Erreur innatendue');
          })
    }, 300),
    postTags(tags){
      this.submitting = true;

      return axios.post('/api/filesmanager/tags/' + this.element.id, {
        tags
      })
          .then(response => {
            this.tags = response.data
          })
          .catch(error => {
            console.log(error)
            alert('Erreur innatendue');
            throw error;
          }).finally(() => {
            this.submitting = false;
          })
    },
    addTag(newTag) {
      console.log('add tag', newTag)
      if(this.submitting) {
        return;
      }

      if(this.tags.some(t => t.name === newTag)) {
        return;
      }
      this.tags.push({name: newTag, slug: newTag.trim().toLowerCase(), isTemporary: true})
    },
    onSelect(tag) {
      console.log('on select', tag)
      if(this.tags.some(t => t.id === tag.id)) {
        return;
      }
      this.tags.push({...tag, isTemporary: true})
    },
    removeTag(tag) {
      console.log('remove tag', tag)
      this.tags = this.tags.filter(t => t.slug !== tag.slug)
    },
    handleCloseModal() {
      this.$bvModal.hide('manage-tags')
      this.searchedTags = []
    },
    handleSubmitTags() {
      this.postTags(this.tags.map(tag => tag.name)).then(() => {
        this.handleCloseModal()
      })
    }
  }
}
</script>

<template>
  <div>
    <h6 class="font-size-16 d-flex flex-row justify-content-between align-items-center">
      <span>Étiquettes</span>
      <b-button
        variant="primary"
        size="sm"
        class="font-size-12"
        v-if="access.has_write_access"
        @click="$bvModal.show('manage-tags')"
        >
        Gérer les étiquettes
      </b-button>
    </h6>
    <div v-if="!hasTags && !loading" class="p-2 text-center">
      <p>Aucune étiquette</p>
    </div>
    <div v-if="loading" class="d-flex min-h-100p justify-content-center align-items-center">
      <b-spinner type="grow" variant="primary" v-if="loading"/>
    </div>
    <div v-if="hasTags" class="mt-3">
      <span class="badge bg-info rounded-pill me-1 font-size-13" v-for="tag in finalTags" :key="tag.id">
        {{ tag.name}}
      </span>
    </div>
    <b-modal id="manage-tags" click-outside-except hide-footer hide-header>
      <h5>Gérer les étiquettes</h5>
      <hr>
      <multiselect
          :value="tags"
          :options="searchedTags"
          :multiple="true"
          :taggable="true"
          :searchable="true"
          :internal-search="false"
          :close-on-select="false"
          :options-limit="30"
          :limit="5"
          label="name"
          track-by="slug"
          tag-placeholder="Ajouter une étiquette"
          @search-change="search"
          @tag="addTag"
          @remove="removeTag"
          @select="onSelect"
      />
      <hr>
      <div>
        <button class="btn btn-secondary" @click="handleCloseModal" :disabled="submitting">Annuler</button>
        <button class="btn btn-danger" @click="handleSubmitTags" :disabled="submitting">
          Soumettre
          <b-spinner v-if="submitting" small class="ms-1" />
        </button>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>

</style>
