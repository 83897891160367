<script>
import axios from "axios";
import headerWithButton from "./base/page-header-with-button.vue";
import careerItemsList from "./career-items-list.vue";
import careerItemForm from "./career-item-form.vue";

export default {
    components: { headerWithButton, careerItemsList, careerItemForm },
    props: {
        couldUpdate: {
            type: Boolean,
            required: true
        }
    },
    name: "career",
    data() {
        return {
            moduleTitle: "Carrière",
            title: "Évolution professionnelle",
            buttonLbl: "Ajouter",
            careerItems: [],
            currentCareerItem: null,
            showCareerItemForm: false,
            selectedCareerItem: null
        };
    },
    created() {
        if (this.routeIsMe(this.$route.path))
            return

        this.getCareerItems();
    },
    watch: {
        $route(to, from) {
            if (this.routeIsMe(to.path))
                return
        }
    },
    methods: {
        routeIsMe(path){
            if (path == "/me") {
                this.currentCareerItem = null;
                this.careerItems = [];
                return true
            }
            return false
        },
        resetForm() {
            this.selectedCareerItem = null;
        },
        getCareerItems() {
                axios.get(`/api/user/${this.$route.params.id}/career/`)
                .then((response) => {
                    if (response.data.length > 0) {
                        this.currentCareerItem = response.data.splice(0, 1)[0];
                        this.careerItems = response.data;
                    }
            });
        },
        editCareerItem(item){
            this.selectedCareerItem = item;
            this.displayCareerItemForm();
        },
        addCareerItem(item) {
            console.log(item);
            this.careerItem = item;
            axios.post(`/api/user/${this.$route.params.id}/career/update/${this.careerItem.id}`, {
                department: this.careerItem.department,
                job_title: this.careerItem.job_title,
                salary: this.careerItem.salary,
                start_dt: this.careerItem.start_dt,
                end_dt: this.careerItem.end_dt,
            }).then((response) => {
                if (response.status == 200) {
                    this.$toaster.success(response.data.message);
                    this.displayForm = false;
                    this.resetForm();
                }
            }).catch((error) => {
                this.$toaster.error(error.response.data.message);
                this.fields = error.response.data.reason;
                });
        },
        displayCareerItemForm(){
            this.showCareerItemForm = true;
        },
        hideCareerItemForm(){
            this.showCareerItemForm = false;
            this.resetForm();
        }
    },
};
</script>
<template>
    <b-tab :title="moduleTitle">
        <headerWithButton :isDisabled="couldUpdate" :title="title" :button_label="buttonLbl" @displayCareerItemForm="displayCareerItemForm()"/>
        <div v-if="currentCareerItem">
            <div class="card">
                <div class="row">
                    <div class="col-10 current-career-item">
                        <div class="">
                            <div class="sub-title">{{ currentCareerItem.start_dt }} -  {{ (currentCareerItem.end_dt == null) ? "En cours" : currentCareerItem.end_dt }}</div>
                            <div class="jobtitle">{{ currentCareerItem.job_title }}</div>
                            <div class="departement">{{ currentCareerItem.department.name }}</div>
                            <div v-if="currentCareerItem.manager" class="manager"><span>Gestionnaire : </span> {{ currentCareerItem.manager }}</div>
                        </div>
                    </div>
                    <div class="col-2 current-career-item-action">
                        <a v-if="!couldUpdate" class="edit-button" @click="editCareerItem(currentCareerItem)"><i class="fas fa-edit"></i></a>
                    </div>
                </div>
            
            </div>
            <careerItemsList v-if="careerItems.length > 0" :careerItems="careerItems" @updateItem="getCareerItems()"/>
        </div>
        <careerItemForm v-if="showCareerItemForm" :selectedCareerItem="selectedCareerItem" :showForm="showCareerItemForm" 
            @hideForm="hideCareerItemForm"
            @careerItemAdded="getCareerItems()"/>
    </b-tab>
</template>

<style lang="scss" scoped>
    .current-career-item {
        padding: 30px 30px;

        .sub-title {
            font-weight: 600;
            font-size: 1rem;
            margin-bottom: .4rem;
        }

        .jobtitle {
            font-weight: 600;
            font-size: 1.2rem;
            margin-bottom: .2rem;
        }

        .departement {
            font-weight: 500;
            font-size: 1rem;
            margin-bottom: .2rem;
        }

        .manager {
            font-weight: 400;
            font-size: 1rem;
            margin-bottom: .4rem;
        }
    }

    .current-career-item-action {
        position: relative;
        font-size: 1.3rem;
        float: right;

        .edit-button {
            color: inherit;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 4rem;
            cursor: pointer;
        }
    }

    .history-career-items {
        padding: 20px;
    }

    
</style>