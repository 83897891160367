<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";



import axios from 'axios';

/**
 * Contacts-Profile component
 */

export default {
  components: { Layout, PageHeader},
  props: {
        id: {
          type: String,
          required: false,
        },
    },
  created(){
    axios.get('/api/roles/edit/'+this.$route.params.id)
    .then( response => {
      this.menus = response.data.menus;
      this.role = response.data.role;
      this.allPermissions = response.data.permissions.map(permission => permission.name);
      this.selectedPermissions = response.data.role.permissions.map(permission => permission.name);
      this.name = response.data.role.name;
      this.permissionData = response.data.role.permissions;
    }).catch((error) => {
       this.$toaster.error(error.response.data.message);
       this.fields = error.response.data.reason;
      });
      axios.get('/api/user').then( response => {
        this.userPerms = response.data.user_permissions.map(currentUserPerms => currentUserPerms.name);
        this.roleUserPerms = response.data.role_permissions.map(currentUserPerms => currentUserPerms.name);
        this.currentUserPerms = this.userPerms.concat(this.roleUserPerms);
    });
  },
  methods : {
    save(permission) {
      if ((this.selectedPermissions.filter(element => element == permission)).length > 0) {
        this.selectedPermissions = this.selectedPermissions.filter(element => element != permission);
      }
      else {
        this.selectedPermissions.push(permission);
      }
    },
    can(permissionName) {
       return this.selectedPermissions.includes(permissionName);
    },

    updateRole(){
      axios.post('/api/roles/update/'+ this.$route.params.id, {role : this.name, permissions: this.selectedPermissions} )
      .then( response => {
           if(response.status == 200){
            this.$toaster.success(response.data.message);
            this.fields = [];
            // window.location.href = '/roles'
          }
        }
      ).catch((error) => {
       this.$toaster.error(error.response.data.message);
       this.fields = error.response.data.reason;
      });
    },
     deleteRole(){
       Swal.fire({
        title: "Êtes-vous sûr.e ?",
        text: "Le rôle sera supprimé.",
        showCancelButton: true,
        confirmButtonColor: "#003da5",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler"
      }).then( result => {
        if(result.value){
           axios.post('/api/roles/delete/'+ this.$route.params.id )
            .then( response => {
                if(response.status == 200){
                  this.$toaster.success(response.data.message);
                  window.location.href = '/roles'
                }
              }
            ).catch((error) => {
            this.$toaster.error(error.response.data.message);
            this.fields = error.response.data.reason;
            
            });
        }
      })
    },
    haveError(label){
      if(this.fields.includes(label)){
       return true;
      }
      return this.reason = null;
    },
    showError(label){
       return this.fields[label][0];
    },
    currentUserCan(namePermission){
      return this.currentUserPerms.includes(namePermission);
    }
  },

  data() {
    return {
      fields: [],
      reason:null,
      menus: null,
      allPermissions: [],
      permissions: [],
      selectedPermissions: [],

      response: null,
      roleData:null,
      permissionData:null,
      defaultPerms : [
        {
          name :'Lire',
          en_name :'read',
        },
        {
          name :'Ajouter',
          en_name :'create',
        },
        {
          name :'Modifier',
          en_name :'update',
        },
        {
          name :'Assigner',
          en_name :'assigne',
        },
        {
          name :'Archiver',
          en_name :'archive',
        },
        {
          name :'Supprimer',
          en_name :'delete',
        },
      ],
      title: "Role & permissions",
      role:null,
      name: null,
      items: [
        {
          text: "Roles",
          to: "/roles"
        },
        {
          text: "-",
          active: true
        }
      ],
      status: [
        "Actif",
        "Archivé",        
      ],
      currentUserPerms: [],
      userPerms:null,
      roleUserPerms:null,
    };
  }
};
</script>

<template>
  <div>
    <PageHeader :title="title" :items="items" />
  <div class="row" >
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-form>
              <b-form-group
                class="mb-4"
                label="Role"
                label-for="horizontal-email-input"
                label-cols-sm="3"
              >
                <b-form-input
                  id="horizontal-register-id-input"
                  type="text"
                  name ="role"
                  v-model="name"
                  :disabled="!currentUserCan('role-update')"
                ></b-form-input>
                <div v-if="haveError('role')">
                  <p class="error-message">{{showError('role')}}</p>
                </div>
              </b-form-group>
              <div class="row justify-content-end">
                 <div class="position-relative form-group">
                    <label for="title">Permissions</label>
                    <div class="row" v-if="menus">
                      <div class="col-sm-10">
                          <table class="table table-bordered">
                              <tr style="background: #f4f4f4; text-align: center">
                                  <td></td>
                                  <td style="text-align: center" v-for="defaultPerm in defaultPerms" :key="defaultPerm.name" >{{defaultPerm.name}} </td>
                              </tr>
                              <tr v-for="menu in menus" :key="menu.id">
                                  <td style="padding-left: 1rem;">{{ menu.title }}</td>
                                  <td style="text-align: center">
                                    <div v-if="currentUserCan('role-update')">
                                      <i v-if="menu.have_read == false" 
                                        class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-read')  && selectedPermissions.includes(menu.shortname + '-read') " @click.prevent="save(menu.shortname + '-read')"
                                        class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else @click.prevent="save(menu.shortname + '-read')"
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                    <div v-else>
                                      <i v-if="menu.have_read == false" 
                                        class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-read')  && selectedPermissions.includes(menu.shortname + '-read') "
                                        class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                  </td>
                                  <td style="text-align: center">
                                    <div v-if="currentUserCan('role-update')">
                                      <i v-if="menu.have_create == false" 
                                        class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-create')  && selectedPermissions.includes(menu.shortname + '-create') " @click.prevent="save(menu.shortname + '-create')"
                                        class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else @click.prevent="save(menu.shortname + '-create')"
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                    <div v-else>
                                      <i v-if="menu.have_create == false" 
                                        class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-create')  && selectedPermissions.includes(menu.shortname + '-create') "
                                        class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                  </td>
                                  <td style="text-align: center">
                                    <div v-if="currentUserCan('role-update')">
                                      <i v-if="menu.have_update == false" 
                                      class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-update') && selectedPermissions.includes(menu.shortname + '-update') " @click.prevent="save(menu.shortname + '-update')"
                                      class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else @click.prevent="save(menu.shortname + '-update')"
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                    <div v-else>
                                      <i v-if="menu.have_update == false" 
                                      class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-update') && selectedPermissions.includes(menu.shortname + '-update') "
                                      class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else 
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                  </td>
                                  <td style="text-align: center">
                                    <div v-if="currentUserCan('role-update')">
                                      <i v-if="menu.have_assign == false" 
                                        class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-assign') && selectedPermissions.includes(menu.shortname + '-assign')" @click.prevent="save(menu.shortname + '-assign')"
                                        class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else @click.prevent="save(menu.shortname + '-assign')"
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                    <div v-else>
                                      <i v-if="menu.have_assign == false" 
                                        class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-assign') && selectedPermissions.includes(menu.shortname + '-assign')"
                                        class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else 
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                  </td>
                                  <td style="text-align: center">
                                    <div v-if="currentUserCan('role-update')">
                                       <i v-if="menu.have_archive == false" 
                                        class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-archive') && selectedPermissions.includes(menu.shortname + '-archive')" @click.prevent="save(menu.shortname + '-archive')"
                                        class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else  @click.prevent="save(menu.shortname + '-archive')"
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                    <div v-else>
                                      <i v-if="menu.have_archive == false" 
                                        class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-archive') && selectedPermissions.includes(menu.shortname + '-archive')" 
                                        class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else 
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                   
                                  </td>
                                  <td style="text-align: center">
                                    <div v-if="currentUserCan('role-update')">
                                      <i v-if="menu.have_delete == false" 
                                        class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-delete') && selectedPermissions.includes(menu.shortname + '-delete')" @click.prevent="save(menu.shortname + '-delete')"
                                        class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else @click.prevent="save(menu.shortname + '-delete')"
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                    <div v-else>
                                      <i v-if="menu.have_delete == false" 
                                        class="far fa-times-circle icone-shape red">
                                      </i>
                                      <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                                      <i v-else-if="can(menu.shortname + '-delete') && selectedPermissions.includes(menu.shortname + '-delete')"
                                        class="far fa-check-circle icone-shape green">
                                      </i>
                                      <i v-else 
                                        class="far fa-check-circle icone-shape">
                                      </i>
                                    </div>
                                  </td>
                              </tr>
                                  
                                  
                          </table>
                          <div v-if="haveError('permissions')">
                            <p class="error-message"  >{{showError('permissions')}}</p>
                          </div>
                      </div>
                      <div class="col-sm-2" style="margin : auto;" >
                        <div class="mb-2">
                            <i class="far fa-check-circle icone-shape green" ></i> Valeur profil
                        </div>
                        <!-- <div class="mb-2">
                            <i class="far fa-check-circle" style="color: #231F5B; font-size: 18px; font-weight: bold"></i> Valeur utilisateurs
                        </div> -->
                        <div  class="mb-2">
                            <i class="far fa-check-circle icone-shape" ></i> Désactivé
                        </div>
                        <div  class="mb-2">
                            <i class="far fa-times-circle icone-shape red" ></i> Indisponible
                        </div>
                    </div>
                   </div>
                  </div>
                  <div class="col-sm-9" style="margin-top : 20px;">  
                    <b-button :disabled="!currentUserCan('role-delete')" style="margin-left: 57%;" @click.prevent="deleteRole" variant="danger">Supprimer</b-button>
                    <b-button :disabled="!currentUserCan('role-update')" style="margin-left: 20px;" @click.prevent="updateRole" variant="primary">Valider</b-button> 
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style scoped>
  .icone-shape {
    font-size: 18px; 
    font-weight: bold;
    cursor: pointer;
  }
  .red{
     color: red;
  }
  .green{
    color: #008000;
  }
  .error-message {
   color: red;
 }
</style>
