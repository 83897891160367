<script >
import { useFilemanagerUploadStore } from '../../stores/filemanager-upload';
import { computed } from "@vue/composition-api";
import CircularProgressBar from "./circular-progress-bar.vue";
import MimeIcon from "./mime-icon.vue";
import DownloadStatusListItem from "./download-status-list-item.vue";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const store = useFilemanagerUploadStore();
  const formattedTitle = computed(() => {
    if (store.hasUploadStatus) {
      return "Téléchargement en cours";
    }
    return "Téléchargement terminé";
  });
  function close() {
    if (store.hasUploadStatus) {
      this.$bvModal.show('close-modal');
      return;
    }
    store.clearAll();
  }
  function handleCloseModal() {
    this.$bvModal.hide('close-modal');
    store.clearAll();
  }
  function handleCancelItemUpload(id) {
    store.cancelUpload(id);
  }
  return {
    store,
    formattedTitle,
    close,
    handleCloseModal,
    handleCancelItemUpload
  };
};
__sfc_main.components = Object.assign({
  DownloadStatusListItem
}, __sfc_main.components);
export default __sfc_main;
</script>

<template>
<div class="upload-status-box" v-if="!store.isEmpty">
  <b-card no-body  style="max-width: 20rem;" class="mb-0 shadow-lg">
    <template #header>
      <div class="d-flex align-items-center flex-row justify-content-between">
        <strong class="my-2 font-weight-bold text-truncate">{{formattedTitle}}</strong>
        <div>
          <a
              data-toggle="collapse"
              v-b-toggle.upload-status-box-body
              role="button">
            <i
                class="mdi mdi-chevron-down accor-up-icon font-size-24"
            ></i>
          </a>
          <a role="button" class="ms-2" @click="close">
            <i
                class="mdi mdi-close font-size-22"
            ></i>
          </a>
        </div>
      </div>
    </template>
    <b-collapse visible id="upload-status-box-body">
      <ul class="list-unstyled m-0 uploaded-items">
        <DownloadStatusListItem
            v-for="item in store.items"
            :key="item.id"
            :on-stop-click="() => handleCancelItemUpload(item.id)"
            :item="item"
        />
      </ul>
    </b-collapse>
  </b-card>
  <b-modal id="close-modal" hide-footer hide-header>
    <h5>Arrêter tous les téléchargements</h5>
    <p>Voulez-vous arrêter tous les chargements en cours ?</p>
    <hr>
    <div>
      <button class="btn btn-secondary" @click="$bvModal.hide('close-modal')">Annuler</button>
      <button class="btn btn-danger" @click="handleCloseModal">Arrêter</button>
    </div>
  </b-modal>
</div>
</template>

<style scoped>
  .upload-status-box {
    position: fixed;
    z-index: 999;
    bottom: 0;
    right: 56px;
    min-width: 20rem;
  }
  .upload-status-box ul {
    max-height: 80vh;
    overflow-y: auto;
  }
  .upload-status-box > .card {
    border: 1px solid #CCCCCC;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }
  .upload-status-box a.collapsed i.accor-up-icon:before {
    content: "\F0143";
  }
  .upload-status-box .uploaded-items li:hover {
    background-color: #f5f5f5;
  }
</style>