import { render, staticRenderFns } from "./list-item-element.vue?vue&type=template&id=5b5fac2a&scoped=true&"
import script from "./list-item-element.vue?vue&type=script&lang=js&"
export * from "./list-item-element.vue?vue&type=script&lang=js&"
import style0 from "./list-item-element.vue?vue&type=style&index=0&id=5b5fac2a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5fac2a",
  null
  
)

export default component.exports