import { url } from "vuelidate/lib/validators";

export const menuItems = [
    {
        id: 1,
        label: 'menuitems.dashboard.text',
        // icon: 'bx-home-circle',
        link: '/',
    },
    {
        id: 2,
        label: 'menuitems.documents.text',
        // icon: 'bx-collection',
        link: '/applications',
    },
    {
        id: 3,
        label: 'menuitems.messages.text',
        // icon: 'bx-message-square-dots',
        link: '/messages',
    },
    {
        id: 4,
        label: 'menuitems.settings.text',
        // icon: 'bx-tone',
        isUiElement: true,
        subItems: [
            {
                id: 5,
                label: 'menuitems.settings.list.users',
                link: '/users',
                parentId: 4
            },
            {
                id: 6,
                label: 'menuitems.settings.list.roles',
                link: '/roles',
                parentId: 4
            },
            // {
            //     id: 7,
            //     label: 'menuitems.settings.list.documenttypes',
            //     link: '/',
            //     parentId: 4
            // },
            {
                id: 8,
                label: 'menuitems.settings.list.documentworflows',
                link: '/validations',
                parentId: 4
            },
            {
                id: 9,
                label: 'menuitems.settings.list.logs',
                link: '/',
                parentId: 4
            },
        ]
    },
];

