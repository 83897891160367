<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const axios = require('axios');
import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import Sidepanel from "./sidepanel";

import { emailData } from "./data-inbox";

/**
 * Email-inbox component
 */
export default {
  components: {
    Layout,
    Sidepanel,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      sectionTitle: "Boîte de réception",
      emailData: emailData,
      paginatedEmailData: emailData,
      title: "Inbox",
      section: "inbox",
      total: 0,
      items: [
        {
          text: "Email",
          href: "/",
        },
        {
          text: "Inbox",
          active: true,
        },
      ],
      message: null,
      author: null
    };
  },
  computed: {
    rows() {
      return this.emailData.length;
    },
  },
  created() {
    axios.get('/api/message/view/' + this.$route.params.id).then(response => {
      this.message = response.data;
      this.author = this.message.from_sender;
    });
  },
  methods: {
  },
};
</script>

<template>
  <div class="row">
    <!-- Right Sidebar -->
    <div class="col-12">
      <div class="email-rightbar mb-3" v-if="message">
        <div class="card">
          <div class="card-body">
              <div><h4>{{ message.object }}</h4></div>
              <div class="media mb-4">
                <div class="media-body">
                  <h5 class="font-size-14 mt-1">
                    {{ author.dgdi_id + " - " + author.lastname.toUpperCase() + ' ' + author.firstname + " <" + author.email + ">" }}
                  </h5>
                  <small class="text-muted">{{ message.created_at | moment("DD MMM YYYY") }}</small>
                </div>
              </div>
              <div v-html="message.content"> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
