<script >
import axios from "axios";
import {objectToFormData} from "../../../utils";
import Swal from "sweetalert2";

/**
 * List Item Element component
 */
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: false,
    },
    click: {
      type: Function,
      required: false,
    },
    dbclick: {
      type: Function,
      required: false,
    },
    unselect: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      timeOutId: null,
      focused: false,
      newName: this.name,
      processing: false,
      error: null,
    }
  },
  computed: {
    isFolder() {
      return this.type === 'folder'
    },
    renameModalId() {
      return `rename-modal-${this.id}`
    },
    deleteModalId() {
      return `delete-modal-${this.id}`
    },
    openActionName() {
      return this.isFolder ? 'Ouvrir' : 'Télécharger'
    },
    hasError() {
      return this.error !== null
    },
    elementType() {
      return this.isFolder ? 'dossier' : 'fichier'
    },
  },
  methods: {
    onClick() {
      if (this.timeOutId !== null) {
        clearTimeout(this.timeOutId)
        this.timeOutId = null
        this.dbclick && this.dbclick()
      } else {
        this.timeOutId = setTimeout(() => {
          this.timeOutId = null
          this.click && this.click()
          this.onFocus()
        }, 200)
      }
    },
    onFocus() {
      console.log('focus')
      this.focused = true
    },
    onBlur() {
      this.focused = false
      this.unselect && this.unselect()
    },
    handleCancelRename() {
      this.newName = this.name
      this.error = null
      this.$bvModal.hide(this.renameModalId)
    },
    handleValidateRename() {
      this.processing = true
      this.error = null

      const id = this.id;

      console.log('id', id)

      const url = '/api/filesmanager/rename/' + id;

      axios.post(url, objectToFormData({
        name: this.newName
      }, {
        'Content-Type': 'application/json'
      })).then(response => {
        this.$toaster.success('Le ' + this.elementType + ' a été renommé avec succès !')
        this.processing = false
        this.$bvModal.hide(this.renameModalId)
        this.$emit('reload', 'renamed-element')
        this.$bvModal.hide(this.renameModalId)
      }).catch(error => {
        if(error.response && error.response.status === 403) {
          this.$toaster.error('Vous n\'avez pas les permissions nécessaires pour faire cette action.')
        }
        this.processing = false
        this.error = error.response.data.message
      })
    },
    handleCancelDelete() {
      this.error = null
      this.$bvModal.hide(this.deleteModalId)
    },
    handleDelete() {
      this.processing = true;
      this.error = null;

      const id = this.id;

      const url = '/api/filesmanager/delete/' + id;

      axios.post(url, objectToFormData({}), {
        'Content-Type': 'application/json'
      }).then(response => {
        this.$toaster.success('Le ' + this.elementType + ' a été supprimé avec succès !')
        this.processing = false
        this.$bvModal.hide(this.deleteModalId)
        this.$emit('reload', 'deleted-element')
      }).catch(error => {
        if(error.response && error.response.status === 403) {
          this.$toaster.error('Vous n\'avez pas les permissions nécessaires pour faire cette action.')
        }
        this.processing = false
        this.error = error.response.data.message
      })
    }
  }
}
</script>

<template>
  <div class="d-flex flex-row justify-content-between align-items-center file-component"
       v-bind:class="{ 'focused': focused}"
       @click="onClick"
       v-click-outside="onBlur"
    >
    <div>
      <a
          href="javascript: void(0);"
          class="text-dark fw-medium"
      >
        <i
          v-if="isFolder"
          class="bx bxs-folder font-size-16 align-middle text-warning me-2"
      ></i>
        <i v-if="!isFolder" class="mdi mdi-text-box font-size-16 align-middle text-primary me-2" />
        {{name}}</a
      >
    </div>
    <div>
      <b-dropdown
          toggle-class="font-size-16 text-muted p-0"
          variant="white"
          menu-class="dropdown-menu-end"
          right
      >
        <template #button-content>
          <i class="mdi mdi-dots-horizontal"></i>
        </template>

        <b-dropdown-item href="#" @click="$emit('open')">{{openActionName}}</b-dropdown-item>
        <b-dropdown-item href="#" @click="$bvModal.show(renameModalId)">Renommer</b-dropdown-item
        >
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item href="#" @click="$bvModal.show(deleteModalId)">Supprimer</b-dropdown-item
        >
      </b-dropdown>
    </div>
    <b-modal :id="renameModalId" hide-footer hide-header>
      <h5>Renommer</h5>
      <hr>
      <div class="form-group">
        <input type="text" class="form-control" id="rename-input" placeholder="Nouveau nom" v-model="newName" autofocus autocapitalize="words">
      </div>
      <br>
      <div>
        <button class="btn btn-secondary" @click="handleCancelRename">Annuler</button>
        <button class="btn btn-info" @click="handleValidateRename" :disabled="processing">
          Renommer
          <b-spinner v-if="processing" small class="ms-1" />
        </button>
      </div>
      <div v-if="hasError">
        <br>
        <p class="text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal :id="deleteModalId" hide-footer hide-header>
      <h5>Supprimer</h5>
      <p>Voulez-vous vraiment supprimer ce {{elementType}} ?</p>
      <hr>
      <div>
        <button class="btn btn-secondary" @click="handleCancelDelete">Annuler</button>
        <button class="btn btn-danger" @click="handleDelete">
          Supprimer
          <b-spinner v-if="processing" small class="ms-1" />
        </button>
      </div>
      <div v-if="hasError">
        <br>
        <p class="text-danger">{{error}}</p>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.file-component {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 8px 8px;
  border-radius: 4px;
  border: 1px solid transparent;
}
.file-component:hover {
  background-color: #f6f6f6;
}
.file-component.focused {
  border-color: #003da5;
}
</style>