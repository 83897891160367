<script>
import axios from 'axios';
import Toaster from 'v-toaster';
import Multiselect from "vue-multiselect";

/**
 * Page-header component
 */
export default {
  components: { Multiselect },
  data() {
    return {
			showForm: false,
      description: null,
      reason: null,
			due_at: null,
			application: null,
			currentApplicationName: null,
			alerts: [],
			alert: null,
			reasons: [
				"Congés maladie",
				"Mission",
				"Autre"
			],
			errors: null,
      submitted: false,
    };
  },
  created(){
    axios.get('/api/application/'+ this.id +'/alerts/')
    .then(response => {
			this.alerts = response.data.alerts;
      this.application = response.data.application;
      this.currentApplicationName  = this.application.metadata.numero  + " - " + this.application.metadata.objet;
    })
    .catch( error => {
      this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
    });
  },
  methods:{
		displayForm() {
			this.showForm = !this.showForm;
		},
		resetFormFields() {
			this.reason = null;
			this.description = null;
			this.due_at = null;
			this.submitted = false;
    },
		save() {
			this.submitted = true;
			axios.post('/api/application/'+this.id +'/alert/save', {
          reason: this.reason,
          description: this.description,
          due_at: this.due_at
			})
			.then(response => {
				this.alerts = response.data;
				this.showForm = false
				this.resetFormFields();
			})
			.catch( error => {
				this.errors = error.response.data;
        this.$toaster.error("L'alerte n'a pu être enregistrée.");
			});
		},
		deleteAlert(id) {
			axios.get('/api/alert/delete/'+ id)
			.then(response => {
				this.alerts = response.data;
			})
			.catch( error => {
				this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
			});
		},
		switchState(alert) {
			axios.get('/api/alert/update/'+ alert.id + '/status')
			.then(response => {
				console.log("truc");
				let alert = response.data;
        this.alerts = this.alerts.map((item) => {
          if (item.id === alert.id)
            return {...item, is_active: alert.is_active};
          return item;
        });
			})
			.catch( error => {
				console.log(error);
				this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
			});
		},
		onLoadFile(e){
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length)
						return;
				this.file = e.target.files[0];
				this.name = this.file.name;
		},
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      required: true,
    },
  },
}
</script>

<template> 
	<div>
		<b-form-input
				id="horizontal-firstname-input"
				type="text"
				name="current-app-id"
				v-model="currentApplicationName"
				disabled
				style="margin-bottom: 10px;">
		</b-form-input>
		<div v-if="alerts.length == 0" class="mb-3" style="color: #A9A9A9; font-weight: 600; min-height: 2rem;">
			Aucune alerte programmée pour cette demande
		</div>
		<div v-else>
			<table class="table table-centered table-nowrap">
				<thead class="table-light">
					<tr>
						<th class="align-middle" style="width: 50%">Motif</th>
						<th class="align-middle" style="width: 20%">Date</th>
						<th class="align-middle" style="width: 20%">Statut</th>
						<th class="align-middle" style="width: 10%">Action</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="alert in alerts" :key="alert.id" style="height: 2rem;">
						<td>{{ alert.reason }}</td>
						<td>{{ alert.due_at | moment("DD MMM YYYY") }}</td>
						<td>
							<div class="form-check form-switch mb-3">
								<input
									class="form-check-input"
									type="checkbox"
									id="flexSwitchCheckChecked"
									@change="switchState(alert)"
									:checked="alert.is_active"
								/>
							</div>
						</td>
						<td>
							<button
								id="vertical-menu-btn"
								type="button"
								class="btn btn-sm"
								@click="deleteAlert(alert.id)"
								:disabled="!alert.is_custom">
								<i class="fas fa-trash"></i>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<button type="button" class="btn btn-primary mb-3" @click="displayForm()">Ajouter</button>
		<form v-if="application && showForm">
			<div class="mb-3" style="margin-bottom: 10px;">
				<multiselect 
					class="col" 
					:show-labels="false"
					placeholder="Choisir un motif"
					v-model="reason"
					:options="reasons">
				</multiselect>
				<div v-if="submitted && errors !== null" style="color: red;">
          <span v-if="errors.reason">{{ errors.reason[0] }}</span>
        </div>
			</div>
			<textarea class="form-control mb-3" v-model="description" maxlength="1000" rows="3" placeholder="Donner plus d'informations"></textarea>
			<div class="mb-3">
				<input type="date" v-model="due_at" class="form-control"/>
				<div v-if="submitted && errors !== null" style="color: red;">
          <span v-if="errors.due_at">{{ errors.due_at[0] }}</span>
        </div>
      </div>
			<div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="save()">Enregistrer</button>
    	</div>
  	</form>
	</div>
  
</template>
