<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
const axios = require('axios');
/**
 * Login component
 */
export default {
  components: {
    ckeditor: CKEditor.component,
    Multiselect
  },
  data() {
    return {
      dgdi_id: null,
      password: null,
      tryingToLogIn: false,
      isAuthError: false,
      // Help
      object:null,
      content: null,
      options: [
        "Mot de passe perdu",
        "Déclarer une anomalie du système"
      ],
      showHelp: false,
      editor: ClassicEditor,
      subject: null,
      config: {
        placeholder: 'Décrivez le problème rencontré...'
      },
      submitted: false,
      errors: null
    };
  },
  props: {
    submitUrl: {
      type: String,
      required: true,
    },
    authError: {
      type: String,
      required: false,
      default: () => null,
    },
    email: {
      type: String,
      required: false,
      default: () => null
    }
  },
  mounted() {
    this.isAuthError = !!this.authError;
  },
  methods: {
    sendMessage() {
      this.submitted = true;
      axios.post('/api/message/store', {
          dgdi_id: this.dgdi_id,
          object: this.subject,
          content: this.content
        })
        .then( response => {
          this.showHelp = false;
          this.$toaster.info("Message envoyé");
        })
        .catch(error => {
          if (parseInt(error.response.status) === 401){
            this.errors = error.response.data;
          }
          else
            this.$toaster.error(error.response.data);
        });
    },
  },
};
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5">
      <div class="card overflow-hidden">
        <div class="" style="background-color: #ebebf4;">
          <div class="row">
            <div class="col-7">
              <div class="text-primary p-4">
                <h5 class="text-primary"></h5>
                <p></p>
              </div>
            </div>
            <div class="col-5 align-self-end">
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <div>
            <a href="/">
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="/images/logo-dgdi-2.png" alt height="72" />
                </span>
              </div>
            </a>
          </div>

          <b-form class="p-2" :action="submitUrl" method="POST">
            
            <div v-if="isAuthError" style="margin-bottom: 0.5rem;">
              <div class="alert alert-danger" role="alert">
                  <span style="color: #8A1111 ;" >{{ authError }}</span>
              </div>
            </div>

            <slot />
            <b-form-group
              id="input-group-1"
              label="Matricule"
              label-for="input-1"
              class="mb-3"
            >
              <b-form-input
                id="input-1"
                name="dgdi_id"
                v-model="dgdi_id"
                type="text"
                placeholder="Entrez votre matricule"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Mot de passe"
              label-for="input-2"
              class="mb-3"
            >
              <b-form-input
                id="input-2"
                v-model="password"
                name="password"
                type="password"
                placeholder="Entrez votre mot de passe"
              ></b-form-input>
            </b-form-group>
            <div class="mt-3 d-grid">
              <b-button type="submit" variant="primary" class="btn-block"
                >Se connecter</b-button
              >
            </div>
            
            <div class="mt-4 text-center">
              <a @click="showHelp = true" class="text-muted" style="cursor: pointer;">
                <i class="mdi mdi-lock mr-1"></i> Mot de passe oublié ?
              </a>
            </div>
          </b-form>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
      <b-modal v-model="showHelp" title="Besoin d'aide ?" centered>
          <form>
            <div class="mb-3">
              <input type="text"  v-model="dgdi_id" class="form-control" placeholder="Votre Matricule"/>
              <div v-if="submitted && errors !== null" style="color: red;">
                <span v-if="errors.dgdi_id">{{ errors.dgdi_id[0] }}</span>
              </div>
            </div>

            <div class="mb-3">
              <multiselect 
                class="col" 
                :show-labels="false"
                placeholder="Quel est votre problème ?"
                v-model="subject"
                :options="options">
              </multiselect>
              <div v-if="submitted && errors !== null" style="color: red;">
                <span v-if="errors.object">{{ errors.object[0] }}</span>
              </div>
            </div>
            <div class="mb-3">
              <ckeditor v-model="content" :editor="editor" :config="config"></ckeditor>
              <div v-if="submitted && errors !== null" style="color: red;">
                <span v-if="errors.content">{{ errors.content[0] }}</span>
              </div>
            </div>
          </form>
          <template v-slot:modal-footer>
            <b-button variant="secondary" @click="showHelp = false">Close</b-button>
            <b-button variant="primary" @click="sendMessage()">
              Envoyer
              <i class="fab fa-telegram-plane ms-1"></i>
            </b-button>
          </template>
        </b-modal>

      <div class="mt-5 text-center">
        <p>
          © {{ new Date().getFullYear() }} A Creative Lab
        </p>
      </div>
      <!-- end row -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>

