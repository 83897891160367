<script>
import simplebar from "simplebar-vue";
import i18n from "../i18n";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
const axios = require('axios');

export default {
  components: {
    simplebar,
    ckeditor: CKEditor.component,
    Multiselect
  },
  data() {
    return {
      user:null,
      lan: i18n.locale,
      options: [
        "Mot de passe perdu",
        "Déclarer une anomalie du système"
      ],
      showHelp: false,
      editor: ClassicEditor,
      dgdi_id: null,
      subject: null,
      content: null,
      config: {
        placeholder: 'Décrivez le problème rencontré...'
      },
      submitted: false,
      errors: null,
      unreadNotifications: 0,
      notifications: [],
      notification: null,
      timer: null
    };
  },
  //get data from API
  created(){
    let endpoints = [
      '/api/user',
      '/api/notifications'
    ];

    axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
      axios.spread((userResponse, notificationsResponse) => {
        this.user = userResponse.data.user;
        this.dgdi_id = this.user.dgdi_id;

        this.notifications = notificationsResponse.data;
        this.unreadNotifications = this.notifications.filter((notification) => notification.read == false).length;

        this.timer = setInterval(this.getNotifications, 30000);
      })
    );
  },
  methods: {
    clearNotifications(){
      if (this.unreadNotifications > 0) {
        axios.get('/api/notifications/clear').then(response => {
          this.unreadNotifications = 0;
        });
      }
    },
    getNotifications(){
      axios.get('/api/notifications').then(response => {
        this.notifications = response.data;
        this.unreadNotifications = this.notifications.filter((notification) => notification.read == false).length;
      });
    },
    getLink(notification){
      let type = notification.notifiable_type.split('\\').pop();
       switch(type) {
        case 'Application':
          return "/application/edit/" + notification.notifiable.id;
        case 'Message':
          return "/messages/view/" + notification.notifiable.id;
        default:
          // code block
      }
    },

    getNotificationString(notification){
      let type = notification.notifiable_type.split('\\').pop();
      
      switch(type) {
        case 'Application':
          return notification.format.replace('<application>', "<strong>" + notification.notifiable.metadata.numero + " - "
            + notification.notifiable.metadata.objet + "</strong>");
        default:
          return notification.format;
      }

    },
    sendMessage() {
      this.submitted = true;
      axios.post('/api/message/store', {
          dgdi_id: this.dgdi_id,
          object: this.subject,
          content: this.content
        })
        .then( response => {
          this.showHelp = false;
          this.$toaster.info("Message envoyé");
        })
        .catch(error => {
          if (error.response.status === 401){
            this.errors = error.response.data;
          }
          else
            this.$toaster.error(error.response.data);
        });
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="/images/logo.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="/images/logo-dark@3x.png" alt height="60" />
            </span>
          </router-link>

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="/images/logo-light.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="/images/logo-light.png" alt height="19" />
            </span>
          </a>
        </div>
      </div>
      <div class="d-flex">
        <b-dropdown
          class="d-inline-block d-lg-none ml-2"
          variant="black"
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          right
        >
          <template v-slot:button-content>
            <i class="mdi mdi-magnify"></i>
          </template>

          <form class="p-3">
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </b-dropdown>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon"
          variant="black"
          @show="clearNotifications()"
        >
          <template v-slot:button-content>
            <i :class="{ 'bx': true, 'bx-bell' : true, 'bx-tada' : unreadNotifications > 0 }"></i>
            <span v-if="unreadNotifications > 0" class="badge bg-danger rounded-pill">{{ unreadNotifications }}</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Notifications</h6>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px">
            <div v-for="notification in notifications" :key="notification.id">
              <a :href="getLink(notification)"  class="text-reset notification-item">
                <div class="media">
                  <div class="media-body">
                    <h6 class="mt-0 mb-1">
                      {{ notification.type }}
                    </h6>
                    <div class="font-size-12 text-muted">
                      <p class="mb-1"  v-html="getNotificationString(notification)">
                      </p>
                      <p class="mb-0">
                        <i class="mdi mdi-clock-outline"></i>
                        {{ notification.created_at | moment("from", "now", true) }}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </simplebar>
          <div class="p-2 border-top d-grid">
            <a
              class="btn btn-sm btn-link font-size-14 text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-down-circle me-1"></i>
              <span key="t-view-more">
                {{ $t("navbar.dropdown.notification.button") }}
              </span>
            </a>
          </div>
        </b-dropdown>

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
        >
        <!-- Print User Firstname-->
          <template v-slot:button-content v-if="user">
            <span class="d-none d-xl-inline-block ml-1">{{ user.firstname }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-dropdown-item :to="`/me`">
            <i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ $t("navbar.dropdown.henry.list.profile") }} 
          </b-dropdown-item>
          <!-- <b-dropdown-item href="javascript: void(0);">
            <i class="bx bx-wallet font-size-16 align-middle me-1"></i>
            {{ $t("navbar.dropdown.henry.list.mywallet") }}
          </b-dropdown-item>
          <b-dropdown-item class="d-block" href="javascript: void(0);">
            <span class="badge bg-success float-end">11</span>
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
            {{ $t("navbar.dropdown.henry.list.settings") }}
          </b-dropdown-item>
          <b-dropdown-item href="javascript: void(0);">
            <i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ $t("navbar.dropdown.henry.list.lockscreen") }}
          </b-dropdown-item> -->
          <b-dropdown-divider></b-dropdown-divider>
          <a href="/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </a>
        </b-dropdown>

        <div class="d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="showHelp = true">
            <i class="bx bx-help-circle"></i>
          </button>
        </div>

        <b-modal v-model="showHelp" title="Besoin d'aide ?" centered>
          <form>
            <div class="mb-3">
              <input type="text"  v-model="dgdi_id" class="form-control" placeholder="Votre Matricule" :readonly="true"/>
               <div v-if="submitted && errors !== null" style="color: red;">
                <span v-if="errors.dgdi_id">{{ errors.dgdi_id[0] }}</span>
              </div>
            </div>

            <div class="mb-3">
              <multiselect 
                class="col" 
                :show-labels="false"
                placeholder="Quel est votre problème ?"
                v-model="subject"
                :options="options">
              </multiselect>
              <div v-if="submitted && errors !== null" style="color: red;">
                <span v-if="errors.object">{{ errors.object[0] }}</span>
              </div>
            </div>
            <div class="mb-3">
              <ckeditor v-model="content" :editor="editor" :config="config"></ckeditor>
              <div v-if="submitted && errors !== null" style="color: red;">
                <span v-if="errors.content">{{ errors.content[0] }}</span>
              </div>
            </div>
          </form>
          <template v-slot:modal-footer>
            <b-button variant="secondary" @click="showHelp = false">Close</b-button>
            <b-button variant="primary" @click="sendMessage()">
              Envoyer
              <i class="fab fa-telegram-plane ms-1"></i>
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </header>
</template>
