<script>
import axios from 'axios';
import Toaster from 'v-toaster';
import Multiselect from "vue-multiselect";

/**
 * Page-header component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      application: null,
      applicationsSelected: null,
      applications: [],
      currentApplicationName: null,
      // pagination
      currentPage: 1,
      perPage: 10,
      startIndex: 1,
      endIndex: 1,
    };
  },
  created(){
    axios.get('/api/application/edit/links/' + this.id).then(response => {
      this.applications = response.data.applications.filter((application) => application.id !== this.id);
      this.application = response.data.application;
      this.applicationsSelected = this.application.links;

      var applicationMeta = this.application.metadata;
      this.currentApplicationName  = applicationMeta.numero  + " - " + applicationMeta.objet;
    });
  },
  methods:{
    resetFormFields() {
    },
    save(){
        let applicationsIds = (this.applicationsSelected !== null) ? this.applicationsSelected.map((application) => application.id) : [];
  
        axios.post('/api/application/update/links/' + this.id, {
          applications: applicationsIds,
        })
        .then(response => {
          this.resetFormFields();
          this.$emit('applicationUpdated', false);
          this.$toaster.success(response.data.message);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    displayName({metadata}){
        return metadata.numero + " - " + metadata.objet;
    }
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      required: false,
    },
  },
}
</script>

<template>  
  <form v-if="currentApplicationName">
    <div class="mb-3">
      <b-form-input
        id="horizontal-firstname-input"
        type="text"
        name="current-app-id"
        v-model="currentApplicationName"
        disabled
      ></b-form-input>
    </div>
        <div class="mb-3" style="margin-bottom: 10px;">
            <multiselect
                :multiple="true"
                :show-labels="false"
                track-by="id"
                class="col"
                :custom-label="displayName"
                placeholder="Choisissez les demandes à lier" 
                v-model="applicationsSelected"
                :options="applications">
            </multiselect>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="save()">Enregistrer</button>
        </div>
  </form>
</template>
