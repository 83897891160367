<script>
const axios = require("axios");
import Layout from "./inbox.vue";

/**
 * Email-inbox component
 */
export default {
  components: {
    Layout,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      message: null,
      author: null,
    };
  },
  created() {
    axios.get("/api/message/view/" + this.$route.params.id).then((response) => {
      this.message = response.data;
      this.author = this.message.from_sender;
    });
  },
};
</script>

<template>
  <div class="email-rightbar mb-3" v-if="message">
    <div class="card">
      <div class="card-body">
        <div>
          <h4>{{ message.object }}</h4>
        </div>
        <div class="media mb-4">
            <div class="media-body">
              <h5 class="font-size-14 mt-1">
                  {{ author.dgdi_id + " - " + author.lastname.toUpperCase() + " " + author.firstname + " <" + author.email + ">" }}
              </h5>
              <small class="text-muted">{{ message.created_at | moment("DD MMM YYYY") }}</small>
            </div>
        </div>
        <div v-html="message.content"></div>
      </div>
    </div>
  </div>
</template>