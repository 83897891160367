<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Multiselect from "vue-multiselect";
import axios from "axios";
import careerTab from "./component/career/career-tab.vue"

/**
 * Contacts-Profile component
 */
export default {
  components: { Layout, PageHeader, Multiselect, careerTab },
  props: {
    id: {
      type: String,
      required: false,
    },
  },

  watch: {
    $route(to, from) {
      if (to.path == "/me") {
        this.getMe()
      }
    }
  },

  created() {
    axios.get('/api/user').then(response => {
      this.userPerms = response.data.user_permissions.map(currentUserPerms => currentUserPerms.name);
      this.roleUserPerms = response.data.role_permissions.map(currentUserPerms => currentUserPerms.name);
      this.currentUserPerms = this.userPerms.concat(this.roleUserPerms);
      this.currentUserRole = response.data.role;
    });

    if (this.$route.params.id) {
      axios
        .get("/api/user/view/" + this.$route.params.id)
        .then((response) => {
          this.userData = response.data.user;
          this.dgdi_id = this.userData.dgdi_id;
          this.firstname = this.userData.firstname;
          this.lastname = this.userData.lastname;
          this.email = this.userData.email;
          this.password = this.userData.pasword;
          this.password_confirmation = this.password_confirmation;
          this.role = this.userData.role[0];
          this.menus = response.data.menus;
          this.permissions = response.data.permissions;
          this.selectedPermissions = response.data.user_permissions.map(
            (permission) => permission.name
          );
          this.rolePermissions = response.data.role_permissions.map(
            (permission) => permission.name
          );
          if (this.userData.active == true) {
            this.isActive = "Actif";
          } else {
            this.isActive = "Archivé";
          }
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message);
        });

    } else {
      axios.get("/api/me").then((response) => {
        this.userData = response.data.user;
        this.dgdi_id = this.userData.dgdi_id;
        this.firstname = this.userData.firstname;
        this.lastname = this.userData.lastname;
        this.email = this.userData.email;
        this.password = this.userData.pasword;
        this.password_confirmation = this.password_confirmation;
        this.role = this.userData.role[0];
        this.menus = response.data.menus;
        this.permissions = response.data.permissions;
        this.selectedPermissions = response.data.user_permissions.map(permission => permission.name);
        this.rolePermissions = response.data.role_permissions.map(permission => permission.name);
        this.byMe = true;
        if (this.userData.active == true) {
          this.isActive = "Actif"
        } else {
          this.isActive = "Archivé"
        }

        this.supAdminOnHisProfile()
      });

    }
    axios.get('/api/roles/list').then(response => {
      response.data.forEach(role => {
        this.roles.push(role);
      });
    });
  },

  methods: {
    getMe() {
      axios.get("/api/me").then((response) => {
        this.userData = response.data.user;
        this.dgdi_id = this.userData.dgdi_id;
        this.firstname = this.userData.firstname;
        this.lastname = this.userData.lastname;
        this.email = this.userData.email;
        this.password = this.userData.pasword;
        this.password_confirmation = this.password_confirmation;
        this.role = this.userData.role[0];
        this.menus = response.data.menus;
        this.permissions = response.data.permissions;
        this.selectedPermissions = response.data.user_permissions.map(permission => permission.name);
        this.rolePermissions = response.data.role_permissions.map(permission => permission.name);
        this.byMe = true;
        this.isActive = (this.userData.active == true) ? "Actif" : "Archivé";
        this.supAdminOnHisProfile()
      });

      axios.get('/api/roles/list').then(response => {
        response.data.forEach(role => {
          this.roles.push(role);
        });
      });
    },
    updateUser() {
      axios.post('/api/update_data_user/' + this.dgdi_id, { matricle: this.dgdi_id, noms: this.lastname, prénoms: this.firstname, email: this.email, role: this.role, status: this.isActive })
        .then(response => {
          if (response.status == 200) {
            this.$toaster.success(response.data.message);
            this.fields = [];
          }
        }).catch((error) => {
          this.$toaster.error(error.response.data.message);
          this.fields = error.response.data.reason;
        })
    },
    updatePassword() {
      axios.post('/api/update_password_user/' + this.dgdi_id, { mot_de_passe: this.password, confirmation_de_mot_de_passe: this.password_confirmation })
        .then(response => {
          if (response.status == 200) {
            this.$toaster.success(response.data.message);
          }
        }
        ).catch((error) => {
          this.$toaster.error(error.response.data.message);
          this.fields = error.response.data.reason;
        })
    },
    save(permission) {
      if ((this.selectedPermissions.filter(element => element == permission)).length > 0) {
        this.selectedPermissions = this.selectedPermissions.filter(element => element != permission);
      }
      else {
        this.selectedPermissions.push(permission);
      }
    },
    can(permissionName) {
      return this.selectedPermissions.includes(permissionName);
    },
    assignUserPermissionAndRole() {
      axios.post('/api/update_permission_role_user/' + this.dgdi_id, { role_id: this.role.id, permissions: this.selectedPermissions })
        .then(response => {
          if (response.status == 200) {
            this.$toaster.success(response.data.message);
          }
        }).catch((error) => {
          this.$toaster.error(error.response.data.message);
          this.fields = error.response.data.reason;
        })
    },
    haveError(label) {
      if (this.fields.hasOwnProperty(label)) {
        return true;
      }
      return false;
    },
    showError(label) {
      return this.fields[label][0];
    },
    refreshPermisions(role) {
      axios.get('/api/roles/edit/' + role)
        .then(response => {
          this.rolePermissions = response.data.role.permissions.map(permission => permission.name);
        })
    },
    currentUserCan(namePermission) {
      return this.currentUserPerms.includes(namePermission);
    },
    currentUserIs(roleName) {
      return this.currentUserRole == roleName;
    },
    isAdministrator() {
      return this.currentUserIs('Administrateur')
    },
    cantUpdate() {
      return !this.currentUserCan('user-update') ||
        (this.byMe && (this.currentUserIs('Super Administrateur')));
    },
    supAdminOnHisProfile() {
      return this.currentUserIs('Super Administrateur') && this.byMe
    }

  },

  data() {
    return {
      displayCareer: (process.env.MIX_GEDEON_DEPARTMENT_DISPLAY == "true") ? true : false,
      fields: [],
      reason: null,
      response: null,
      userData: null,
      dgdi_id: null,
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      role: this.role,
      isActive: this.active,
      password: null,
      password_confimation: null,
      title: "Profile",
      button_label: "Ajouter",
      roles: [],
      menus: [],
      permissions: null,
      selectedPermissions: [],
      rolePermissions: [],
      byMe: false,
      currentUserPerms: [],
      userPerms: null,
      roleUserPerms: null,
      currentUserRole: null,
      items: [
        {
          text: "Utilisateurs",
          to: "/users"
        },
        {
          text: "Profile",
          active: true
        }
      ],
      statData: [
        {
          icon: "bx bx-check-circle",
          title: "Completed Projects",
          value: "125"
        },
        {
          icon: "bx bx-hourglass",
          title: "Pending Projects",
          value: "12"
        },
        {
          icon: "bx bx-package",
          title: "Total Revenue",
          value: "$36,524"
        }
      ],
      options: [
        "Administrateur",
        "Observateur",
        "Commercial",
        "Responsable juridique",

      ],
      status: [
        "Actif",
        "Archivé",
      ],
      defaultPerms: [
        {
          name: 'Lire',
          en_name: 'read',
        },
        {
          name: 'Ajouter',
          en_name: 'create',
        },
        {
          name: 'Modifier',
          en_name: 'update',
        },
        {
          name: 'Assigner',
          en_name: 'assigne',
        },
        {
          name: 'Archiver',
          en_name: 'archive',
        },
        {
          name: 'Supprimer',
          en_name: 'delete',
        },
      ],
    };
  }
};
</script>

<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <b-tabs pills card>
      <b-tab title="Généralités">
        <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <b-form v-if="userData">
                  <b-form-group class="mb-4" label="Matricule *" label-for="horizontal-email-input" label-cols-sm="3">
                    <b-form-input id="horizontal-register-id-input" type="text" name="matricule" v-model="dgdi_id"
                      disabled></b-form-input>
                    <div v-if="haveError('matricule')">
                      <p class="error-message">{{ showError("matricule") }}</p>
                    </div>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Noms *" label-for="horizontal-lastname-input" label-cols-sm="3"
                    :disabled="byMe || !currentUserCan('user-update')">
                    <b-form-input id="horizontal-lastname-input" name="noms" v-model="lastname" type="text" required
                      :disabled="byMe || !currentUserCan('user-update')"></b-form-input>
                    <div v-if="haveError('noms')">
                      <p class="error-message">{{ showError("noms") }}</p>
                    </div>
                  </b-form-group>
                  <b-form-group class="mb-3" label="Prénoms *" label-for="horizontal-firstname-input" label-cols-sm="3"
                    :disabled="byMe || !currentUserCan('user-update')">
                    <b-form-input id="horizontal-firstname-input" type="text" name="prénoms" v-model="firstname" required
                      :disabled="byMe || !currentUserCan('user-update')"></b-form-input>
                    <div v-if="haveError('prénoms')">
                      <p class="error-message">{{ showError("prénoms") }}</p>
                    </div>
                  </b-form-group>
                  <b-form-group class="mb-4" label="Email *" label-for="horizontal-email-input" label-cols-sm="3">
                    <b-form-input id="horizontal-email-input" name="email" v-model="email"
                      :disabled="byMe || !currentUserCan('user-update')" type="email">
                    </b-form-input>
                    <div v-if="haveError('email')">
                      <p class="error-message">{{ showError("email") }}</p>
                    </div>
                  </b-form-group>
                  <b-form-group class="mb-4" label="Statut *" label-for="horizontal-email-input" label-cols-sm="3">
                    <multiselect :show-labels="false" class="col" v-model="isActive" :options="status"
                      :disabled="byMe || !currentUserCan('user-update')">{{ this.status[0] }}</multiselect>
                    <div v-if="haveError('Statut')">
                      <p class="error-message">{{ showError("Statut") }}</p>
                    </div>
                  </b-form-group>
                  <div class="row justify-content-end">
                    <div class="col-sm-9">
                      <b-button :disabled="cantUpdate() || (byMe && !isAdministrator()) || (byMe && isAdministrator())"
                        style="float: right" @click.prevent="updateUser" variant="primary">Enregistrer</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <div class="card">
              <div v-if="currentUserIs('Super Administrateur') ||
                currentUserIs('Administrateur') ||
                this.byMe == true
                " class="card-body">
                <b-form v-if="userData">
                  <b-form-group class="mb-4" label="Mot de passe" label-for="password" label-cols-sm="3">
                    <b-form-input id="password" type="password" name="mot_de_passe" v-model="password"></b-form-input>
                    <div v-if="haveError('mot_de_passe')">
                      <p class="error-message">
                        {{ showError("mot_de_passe") }}
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group class="mb-4" label="Entrer le mot de passe à nouveau" label-for="password-again"
                    label-cols-sm="3">
                    <b-form-input id="password-again" name="confirmation_de_mot_de_passe" v-model="password_confirmation"
                      type="password"></b-form-input>
                    <div v-if="haveError('confirmation_de_mot_de_passe')">
                      <p class="error-message">
                        {{ showError("confirmation_de_mot_de_passe") }}
                      </p>
                    </div>
                  </b-form-group>

                  <div class="row justify-content-end">
                    <div class="col-sm-9">
                      <b-button :disabled="!currentUserIs('Super Administrateur') &&
                        !currentUserIs('Administrateur') &&
                        !byMe
                        " style="float: right" @click.prevent="updatePassword" variant="primary">Enregistrer</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <careerTab :couldUpdate="(isAdministrator() || !cantUpdate()) ? false : cantUpdate()"
        v-if="displayCareer && !supAdminOnHisProfile()" />

      <b-tab v-if="displayCareer && !supAdminOnHisProfile()" title="Permissions">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <label class="mb-4 col-sm-3 col-form-label">Role</label>
              <multiselect @input="refreshPermisions(role.id)" :show-labels="false" label="name" class="col"
                v-model="role" :options="roles"
                :disabled="cantUpdate() || (byMe && !isAdministrator()) || (byMe && isAdministrator())"></multiselect>
              <div v-if="haveError('role')">
                <p class="error-message">{{ showError('role') }}</p>
              </div>
            </div>
            <div>
              <div class="row justify-content-end">
                <div class="position-relative form-group">
                  <label for="title" class="">Permissions</label>
                  <div class="row" v-if="menus">
                    <div class="col-sm-10">
                      <table class="table table-bordered">
                        <tr style="background: #f4f4f4; text-align: center">
                          <td></td>
                          <td style="text-align: center" v-for="defaultPerm in defaultPerms" :key="defaultPerm.name">
                            {{ defaultPerm.name }} </td>
                        </tr>
                        <tr v-for="menu in menus" :key="menu.id">
                          <td style="padding-left: 1rem;">{{ menu.title }}</td>
                          <td style="text-align: center">
                            <div v-if="currentUserCan('user-update')">
                              <i v-if="menu.have_read == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                              <i v-else-if="can(menu.shortname + '-read') && selectedPermissions.includes(menu.shortname + '-read')"
                                @click.prevent="save(menu.shortname + '-read')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-read')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else @click.prevent="save(menu.shortname + '-read')"
                                class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                            <div v-else>
                              <i v-if="menu.have_read == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                              <i v-else-if="can(menu.shortname + '-read') && selectedPermissions.includes(menu.shortname + '-read')"
                                @click.prevent="save(menu.shortname + '-read')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-read')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else @click.prevent="save(menu.shortname + '-read')"
                                class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                          </td>
                          <td style="text-align: center">
                            <div v-if="currentUserCan('user-update')">
                              <i v-if="menu.have_create == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                              <i v-else-if="can(menu.shortname + '-create') && selectedPermissions.includes(menu.shortname + '-create')"
                                @click.prevent="save(menu.shortname + '-create')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-create')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else @click.prevent="save(menu.shortname + '-create')"
                                class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                            <div v-else>
                              <i v-if="menu.have_create == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                              <i v-else-if="can(menu.shortname + '-create') && selectedPermissions.includes(menu.shortname + '-create')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-create')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                          </td>
                          <td style="text-align: center">
                            <div v-if="currentUserCan('user-update')">
                              <i v-if="menu.have_update == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                              <i v-else-if="can(menu.shortname + '-update') && selectedPermissions.includes(menu.shortname + '-update')"
                                @click.prevent="save(menu.shortname + '-update')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-update')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else @click.prevent="save(menu.shortname + '-update')"
                                class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                            <div v-else>
                              <i v-if="menu.have_update == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                              <i v-else-if="can(menu.shortname + '-update') && selectedPermissions.includes(menu.shortname + '-update')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-update')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                          </td>
                          <td style="text-align: center">
                            <div v-if="currentUserCan('user-update')">
                              <i v-if="menu.have_assign == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                              <i v-else-if="can(menu.shortname + '-assign') && selectedPermissions.includes(menu.shortname + '-assign')"
                                @click.prevent="save(menu.shortname + '-assign')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-assign')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else @click.prevent="save(menu.shortname + '-assign')"
                                class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                            <div v-else>
                              <i v-if="menu.have_assign == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                              <i v-else-if="can(menu.shortname + '-assign') && selectedPermissions.includes(menu.shortname + '-assign')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-assign')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                          </td>
                          <td style="text-align: center">
                            <div v-if="currentUserCan('user-update')">
                              <i v-if="menu.have_archive == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                              <i v-else-if="can(menu.shortname + '-archive') && selectedPermissions.includes(menu.shortname + '-archive')"
                                @click.prevent="save(menu.shortname + '-archive')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-archive')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else @click.prevent="save(menu.shortname + '-archive')"
                                class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                            <div v-else>
                              <i v-if="menu.have_archive == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                              <i v-else-if="can(menu.shortname + '-archive') && selectedPermissions.includes(menu.shortname + '-archive')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-archive')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                          </td>
                          <td style="text-align: center">

                            <!-- check if the role can create for the given menu using menu.shortname + '-create' in this case -->
                            <div v-if="currentUserCan('user-update')">
                              <i v-if="menu.have_delete == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <i v-else-if="can(menu.shortname + '-delete') && selectedPermissions.includes(menu.shortname + '-delete')"
                                @click.prevent="save(menu.shortname + '-delete')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-delete')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else @click.prevent="save(menu.shortname + '-delete')"
                                class="far fa-check-circle icone-shape">
                              </i>
                            </div>
                            <div v-else>
                              <i v-if="menu.have_delete == false" class="far fa-times-circle icone-shape red">
                              </i>
                              <i v-else-if="can(menu.shortname + '-delete') && selectedPermissions.includes(menu.shortname + '-delete')"
                                class="far fa-check-circle icone-shape blue">
                              </i>
                              <i v-else-if="rolePermissions.includes(menu.shortname + '-delete')"
                                class="far fa-check-circle icone-shape green">
                              </i>
                              <i v-else class="far fa-check-circle icone-shape">
                              </i>
                            </div>

                          </td>
                        </tr>


                      </table>
                      <div v-if="haveError('permissions')">
                        <p class="error-message">{{ showError('permissions') }}</p>
                      </div>
                    </div>
                    <div class="col-sm-2" style="margin : auto;">
                      <div class="mb-2">
                        <i class="far fa-check-circle icone-shape green"></i> Valeur profil
                      </div>
                      <div class="mb-2">
                        <i class="far fa-check-circle icone-shape blue"></i> Valeur utilisateurs
                      </div>
                      <div class="mb-2">
                        <i class="far fa-check-circle icone-shape"></i> Désactivé
                      </div>
                      <div class="mb-2">
                        <i class="far fa-times-circle icone-shape red"></i> Indisponible
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-9" style="margin-top : 20px;">
                  <b-button :disabled="cantUpdate() || (byMe && !isAdministrator()) || (byMe && isAdministrator())"
                    style="margin-left: 70%" @click.prevent="assignUserPermissionAndRole"
                    variant="primary">Valider</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <!-- end row -->
  </div>
</template>

<style scoped>
.icone-shape {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.red {
  color: red;
}

.green {
  color: #008000;
}

.blue {
  color: #003CA3;
}

.error-message {
  color: red;
}
</style>
