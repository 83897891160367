<script >
import Multiselect from "vue-multiselect";
import {debounce} from "../../../utils";
const axios = require('axios');

/**
 *
 */
export default {
  components: {
    Multiselect,
  },
  props: {
    access: {
      type: Object,
      required: true
    },
    element: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      searchResults: [],
      selections: [],
      submitting: false,
      userAccessToRemove: null,
      accessToRemove: null,
      tabIndex: 0,
    }
  },
  watch: {
    tabIndex(newValue, oldValue) {
      console.log('tab index changed', newValue, oldValue);
      if(newValue !== oldValue) {
        this.searchResults.splice(0)
        this.selections.splice(0)
      }
    }
  },
  computed: {
    canSubmit() {
      return this.selections.length > 0 && !this.submitting
    },
    hasSelected() {
      return this.selections.length > 0
    },
    options() {
      return this.searchResults.filter(item => !this.selections.some(selected => selected.id === item.id))
    },
    isUsersTab() {
      return this.tabIndex === 0
    },
    hasPermittedDepartments() {
      return this.access.permittedDepartments.length > 0;
    }
  },
  methods: {
    showModal() {
      this.$bvModal.show('manage-element-access')
    },
    handleCancel() {
      if(this.submitting) {
        return;
      }
      this.selections.splice(0)
      this.searchResults.splice(0)
      this.$bvModal.hide('manage-element-access')
    },
    handleSubmit() {
      if(!this.canSubmit) {
        return;
      }
      const data = this.selections.map(selection => {
        return {
          permitted_id: selection.item.id,
          access: selection.access,
          permitted_type: this.isUsersTab ? 'user' : 'department'
        }
      })

      this.submitting = true

      axios.put('/api/filesmanager/access/'+ this.element.id, data, {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(response => {
        console.log('response', response.data)
        this.$toaster.success('Les accès ont été mis à jour avec succès !')
        this.selections.splice(0)
        this.searchResults.splice(0)
        this.$bvModal.hide('manage-element-access')
        this.$emit('updated')
      }).catch(error => {
        if(error.response && error.response.status === 403) {
          this.$toaster.error('Vous n\'avez pas les permissions nécessaires pour faire cette action.')
        }
        console.log(error)
        alert('Erreur innatendue');
      }).finally(() => {
        this.submitting = false
      })
    },
    removeSelected(item) {
      const vm = this;
      console.log('remove', item)
      const index = this.selections.findIndex(selection => selection.item.id === item.id)

      console.log('index', index)
      if(index !== -1) {
        // hack to avoid the multiselect to remove the item before the animation is finished
        setTimeout(() => {
          vm.selections.splice(index, 1)
        }, 1)
      }
    },
    onSelect(value) {
      if(!this.selections.some(selection => selection.item.id === value.id)) {
        this.selections.push({
          item: value,
          access: "read"
        })
      }
    },
    onSelectionAccessChange(value, selection) {
      selection.access = value

      console.log('selections', JSON.stringify(this.selections))
    },
    removePermittedUser() {
      const vm = this;
      const accessToRemove = this.accessToRemove;

      this.submitting = true;

      axios.delete('/api/filesmanager/access/'+ this.element.id, {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          permitted_id: accessToRemove.model.id,
          permitted_type: accessToRemove.type,
        }
      })
          .then(response => {
            console.log('response', response.data)
            this.$toaster.success('L\'accès a été supprimé avec succès !')
            vm.$bvModal.hide('remove-permitted-user')
            vm.$emit('updated')
          })
          .catch(error => {
            if(error.response && error.response.status === 403) {
              this.$toaster.error('Vous n\'avez pas les permissions nécessaires pour faire cette action.')
            }
            console.log(error)
            alert('Erreur innatendue');
          }).finally(() => {
            vm.submitting = false
          })
    },
    tryRemovePermittedUser(user) {
      this.accessToRemove = {model: user, type: 'user'};
      this.$bvModal.show('remove-permitted-user')
    },
    tryRemovePermittedDepartment(department) {
      this.accessToRemove = {model: department, type: 'department'};
      this.$bvModal.show('remove-permitted-user')
    },
    searchQuery(query) {
      if(this.submitting) {
        return;
      }
      const vm = this;

      let endpoint = '/api/users/search';

      let except = [vm.access.owner.id, ...vm.access.permittedUsers.map(user => user.id)]

      if(!vm.isUsersTab) {
        endpoint = '/api/departments/search';
        except = [...vm.access.permittedDepartments.map(department => department.id)]
      }

      axios.get(endpoint, {
        params: {
          q: query,
          except: except.join(),
        }
      })
          .then(response => {
            console.log('search results', response.data)
            vm.searchResults = response.data
          })
          .catch(error => {
            console.log(error)
            alert('Erreur innatendue');
          })
    }
  }
}
</script>

<template>
  <div>
    <b-button
        @click="showModal"
        variant="primary"
        size="sm"
        class="font-size-12">
      Gérer les accès
    </b-button>
    <b-modal id="manage-element-access" click-outside-except hide-footer hide-header>
      <h5>Gérer les accès</h5>
      <b-tabs content-class="mb-4" v-model="tabIndex">
        <b-tab title="Utilisateurs">
          <div class="pt-4">
            <b-form-group>
              <multiselect
                  label="full_name"
                  track-by="id"
                  :options="options"
                  :hide-selected="true"
                  :internal-search="false"
                  :limit="4"
                  :reset-after="true"
                  open-direction="bottom"
                  placeholder="Ajouter des personnes"
                  @search-change="searchQuery"
                  @select="onSelect"
              >
                <template v-slot="noResult">
                  <span>Aucun résultat trouvé.</span>
                </template>
              </multiselect>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab title="Départements">
          <div class="pt-4">
            <b-form-group>
              <multiselect
                  label="name"
                  track-by="id"
                  :options="options"
                  :hide-selected="true"
                  :internal-search="false"
                  :limit="4"
                  :reset-after="true"
                  open-direction="bottom"
                  placeholder="Ajouter des départements"
                  @search-change="searchQuery"
                  @select="onSelect"
              >
                <template v-slot="noResult">
                  <span>Aucun résultat trouvé.</span>
                </template>
              </multiselect>
            </b-form-group>
          </div>
        </b-tab>
      </b-tabs>
      <div class="mb-4" v-if="hasSelected">
        <h6 class="font-size-14 mb-2">Ajouter des accès</h6>
        <div
            class="d-flex flex-row align-items-center justify-content-between"
            v-for="selection in selections"
            :key="selection.item.id"
        >
          <div>
            <a
                href="javascript:void(0)"
                class="me-3 font-size-16"
                @click.prevent="removeSelected(selection.item)"
            >
              <i class="mdi mdi-close"></i>
            </a>
            <strong>{{ isUsersTab ? selection.item.full_name : selection.item.name }}</strong>
          </div>
          <b-form-select :value="selection.access" @input="(value) => onSelectionAccessChange(value, selection)">
            <b-form-select-option value="read">Lecteur</b-form-select-option>
            <b-form-select-option value="write">Éditeur</b-form-select-option>
          </b-form-select>
        </div>
      </div>
      <div v-if="!isUsersTab && hasPermittedDepartments">
        <h6 class="font-size-14 mb-2">Départements avec accès</h6>
        <div v-for="permittedDepartment in access.permittedDepartments" :key="permittedDepartment.id" class="d-flex flex-row align-items-center justify-content-between mb-1">
          <div>
            <a
                href="javascript:void(0)"
                v-if="access.owner.isCurrentUser"
                class="me-2 font-size-14"
                @click.prevent="tryRemovePermittedDepartment(permittedDepartment)"
            >
              <i class="mdi mdi-close"></i>
            </a>
            <strong>{{ permittedDepartment.name }}</strong>
          </div>
          <span v-if="permittedDepartment.pivot.permission_type === 'read'" class="text-muted">Lecteur</span>
          <span v-if="permittedDepartment.pivot.permission_type === 'write'" class="text-muted">Éditeur</span>
        </div>
      </div>
      <div v-if="isUsersTab">
        <h6 class="font-size-14 mb-2">Utilisateurs avec accès</h6>
        <div class="d-flex flex-row align-items-center justify-content-between mb-1">
          <div>
            <strong>{{ access.owner.full_name }} <span v-if="access.owner.isCurrentUser">(vous)</span></strong>
          </div>
          <span class="text-muted">Propriétaire</span>
        </div>
        <div v-for="permittedUser in access.permittedUsers" :key="permittedUser.id" class="d-flex flex-row align-items-center justify-content-between mb-1">
          <div>
            <a
                href="javascript:void(0)"
                v-if="access.owner.isCurrentUser"
                class="me-2 font-size-14"
                @click.prevent="tryRemovePermittedUser(permittedUser)"
            >
              <i class="mdi mdi-close"></i>
            </a>
            <strong>{{ permittedUser.full_name }} <span v-if="permittedUser.isCurrentUser">(vous)</span></strong>
          </div>
          <span v-if="permittedUser.pivot.permission_type === 'read'" class="text-muted">Lecteur</span>
          <span v-if="permittedUser.pivot.permission_type === 'write'" class="text-muted">Éditeur</span>
        </div>
      </div>
      <hr>
      <div>
        <button class="btn btn-secondary" @click="handleCancel" :disabled="submitting">Annuler</button>
        <button class="btn btn-danger" @click="handleSubmit" :disabled="!canSubmit">
          Envoyer
          <b-spinner v-if="submitting" small class="ms-1" />
        </button>
      </div>
    </b-modal>
    <b-modal id="remove-permitted-user" click-outside-except hide-footer hide-header>
      <h5>Supprimer l'accès</h5>
      <p>Voulez-vous supprimer l'accès ?</p>
      <hr>
      <div>
        <button class="btn btn-secondary" @click="$bvModal.hide('remove-permitted-user')" :disabled="submitting">Annuler</button>
        <button class="btn btn-danger" @click.prevent="removePermittedUser" :disabled="submitting">Supprimer l'accès</button>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>

</style>