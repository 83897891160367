import {nanoid} from 'nanoid'
export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const setQueryStringParameters = (obj, keepOthers = true) => {
    const urlParams = new URLSearchParams(
        keepOthers ? window.location.search : ""
    )

    console.log('obj', obj)

    Object.entries(obj).forEach(([key, value]) => {
        urlParams.set(key, value)
    })
    window.history.pushState(obj, '', `${window.location.pathname}?${urlParams.toString()}`)
}

export const uniqueId = () => nanoid()

export const objectToFormData = (obj) => {
    const formData = new FormData()
    Object.entries(obj).forEach(([key, value]) => {
        formData.append(key, value)
    })
    return formData
}

export const debounce = (func, timeout) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export const debounceLeading = (func, timeout) => {
    let timer;
    return (...args) => {
        if (!timer) {
            func.apply(this, args);
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = undefined;
        }, timeout);
    };
}

export const getUrlParameter = (name) => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(name)
}

export const findAttribute = (el, attr) => {
    const element = findAncestorWithAttribute(el, attr)
    if(element) {
        return element.getAttribute(attr)
    }
}

export const findAncestorWithAttribute = (el, attr) => {
    if (el.hasAttribute(attr)) {
        return el
    }
    while ((el = el.parentElement) && !el.hasAttribute(attr));
    return el
}

export const hasAncestorWithAttribute = (el, attr) => {
    return !!findAncestorWithAttribute(el, attr)
}

export const exposeRawDataProperty = (value) => JSON.parse(JSON.stringify(value))