<script>
import axios from 'axios';
import Multiselect from "vue-multiselect";
import { documentTypesTemplates } from "../templates.js";


/**
 * Projects-list component
 */
export default {
  components: { 
    Multiselect
  },
  data() {
    return {
      app_no: undefined,
	  name: undefined,
      object: undefined,
      type: undefined,
      status: undefined,
      statuses: [
        "en attente",
        "en cours",
        "en cours de validation",
        "complet"
      ],
      type: undefined,
      types: [],
    };
  },
  created(){
	this.getApplicationTypes()
	
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopstate);
  },
  methods: {
	getApplicationTypes() {
      axios.get('/api/application_types/list').then(response => {
        this.types = response.data
      });
    },
		resetFormFields(){
			this.app_no = null;
      		this.object = null;
			this.type = null;
      		this.status = null;
			this.type = null;
			this.name = null;
		},
		checkFormFields() {
			var result = true;

			if (this.app_no !== null && this.app_no !== undefined ) {
					this.app_no = this.app_no.trim();
					if (this.app_no.length < 2) {
						this.$toaster.warning("Entrez au moins 3 caractères pour le numéro de demande.");
						this.app_no = null;
						result = false;
					}
			}

			if (this.object !== null && this.object !== undefined) {
					this.object = this.object.trim();
					if (this.object.length < 2) {
						this.$toaster.warning("Entrez au moins 3 caractères pour l'objet de demande.");
						this.object = null;
						result = false;
					}	
			}

			if (this.name !== null && this.name !== undefined) {
					this.name = this.name.trim();
					if (this.name.length < 2) {
						this.$toaster.warning("Entrez au moins 3 caractères pour l'objet de demande.");
						this.name = null;
						result = false;
					}
			}

			return result;
   		},

		setQueryStringParameters(parameters, keepOthers = true) {
			if (typeof window === "undefined") {
				throw new Error("setQueryStringParameters can only be called in the browser");
			}
			var urlParams = new URLSearchParams( "");
			
			Object.entries(parameters).forEach(([key, value]) => {
				if(value != null || value != undefined){
					urlParams.set(key, value);
				}
				
			});
			const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
			window.history.pushState({} , "", newUrl); 
		},

		handlePopstate(event) {
			// Obtenir l'URL précédente à partir de l'événement popstate
			var previousUrl = event.state ? event.state.url : null;
			

			if (previousUrl) {
				// Recharger les données correspondant à l'URL précédente
				// Exemple : Appeler une méthode pour récupérer les données
				this.loadDataFromUrl(previousUrl);
			} else {
				// Aucune URL précédente disponible, vous pouvez gérer ce cas selon vos besoins
			}
			},
			loadDataFromUrl(url) {
			// Exemple : Effectuer une requête HTTP pour récupérer les données de l'URL spécifiée
			// Utilisez axios, fetch ou toute autre bibliothèque de votre choix
			// Une fois les données récupérées, mettez à jour votre état ou effectuez d'autres actions nécessaires dans votre composant
			this.$emit('searchApplications', { url: url });
		},
		search(){
			if (! this.checkFormFields())
				return;

			var parameters = {
				app_no: this.app_no,
				object: this.object,
				type: (this.type) ? this.type.id : null,
				status: this.status,
				name: this.name
			};
			this.setQueryStringParameters(parameters);
			this.$emit('searchApplications', { params: parameters });
		}
	}
};
</script>

<template>
    <div class="row acl-search">
			<h6 style="font-weight: 500;">Recherche</h6>
			<div class="col-sm-1">
				<input v-model="app_no" class="form-control" placeholder="Numéro"/>
			</div>
			<div class="col-sm-3">
				<input v-model="object" class="form-control" placeholder="Objet"/>
			</div>
			<div class="col-sm-2">
				<input v-model="name" class="form-control" placeholder="Nom de dossier"/>
			</div>
			<div class="col-sm-2">
				<multiselect
					:show-labels="false" 
					v-model="status" 
					:options="statuses" 
					placeholder="Statuts">
				</multiselect>
			</div>
			<div class="col-sm-2">
				<multiselect 
					:show-labels="false"
					label="name"
					v-model="type" 
					:options="types" 
					placeholder="Types">
				</multiselect>
			</div>
			<div class="col-sm-1">
				<b-button  @click.prevent="search()" variant="primary">Filtrer</b-button>
			</div>
			<div class="col-sm-1">
				<b-button  @click.prevent="resetFormFields()" variant="secondary">Reinitialiser</b-button>
			</div>
		</div>
</template>