<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import { basicFields } from "../../assets/js/basic-fields.js";

export default {
  name:'application-type-modal-form',
  components: { Multiselect },
  props: {
    selectedApplicationType: {
			type: Object,
      require: false
		},
    showForm: {
          type: Boolean,
          default: () => false,
    }
  },
  computed: {
    display: {
      get() { return this.showForm },
      set(value) { return value },
    },
  },
  updated(){
    this.applicationType = (this.selectedApplicationType !== null) ? this.selectedApplicationType : this.applicationType;
  },
  data() {
    return {
      applicationType: {
        id: null,
        code: null,
        name: null,
        color: '#000000',
        fields: basicFields,
      },
      processing: false,
      errors: [],
      fieldTypes: [
        { type: 'text',
          display_type: 'Texte'
        },
        { type: 'select',
          display_type: 'Liste'
        },
        { type: 'date',
          display_type: 'Date'
        }
      ],
    };
  },
  methods : {
    saveApplicationType(){
      this.processing = true;

      axios.post('/api/application_type/store', {
        id: this.applicationType.id,
        code: this.applicationType.code,
        color: this.applicationType.color,
        name: this.applicationType.name,
        fields: this.applicationType.fields,
      }).then( response => {
          this.processing = false;
          this.$toaster.success(response.data.message);
          this.resetForm();
          this.display = false;
          this.$emit('formSubmit');
      }).catch((error) => { 
        this.processing = false;
        this.$toaster.error("Une erreur est survenue lors de l'enregistrement du types de demande.");
        this.errors = error.response.data;
      })
    },

    addField() {
      this.applicationType.fields.push({ name: "", type: {}, placeholder: "", required: false });
    },

    removeField(index) {
      if (confirm("Voulez-vous vraiment supprimer ce champ ?"))
        this.applicationType.fields.splice(index, 1);
    },
    
    getFieldTypesList(field) {
      return (field.types) ? field.types : this.fieldTypes ;
    },
    
    isDisabled(field) {
      if (field.can_update === undefined)
        return false
      return !field.can_update
    },

    resetForm(){
      this.applicationType = {
        id: null,
        code: null,
        name: null,
        color: '#000000',
        fields: basicFields.slice(0,3),
      };
      
      this.errors = [];
    },

    hideForm(){
      this.resetForm()
      this.display = false
      this.$emit('hideForm')
    },

    deleteApplicationType(){
      this.resetForm()
      this.display = false
      this.$emit('delete')
    },

    hasError(label){
      if(this.errors.hasOwnProperty(label)){
       return true;
      }
    },

    showError(label){
       return this.errors[label][0];
    },
  },
};
</script>
<template>
  <b-modal v-model="display" size="lg" title="Nouveau type" centered @hidden="hideForm">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-lg-2"><span class="required">Code</span></div>
              <div class="col-lg-10">
                <input class="form-control" id="application-type-code" type="text" name="applicationType.code" v-model="applicationType.code"/>
                <div v-if="hasError('code')">
                  <p class="error-message">{{ showError('code') }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2"><span class="required">Nom</span></div>
              <div class="col-lg-10">
                <input class="form-control" id="application-type-name" type="text" name="applicationType.name" v-model="applicationType.name"/>
                <div v-if="hasError('name')">
                  <p class="error-message">{{ showError('name') }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2"><span class="required">Couleur</span></div>
              <div class="col-lg-10">
                <input type="color" id="application-type-color" v-model="applicationType.color" class="form-control form-control-color" />
                <div v-if="hasError('color')">
                  <p class="error-message">{{ showError('color') }}</p>
                </div>
              </div>
            </div>

            <div class="acl-section">
              <h6 class="mb-0">Champs</h6>
              <div class="subtitle">Les champs 'Date', 'Objet' et 'Nom de dossier', obligatoires, sont générés automatiquement.
                </div>
            </div>

            <div>
              <div v-for="(field, index) in applicationType.fields" :key="field.id" class="row" style="margin-bottom: 20px;">
                <div class="mb-3">
                  <input :id="`fields-${index}-name`" type="text" v-model="field.name" class="form-control" placeholder="Nom du champ" required
                    :readonly="field.basic" />
                  <div v-if="hasError(`fields.${index}.name`)">
                    <p class="error-message">{{ showError(`fields.${index}.name`) }}</p>
                  </div>
                </div>
                <div class="mb-3">
                  <input :id="`fields-${index}-placeholder`" type="text" v-model="field.placeholder" class="form-control"
                    placeholder="Indicateur de la valeur du champ" min="0" required :readonly="field.basic" />
                    <div v-if="hasError(`fields.${index}.placeholder`)">
                      <p class="error-message">{{ showError(`fields.${index}.placeholder`) }}</p>
                    </div>
                </div>
                <div class="mb-3">
                  <b-form-checkbox v-model="field.required" v-bind:value="true" v-bind:unchecked-value="false" plain
                    :disabled="field.basic">
                    Obligatoire
                  </b-form-checkbox>
                </div>
                <div class="mb-3">
                  <multiselect class="col" :show-labels="false" label="display_type" placeholder="Choisissez un type de champ"
                    v-model="field.type" :options="getFieldTypesList(field)" :disabled="isDisabled(field)">
                  </multiselect>
                </div>
                <div class="mb-3" v-if="field.type && field.type.type == 'select'">
                  <textarea v-model="field.options" class="form-control" :maxlength="225" rows="3"
                    placeholder="Entrez une option par ligne"></textarea>
                </div>

                <div v-if="!field.basic" class="col-lg-2 align-self-center">
                  <div class="d-grid">
                    <input type="button" class="btn btn-primary btn-block" value="Supprimer" @click="removeField(index)" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <input type="button" class="btn btn-success mt-3 mt-lg-0" value="Ajouter" @click="addField" />
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="hideForm">Fermer</b-button>
      <b-button v-if="applicationType.id" variant="danger" @click="deleteApplicationType">Supprimer</b-button>
      <b-button variant="primary" @click.prevent="saveApplicationType" :disabled="processing">Enregistrer</b-button>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
  .error-message {
    color: red;
    padding: auto .2rem;
  }

  .required:after {
    content:" *";
    color: red;
  }

  .row {
    margin-bottom: .5rem;
  }
  .acl-section {
    margin-top: 2rem; 
    margin-bottom: 1.25rem;
  }
  .acl-section > .subtitle {
    color: #989898;
    font-size: 0.75rem;
  }
</style>