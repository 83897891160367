export class FilePicker {
    constructor(id) {
        this.id = id;
    }

    pickFile(onFilePicked, multiple = false, acceptedFilesTypes = []) {
        this.dispose();

        const input = document.createElement('input');
        input.type = 'file';
        input.id = this.id;

        if(acceptedFilesTypes.length > 0) {
            input.accept = acceptedFilesTypes.join(',');
        }

        input.style.display = 'none';
        input.multiple = multiple;
        document.body.appendChild(input);
        input.addEventListener('click', (e) => {
            e.stopPropagation();
        }, false)
        input.addEventListener('change', () => {
            onFilePicked(input.files);
        });
        input.click();
    }

    dispose() {
        if(document.getElementById(this.id)) {
            document.getElementById(this.id).remove();
        }
    }
}