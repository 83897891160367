<script>

import Layout from "../../layouts/main";
import PageHeader from "./components/page-header.vue";
import Element from "./components/element.vue";

const axios = require('axios');
import {elementsTree} from "./elements-tree"
import {
  debounce,
  exposeRawDataProperty,
  formatBytes,
  getUrlParameter,
  setQueryStringParameters,
  uniqueId
} from '../../utils'
import {mapStores} from "pinia";
import {useFilemanagerUploadStore} from "../../stores/filemanager-upload";
import DetailsSection from "./details-section.vue";
import ListItemElement from "./components/list-item-element.vue";
import Searchbox from "./components/searchbox.vue";

const SECTIONS = {
  MyFiles: 'my-files',
  SharedFiles: 'shared-files',
}

export default {
  components: {
    Searchbox,
    ListItemElement,
    DetailsSection,
    Element,
    PageHeader,
    Layout
  },
  data() {
    return {
      title: "Gestionnaire de fichiers",
      elements: [],
      items: [],
      loading: true,
      path: [],
      details: null,
      searching: false,
      processing: false,
      currentSection: SECTIONS.MyFiles,
      search: '',
      selectedFile: null,
      conflictResolutionMode: 'rename',
      folderName: '',
      searchResults: [],
    }
  },
  created() {
    this.SECTIONS = SECTIONS

    const currentSection = getUrlParameter('section')
    if (currentSection
        && Object.values(SECTIONS).includes(currentSection)
        && currentSection !== this.currentSection) {
      this.currentSection = currentSection
    } else {
      this.fetchData()
    }

    this.debouncedSearch = debounce((newValue, filters) => {
      const params = {
        q: newValue
      }

      if(filters) {
        if(filters.tags.length > 0) {
          params.tags = filters.tags.map(tag => tag.slug)
        }
        if(filters.sharedBy) {
          params.shared_by = filters.sharedBy.id
        }
        if(filters.dateRange.length > 0) {
          params.date_range_min = filters.dateRange[0].toISOString();
          params.date_range_max = filters.dateRange[1].toISOString();
        }
      }

      console.log('params', params)
      // call fetch API to get results
      axios.get('/api/filesmanager/search', {
        params,
        'Content-Type': 'application/json'
      })
          .then(response => {
            this.searching = false
            this.searchResults = response.data
          })
          .catch(error => {
            this.searching = false
            console.log(error)
          })
    }, 500);
  },
  mounted() {
    const vm = this;
    addEventListener('popstate', function (e) {
      const state = e.state
      if (state.hasOwnProperty('path')) {
        const element = vm.items.find(element => element.id === state.path)
        vm.path.splice(-1, 1)
        vm.elements = element.children ?? []
      } else {
        vm.path = []
        vm.elements = vm.items
      }
    })
  },
  watch: {
    // search(newValue, oldValue) {
    //   if (newValue === '') {
    //     this.searchResults = []
    //     this.searching = false
    //     return;
    //   }
    //   this.searching = true
    //   this.debouncedSearch(newValue.trim());
    // },
    currentSection(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (newValue === SECTIONS.SharedFiles && oldValue === SECTIONS.MyFiles) {
        this.$root.$emit('bv::toggle::collapse', 'categories-collapse')
      }
      this.fetchData(false, true)
    }
  },
  computed: {
    folders() {
      console.log('computed folders', exposeRawDataProperty(this.elements))
      return this.elements.filter(element => element.element_type === 'folder')
    },
    files() {
      console.log('computed files', exposeRawDataProperty(this.elements))
      return this.elements.filter(element => element.element_type === 'file')
    },
    hasDetails() {
      return this.details !== null
    },
    isEmpty() {
      return this.elements.length === 0
    },
    isSearchQueryEmpty() {
      return this.search.trim() === ''
    },
    hasSearchResults() {
      return this.searchResults.length > 0
    },
    ...mapStores(useFilemanagerUploadStore)
  },
  methods: {
    formatBytes,
    makeSearch(query, filters) {
      console.log('filters', filters)
      if(query.trim() === '') {
        this.searchResults = []
        this.searching = false
        return;
      }

      this.searching = true
      this.debouncedSearch(query.trim(), filters);
    },
    onElementClick(element) {
      this.details = element
      console.log('details', element)
    },
    onElementDoubleClick(element) {
      if (element.element_type === 'folder') {
        this.path.push(element)
        this.elements = element.children ?? []
        this.fetchData({path: element.id})
      } else if (element.element_type === 'file') {
        const url = '/api/filesmanager/download/' + element.id;

        window.open(url, '_blank')
      }
    },
    onElementUnselect(element) {
      this.details = null
    },
    onBreadCrumbClick(isActive, index) {
      if (!isActive) {
        if (index === -1) {
          this.fetchData(false, true)
          return
        }
        const parent = this.path[index]
        this.fetchData({path: parent.id})
      }
    },
    fetchData(queryParams = {}, strict = false) {

      let uri = '/api/filesmanager';

      if (queryParams && Object.keys(queryParams).length === 0) {
        const path = getUrlParameter('path')

        if (path) {
          queryParams.path = path
        }
      }

      if(!queryParams) {
        queryParams = {}
      }

      queryParams.section = this.currentSection;

      if (Object.keys(queryParams).length > 0) {
        uri += '?' + Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&')
      }

      console.log('queryParams', queryParams)

      setQueryStringParameters(queryParams, !strict && Object.keys(queryParams).length > 0)

      this.loading = true

      axios.get(uri).then(response => {
        const {data} = response;
        const {items, parent} = data
        console.log('data', data)
        if (Array.isArray(items)) {
          if(items.length === 0) {
            this.items = items
          }
          this.elements = items
          console.log('items', exposeRawDataProperty(this.items))
        }

        this.loading = false

        this.path.splice(0)

        if (parent) {
          if (parent.ancestors.length > 0) {
            const {ancestors} = parent

            ancestors.sort((a, b) => a.depth - b.depth)

            this.path = ancestors

            delete parent.ancestors
          }
          this.path.push(parent)
        }
      }).catch(error => {
        console.error(error)
        this.loading = false
      })
    },
    onPickFile() {
      this.$refs.fileInput.click()
    },

    onFilePicked(event) {
      const files = event.target.files

      if (files.length === 0) {
        return
      }

      const file = files[0]

      this.selectedFile = file;

      axios.get('/api/filesmanager/exists', {
        params: {
          file_name: this.selectedFile.name,
          folder_id: this.path.length > 0 ? this.path[this.path.length - 1].id : null
        }
      }).then(response => {
        this.$bvModal.show('file-conflict-resolution-mode');
      }).catch(error => {
        console.log('error', error)
        if(error.response && parseInt(error.response.status) === 404) {
          this.uploadFile(file, false)
        } else {
          console.error(error)
          alert('Erreur innatendue');
        }
      }).finally(() => {
        event.target.value = ''

      })
    },
    uploadFile(file, replace = false) {
      this.processing = true;
      const uploadId = uniqueId()

      this.fileManagerUploadStore.upload(
          file,
          uploadId,
          "/api/filesmanager/upload",
          {
            folder_id: this.path.length > 0 ? this.path[this.path.length - 1].id : null,
            conflict_resolution: replace ? 'replace' : 'rename'
          }
      ).then(response => {
        console.log('uploaded', response.data)
        const element = this.path.slice(-1)[0]
        const queryParams = {}

        if (element) {
          queryParams.path = element.id
        }

        this.fetchData(queryParams)
      }).catch(error => {
        console.error('upload error', error)
      }).finally(() => {
        this.processing = false
        this.$bvModal.hide('file-conflict-resolution-mode');
        this.selectedFile = null;
        this.conflictResolutionMode = 'rename';
      })
    },
    handleOpenAction(element) {
      this.onElementDoubleClick(element)
    },
    handleFolderCreation() {
      const folderName = this.folderName.trim()

      if (folderName === '') {
        return
      }
      this.processing = true

      axios.post('/api/filesmanager/folders', {
        name: folderName,
        folder_id: this.path.length > 0 ? this.path[this.path.length - 1].id : null
      }).then(response => {
        const {data} = response
        console.log('folder created', data)
        this.handleCancelFolderCreation()
        this.fetchData()
      }).catch(error => {
        console.error('folder creation error', error)
      }).finally(() => {
        this.processing = false
      })
    },
    handleCancelFolderCreation() {
      this.folderName = ''
      this.$bvModal.hide('new-folder')
    },
  }
}
</script>

<template>
  <div>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-md-3 hidden-sm ">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column h-100">
              <div class="mb-3 d-grid">
                <b-dropdown
                    toggle-class="w-100 btn-block"
                    variant="light"
                >
                  <template #button-content>
                    <i
                        class="mdi mdi-plus me-1"
                    ></i>
                    Nouveau
                  </template>

                  <b-dropdown-item
                      href="#"
                      @click="$bvModal.show('new-folder')"
                  ><i
                      class="bx bx-folder me-1"
                  ></i>
                    Dossier
                  </b-dropdown-item
                  >
                  <b-dropdown-item href="#" @click="onPickFile"
                  ><i class="bx bx-file me-1"></i>
                    Fichier
                  </b-dropdown-item
                  >
                </b-dropdown>
                <input type="file" class="d-none" ref="fileInput" @change="onFilePicked"/>
              </div>
              <ul class="list-unstyled categories-list">
                <li>
                  <div class="custom-accordion">
                    <a
                        class="text-body fw-medium py-1 d-flex align-items-center"
                        :class="{active: currentSection === SECTIONS.MyFiles}"
                        data-toggle="collapse"
                        v-b-toggle.categories-collapse
                        role="button"
                        aria-expanded="false"
                        aria-controls="categories-collapse"
                        @click="currentSection = SECTIONS.MyFiles"
                    >
                      <i
                          class="mdi mdi-folder font-size-16 text-warning me-2"
                      ></i>
                      Mes Fichiers
                      <i
                          class="mdi mdi-chevron-up accor-down-icon ms-auto"
                      ></i>
                    </a>
                    <b-collapse
                        visible
                        class="collapse show"
                        id="categories-collapse"
                    >
                      <div
                          class="card border-0 shadow-none ps-2 mb-0"
                      >
                        <ul
                            class="list-unstyled mb-0"
                        >
                          <li v-for="folder in folders" :key="folder.id">
                            <a
                                href="#"
                                class="d-flex align-items-center"
                            ><i
                                class="bx bxs-folder font-size-16 text-muted me-2"
                            ></i><span
                                class="me-auto"
                            >{{ folder.name }}</span
                            ></a
                            >
                          </li>
                        </ul>
                      </div>
                    </b-collapse>
                  </div>
                </li>
                <li>
                  <a
                      class="text-body fw-medium py-1 d-flex align-items-center"
                      :class="{active: currentSection === SECTIONS.SharedFiles}"
                      role="button"
                      @click="currentSection = SECTIONS.SharedFiles">
                    <i class="mdi mdi-account-multiple font-size-16 text-warning me-2"></i>
                    Partagés avec moi
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <Searchbox v-model="search" @input="makeSearch" />
              <div class="col-sm-12">
                <div class="mt-2">
                  <nav v-if="!loading">
                    <ol class="breadcrumb p-0 m-0">
                      <li
                          class="breadcrumb-item"
                          v-bind:class="{active: path.length === 0, clickable: path.length !== 0}"
                          @click="() => onBreadCrumbClick(path.length === 0, -1)"
                      >
                        <span v-if="currentSection === SECTIONS.MyFiles">Mes fichiers</span>
                        <span v-if="currentSection === SECTIONS.SharedFiles">Partagés avec moi</span>
                      </li>
                      <li
                          v-for="(pathElement, index) in path"
                          :key="index"
                          class="breadcrumb-item"
                          v-bind:class="{active: index === path.length - 1, clickable: index !== path.length - 1}"
                          @click="() => onBreadCrumbClick(index === path.length - 1, index)"
                      >
                        {{ pathElement.name }}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div :class="{'min-h-400': isEmpty}">
              <div class="row" v-if="!loading && isSearchQueryEmpty">
                <div class="col-sm-6" v-for="folder in folders" :key="folder.id">
                  <Element
                      :click="() => onElementClick(folder)"
                      :dbclick="() => onElementDoubleClick(folder)"
                      :unselect="() => onElementUnselect(folder)"
                      :name="folder.name"
                      :type="folder.element_type"
                      :size="folder.size"
                      :id="folder.id"
                      :can-write="!folder.pivot || folder.pivot.permission_type === 'write'"
                      v-on:reload="() => fetchData()"
                      v-on:open="() => handleOpenAction(folder)"
                  />
                </div>
                <div class="col-sm-12">
                  <hr style="border-color: #74788d">
                  <ListItemElement
                      v-for="file in files"
                      :key="file.id"
                      :name="file.name"
                      :type="file.element_type"
                      :size="file.size"
                      :id="file.id"
                      v-on:reload="() => fetchData()"
                      v-on:open="() => handleOpenAction(file)"
                      :click="() => onElementClick(file)"
                      :dbclick="() => onElementDoubleClick(file)"
                      :unselect="() => onElementUnselect(file)"
                  />
                </div>
              </div>
              <div v-if="!isSearchQueryEmpty">
                <div class="d-flex flex-wrap">
                  <h5 class="font-size-14 me-3">Résultats de recherche</h5>
                </div>
                <div v-if="searching" class="d-flex min-h-100p justify-content-center align-items-center">
                  <b-spinner type="grow" variant="primary" v-if="searching"/>
                </div>
                <div v-if="!searching && hasSearchResults">

                  <hr class="mt-2"/>
                  <ListItemElement
                      v-for="result in searchResults"
                      :key="result.id"
                      :name="result.name"
                      :type="result.element_type"
                      :size="result.size"
                      :id="result.id"
                      v-on:reload="() => fetchData()"
                      v-on:open="() => handleOpenAction(result)"
                  />
                </div>
              </div>
              <div v-if="!isSearchQueryEmpty && !searching && !hasSearchResults">
                <p>Votre recherche ne correspond à aucun élement</p>
              </div>
              <div v-if="isEmpty && !loading" class="d-flex min-h-100p justify-content-center align-items-center">
                <p>Aucun élement.</p>
              </div>
              <div v-if="loading" class="d-flex min-h-100p justify-content-center align-items-center">
                <b-spinner type="grow" variant="primary" v-if="loading"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden-sm col-md-3">
        <DetailsSection :details="details" ref="detailsSection"/>

      </div>
    </div>
    <b-modal id="new-folder" hide-footer hide-header>
      <h5>Nouveau dossier</h5>
      <hr>
      <div class="form-group">
        <input type="text" class="form-control" id="folder-input" placeholder="Dossier" v-model="folderName" autofocus
               autocapitalize="words">
      </div>
      <br>
      <div>
        <button class="btn btn-secondary" @click="handleCancelFolderCreation" :disabled="processing">Annuler</button>
        <button class="btn btn-info" @click="handleFolderCreation"
                :disabled="processing || folderName.trim().length === 0">
          Créer le dossier
          <b-spinner v-if="processing" small class="ms-1"/>
        </button>
      </div>
    </b-modal>
    <b-modal id="file-conflict-resolution-mode" hide-footer hide-header>
      <h5>Options d'importation</h5>
      <hr>
      <p>
        Le fichier <b>{{ selectedFile ? selectedFile.fileName : null }}</b> existe déjà dans ce dossier. Veuillez choisir une option d'importation.
      </p>
      <b-form-group>
        <b-form-radio-group v-model="conflictResolutionMode">
          <b-form-radio value="replace">Remplacer le fichier existant (nouvelle version)</b-form-radio>
          <b-form-radio value="rename">Conserver les deux fichiers (renommer le nouveau fichier)</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <br>
      <div>
        <button class="btn btn-secondary" @click="$bvModal.hide('file-conflict-resolution-mode')" :disabled="processing">Annuler</button>
        <button class="btn btn-info" @click="() => uploadFile(selectedFile, conflictResolutionMode === 'replace')"
                :disabled="processing">
          Importer
          <b-spinner v-if="processing" small class="ms-1"/>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.clickable {
  cursor: pointer;
}

.min-h-400 {
  min-height: 400px;
}

.min-h-100p {
  min-height: 100%;
}

.categories-list li > a.active,
.categories-list li > .custom-accordion > a.active {
  background-color: #f5f5f5;
  color: #16181b;
  border-radius: 0.25rem;
}
</style>
