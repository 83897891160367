import {defineStore} from "pinia";
import {objectToFormData} from "../utils";
const axios = require('axios');

export const useFilemanagerUploadStore = defineStore('fileManagerUpload',{
    state() {
        return {
            uploadQueue: [
                /**
                  example:
                 {
                    id: 1,
                    filename: "test.jpg",
                    uploadProgress: 50,
                    status: "uploading",
                    mime: "image/jpeg",
                    abortController: abortController
                 }
                 */
            ],
        }
    },
    actions: {
        cancelAllUploads() {
            this.uploadQueue.forEach(item => {
                if(item.abortController) {
                    item.abortController.abort()
                }
            })
        },
        cancelUpload(id) {
            console.log('cancelUpload', id)
            const item = this.uploadQueue.find(item => item.id === id)
            const itemIndex = this.uploadQueue.findIndex(item => item.id === id)
            if (item && item.abortController) {
                item.abortController.abort()
            }
            this.uploadQueue.splice(itemIndex, 1)
        },
        clearAll() {
            this.cancelAllUploads()
            this.uploadQueue = [];
        },
        upload(file, id, url, additionalData = {}) {
            const store = this;

            const uploadItem = {
                id,
                filename: file.name,
                uploadProgress: 0,
                status: 'uploading',
                mime: file.type,
                abortController: new AbortController()
            }
            this.uploadQueue.push(uploadItem);

            const form = objectToFormData(additionalData)
            form.append('file', file)

            return axios.post(url, form, {
                signal: uploadItem.abortController.signal,
                onUploadProgress: (progressEvent) => {
                    const {loaded, total} = progressEvent
                    const percent = Math.floor((loaded * 100) / total)

                    store.$patch((state) => {
                        const index = state.uploadQueue.findIndex(item => item.id === id)
                        state.uploadQueue[index].uploadProgress = percent
                    })
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }).then(r => {
                store.$patch((state) => {
                    const index = state.uploadQueue.findIndex(item => item.id === id)
                    state.uploadQueue[index].status = 'complete'
                })
                return r
            })
        }
    },
    getters: {
        hasUploadStatus(state) {
            return state.uploadQueue.some(item => item.status === 'uploading');
        },
        items(state) {
            return state.uploadQueue;
        },
        isEmpty(state) {
            return state.uploadQueue.length === 0;
        }
    }
})