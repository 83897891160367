<script>
/**
 * Page-header component
 */
export default {
  name: "page-header-with-button",
  methods: {
    display() {
      this.$emit('displayCareerItemForm');
    }
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    button_label: {
      type: String,
      default: "Add",
    },
    isDisabled: {
        type: Boolean,
        required: true
     }
  },
};
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">{{ title }}</h4>
        <div class="page-title-right">
          <button :disabled="isDisabled" class="btn btn-success ms-auto mb-2" @click="display()">
            {{ button_label }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
