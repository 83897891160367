<script>
import axios from 'axios';
import Toaster from 'v-toaster';
import Multiselect from "vue-multiselect";
import { FilePicker } from '../../../file_picker';

/**
 * Page-header component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      templates: null,
      template: null,
      templateFields: null,
      showCreate: false,
      showTemplateFields: false,
      application: null,
      file: null,
      additionalFiles: [],
      workflows: [],
      workflow: null,
      errors: null,
      submitted: false,
      currentUserPerms: [],
      userPerms:null,
      roleUserPerms:null,
    };
  },
  created(){
    this.getApplicationTypes()
    this.getWorkflows()
    this.filePicker = new FilePicker('application-additional-files');

     axios.get('/api/user').then( response => {
        this.userPerms = response.data.user_permissions.map(currentUserPerms => currentUserPerms.name);
        this.roleUserPerms = response.data.role_permissions.map(currentUserPerms => currentUserPerms.name);
        this.currentUserPerms = this.userPerms.concat(this.roleUserPerms);
    });
  },
  beforeDestroy() {
    this.filePicker.dispose();
  },
  methods:{
    addAdditionalFile() {
      this.filePicker.pickFile((files) => {
        console.log('files', files)
        this.additionalFiles.push(files[0]);
      }, false, [
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ])
    },
    getApplicationTypes() {
      axios.get('/api/application_types/list').then(response => {
        this.templates = response.data
      });
    },
    getWorkflows() {
      axios.get('/api/validations/list').then(response => {
        this.workflows = response.data;
      });
    },
    closeForm() {
      this.resetFormFields();
      this.showCreate = false;
    },
    checkFormFields() {
      var result = true;

      if (this.file === null) {
        this.$toaster.warning('Vous devez télécharger au moins un fichier.');
        result = false;
      } 
      
      if (this.template === null) {
        this.$toaster.warning('Vous devez sélectionner un type de demande.');
        result = false;
      }

      return result;
    },
    resetFormFields() {
        this.template = null;
        this.templateFields = null;
        this.showTemplateFields = false;
        this.showCreate = false;
        this.file = null;
        this.additionalFiles = [];
        this.workflow = null;
        this.application = null;
        this.submitted = false;
    },
    createApplication(){
        if (!this.checkFormFields()) {
          return;
        }

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        };

        var data = new FormData();
        data.append('meta', JSON.stringify(this.application));
        data.append('file', this.file, this.file.name);
        data.append('workflow', JSON.stringify(this.workflow));
        data.append('type', this.template.name);
        data.append('type_code', this.template.code);
        data.append('application_type_id', this.template.id);

        if(this.additionalFiles.length > 0) {
          this.additionalFiles.forEach(file => {
            data.append('additional_files[]', file, file.name);
          });
        }

        this.submitted = true;
        axios.post('/api/application/store', data, config)
        .then( response => {
          this.$toaster.success('La demande a été créée avec succès.');
          this.resetFormFields();
          this.showCreate = false;
          this.$emit('applicationAdded');
        })
        .catch( error => {
          this.errors = error.response.data;
          this.$toaster.error("Une erreur est survenu lors de l'enregistrement de la demande.");
        });
    },
    loadTemplate(){
        if (this.template !== null) {
            this.application = {};
            this.showTemplateFields = true;
        }
    },
    onLoadFile(e){
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.file = e.target.files[0];
    },
    currentUserCan(namePermission){
      return this.currentUserPerms.includes(namePermission);
    },
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    button_label: {
      type: String,
      default: 'Nouvelle demande',
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">{{ title }}</h4>
        <div class="page-title-right">
          <button :disabled="!currentUserCan('application-create')" class="btn btn-success ms-auto mb-2" @click="showCreate = true">{{ button_label }}</button>
        </div>
      </div>
    </div>
    <b-modal size="lg" v-model="showCreate" title="Nouvelle demande" centered>
                <form>
                    <div class="mb-3" v-if="templates !== null" style="margin-bottom: 10px;">
                        <multiselect 
                            class="col" :show-labels="false" label="name" placeholder="Choisissez un type de document" 
                            v-model="template" :options="templates" @input="loadTemplate">
                        </multiselect>
                    </div>
                    <div v-if="showTemplateFields === true">
                        <div style="margin-top: 30px; margin-bottom: 10px; font-size: 15px;">Informations générales</div>
                        <div v-for="field in template.fields" :key="field.order">
                            <div style="margin-bottom: 10px;">{{ field.placeholder }}</div>
                            <div v-if="field.type.type !== 'select'" class="mb-3">
                                <input :type="field.type.type" v-model="application[field.name]" class="form-control"/>
                                <div v-if="submitted && errors !== null" style="color: red;">
                                  <span v-if="errors[field.name]">{{ errors[field.name][0] }}</span>
                                </div>
                            </div>
                            <div v-else class="mb-3">
                                <multiselect 
                                    class="col" :show-labels="false" placeholder="Sélectionnez une option" 
                                    v-model="application[field.name]" :options="field.options.split('\n')">
                                </multiselect>
                                <div v-if="submitted && errors !== null" style="color: red;">
                                  <span v-if="errors[field.name]">{{ errors[field.name][0] }}</span>
                                </div>
                            </div>
                        </div>
                            
                        <div class="mb-3">
                            <div style="margin-bottom: 10px;">Document</div>
                            <input type="file" @input="onLoadFile" id="file" class="form-control" />
                            <div v-if="submitted && errors !== null" style="color: red;">
                              <span v-if="errors['file']">{{ errors['file'][0] }}</span>
                            </div>
                        </div>

                        <div class="mb-1">
                          <div style="margin-bottom: 10px;margin-top: 24px">Documents additionnels</div>
                          <div>
                            <div v-for="(file, index) in additionalFiles" :key="index" class="mb-3">
                              <div class="d-flex justify-content-between align-items-center">
                                <div>{{ file.name }}</div>
                                <div>
                                  <b-button variant="outline-danger" @click="additionalFiles.splice(index, 1)">Supprimer</b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <b-button variant="outline-dark" @click="addAdditionalFile()">Ajouter un document</b-button>
                        </div>

                        <div style="margin-top: 30px; margin-bottom: 10px; font-size: 15px;">Validation</div>
                          <div class="mb-3">
                              <multiselect 
                                  class="col" 
                                  :show-labels="false" 
                                  placeholder="Sélectionnez une option"
                                  label="name"
                                  v-model="workflow" :options="workflows">
                              </multiselect>
                          </div>
                    </div>
                </form>
                <template v-slot:modal-footer>
                    <b-button variant="secondary" @click="closeForm()">Fermer</b-button>
                    <b-button variant="primary" @click="createApplication()">Enregistrer</b-button>
                </template>
            </b-modal>
  </div>
  <!-- end page title -->
</template>
