<script>
import Layout from "../../layouts/main";
import PageHeaderWithButton from "../contacts/component/page-header-with-button";
import Multiselect from "vue-multiselect";
import {exposeRawDataProperty} from "../../utils";

const axios = require('axios');

/**
 * Contacts-list component
 */
export default { 
  components: { Layout, PageHeaderWithButton, Multiselect},
  //get data from API
  created(){
    this.getUsers();
    axios.get('/api/roles/list').then(response => {
       this.roles  = response.data;
    })
  },
  methods : {
    userAddedFromChild(full_name, dgdi_id, lastname, firstname, role ){
      this.users.unshift({
        full_name: full_name,
        dgdi_id: dgdi_id,
        lastname : lastname,
        firstname : firstname,
        roles : [role],
        last_login : "-",
        active : true,
        
      })
    },
    getUsers(){
      axios.get('/api/users',{
              params: {
              dgdi_id: this.dgdi_id,
              lastname: this.lastname,
              role_id: this.role ? this.role.id : null,
              orderBy: this.orderBy ? this.orderBy : 'dgdi_id',
              sortBy: this.sort ? this.sort : 'desc',
              page:this.currentPage,
          }}).then(response => {
              this.usersData = response.data;
              this.users = this.usersData.data;
              this.startIndex = this.usersData.from;
              this.endIndex = this.usersData.to;
              this.perPage = this.usersData.per_page;
              this.currentPage = this.usersData.current_page;
              this.total = this.usersData.total;
            }).catch((error) => {
             this.$toaster.error(error.response.data.message);
      });
    },
    nameSorting(name){
      this.orderBy = name;
      if(this.sort === null){
        this.sort = "asc";
      }else if(this.sort === "asc"){
        this.sort = "desc";
      }else if(this.sort === "desc"){
        this.sort = "asc";
      }
      this.getUsers();
    },
    resetList(){
      this.dgdi_id = "";
      this.lastname = "";
      this.role = "";
      this.getUsers();
    },
    
  },
  data() {
    return {
      lastname: null,
      dgdi_id : null,
      usersData : null,
      role:null,
      roles:[],
      users : null,
      title: "Utilisateurs",
      button_label: "Ajouter",
      showModal: false,
      currentPage: 1,
      perPage: 1,
      startIndex: 10,
      userIds: [],
      endIndex: 1,
      total: null,
      sort: null,
      orderBy: null,
    };
  }
};
</script>
<template>
  <div>
    <PageHeaderWithButton :title="title" :button_label="button_label" @userAdded="userAddedFromChild" />
    <template>
      <div>
      <b-form >   
        <div class="row" style="margin-bottom:10px;">
          <div class="col-sm-8">
            <div class="row">
              <div class="col">
                <b-form-input
                      id="inline-form-input-name"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="Matricule"
                      v-model="dgdi_id"
                    ></b-form-input>
              </div>
              <div class="col" >
                <b-form-input
                      id="inline-form-input-name"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      placeholder="Nom de famille"
                      v-model="lastname"
                    ></b-form-input>
              </div>
              <div class="col" >
                <multiselect :show-labels="false" ref ='role'  class="mb-2 mr-sm-2 mb-sm-0" label="name" v-model="role" :options="roles" placeholder="Sélectionner un role"></multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-2">
               <b-button  @click.prevent="getUsers" variant="primary">Filtrer</b-button>
              </div>
              <div class="col-2">
                <b-button @click.prevent="resetList" variant="warning">Réinitialiser</b-button>
              </div>
            </div>
          </div>
        </div> 
    </b-form>
    </div>
    </template>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover align-middle">
                <thead class="table-light">
                  <tr>
                    <th scope="col" style="width: 150px;">
                      Matricule 
                      <span class="logo-sm"  style="float:right; cursor:pointer;" @click.prevent="nameSorting('dgdi_id')">
                        <i :class="{'fas fa-sort-alpha-up':( sort == 'desc' && orderBy == 'dgdi_id') || ( orderBy == 'lastname') ||sort == null ,'fas fa-sort-alpha-down-alt': (sort == 'asc' && orderBy == 'dgdi_id')|| ( orderBy == 'lastname')}"></i>
                      </span>
                    </th>
                    
                    <th scope="col">
                      Noms
                      <span class="logo-sm"  style="float:right; cursor:pointer;" @click.prevent="nameSorting('lastname')">
                        <i :class="{'fas fa-sort-alpha-up':( sort == 'desc' && orderBy == 'lastname') || ( orderBy == 'dgdi_id') || sort == null ,'fas fa-sort-alpha-down-alt': (sort == 'asc' && orderBy == 'lastname') || ( orderBy == 'dgdi_id')}"></i>
                      </span>
                    </th>
                    <th scope="col" >Dernière connexion</th>
                    <th scope="col">Statut</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user in users" :key="user.id">
                    <td>
                        <span style="font-weight: 800;">{{user.dgdi_id}}</span>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-1">
                        <router-link :to="`user/view/${user.dgdi_id}`" class="text-dark">{{user.full_name }}</router-link>
                      </h5>
                      <p class="text-muted mb-0"  >{{user.roles[0].name}}</p>
                    </td>
                    <td >{{user.last_login}}</td>
                    <td>
                      <div v-if="user.active == 0" class="badge badge-soft-dark font-size-11 m-1">{{ "Archivé" }}</div>
                      <div v-else class="badge badge-soft-success font-size-11 m-1">{{ "Actif" }}</div>
                    </td>
                  
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-md-between align-items-md-center" v-if="users">
              <div class="col-xl-7">{{startIndex}} - {{endIndex}} sur {{total}}</div>
             <!-- end col-->
             <div class="col-xl-5">
              <div class="text-md-end float-xl-end mt-2 pagination-rounded">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="perPage"
                    @input="getUsers"
                  ></b-pagination>
                </div>
              </div>
            <!-- end col-->
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
