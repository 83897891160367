<script>
/**
 * Page-header component
 */
export default {
  name: "page-header",
  methods: {
    display() {
      this.$emit('displayForm');
    }
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    buttonLabel: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
};
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">{{ title }}</h4>
        <div v-if="buttonLabel != null" class="page-title-right">
          <button class="btn btn-success ms-auto mb-2" @click="display()">
            {{ buttonLabel }}
          </button>
        </div>
        <div v-else-if="items.length > 0" class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
