<script>
import axios from 'axios';
import Toaster from 'v-toaster';
import Multiselect from "vue-multiselect";

/**
 * Page-header component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      file: null,
      name: null,
      errors: null,
      submitted: false
    };
  },
  created(){

  },
  methods:{
    checkFormFields() {
			var result = true;

			if (this.file === null) {
					this.$toaster.warning('Vous devez télécharger au moins un fichier.');
					result = false;
			}
			return result;
    },
    onLoadFile(e){
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.file = e.target.files[0];
        this.name = this.file.name;
    },
    save(){
			if (! this.checkFormFields()) {
				return;
			}

			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			};

			var data = new FormData();
			data.append('file', this.file, this.file.name);
      data.append('name', this.name);

      this.submitted = true;
			axios.post('/api/application/'+ this.id +'/documents/add', data, config)
			.then(response => {
				this.$toaster.success(response.data);
				this.$emit('documentsUpdated');
			})
			.catch(error => {
        this.errors = error.response.data;
        console.log(error.response.data);
				this.$toaster.error("Le document n'a pas été enregistré");
			});
    }
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>  
  <form>
    <div class="mb-3">
        <div style="margin-bottom: 10px;">Document</div>
        <div class="mb-3">
          <input type="text"  v-model="name" class="form-control" placeholder="Nom du document"/>
        </div>
        <input type="file" @input="onLoadFile" id="file" class="form-control" />
        <div v-if="submitted && errors !== null" style="color: red;">
          <span v-if="errors['file']">{{ errors['file'][0] }}</span>
          <span v-if="errors['name']">{{ errors['name'][0] }}</span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="save()">Enregistrer</button>
    </div>
  </form>
</template>
