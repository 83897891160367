<script>
import axios from 'axios';
import Layout from "../../layouts/main";
import Swal from "sweetalert2";
import pageHeader from "@modules/Application/Resources/js/base/page-header.vue";
import applicationTypesTable from "@modules/Application/Resources/js/components/type-table.vue";
import applicationTypesModalForm from "@modules/Application/Resources/js/components/type-modal-form.vue";

/**
 * Contacts-list component
 */
export default {
  components: { 
    Layout, 
    pageHeader,
    applicationTypesModalForm,
    applicationTypesTable
	},
	data() {
    return {
      title: "Types de Demandes",
      items: [],
      buttonLabel: "Ajouter",
      applicationTypesRaw: {},
      applicationType: null,
      showForm: false,
      currentPage: 1,
    };
  },
  created(){
    this.getApplicationTypes();
  },
  methods : {
		getApplicationTypes(){
      axios.get('/api/application_types', {
        params: {
          paginate:true,
          page:this.currentPage,
        }
      }).then(response => {
        this.applicationTypesRaw = response.data;
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
    refreshTable() {
      this.getApplicationTypes();
      this.clearData();
    },
    deleteApplicationType() {
      Swal.fire({
        title: "Êtes-vous sûr.e ?",
        text: "Le type de demande sera supprimé.",
        showCancelButton: true,
        confirmButtonColor: "#003da5",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler"
      }).then(result => {
        if (result.value) {
          axios.get(`/api/application_type/delete/${this.applicationType.id}`)
          .then(response => {
            this.getApplicationTypes();
            this.clearData();
            this.$toaster.success('Le type de demande a été supprimé.');
          })
          .catch(error => {
            if (parseInt(error.response.status) != 500) {
              this.$toaster.error(error.response.data.message);
            } else {
              this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
            }
          });
        }
      });
    },
    toPage(pageNumber) {
      this.currentPage = pageNumber;
      this.getApplicationTypes()
    },

    showSelectedApplicationType(selectedApplicationType){
      this.applicationType = selectedApplicationType;
      this.showForm = true;
    },

    clearData(){
      this.applicationType = null;
      this.showForm = false;
    },
  }
};
</script>
<template>
  <div>
    <page-header :title="title" :buttonLabel="buttonLabel" @displayForm="showForm = true"/>
    <application-types-table :applicationTypesData="applicationTypesRaw" 
      @onPageClick="toPage" @displayForm="showSelectedApplicationType"/>
    <application-types-modal-form :showForm="showForm" :selectedApplicationType="applicationType" 
      @hideForm="clearData" @formSubmit="refreshTable" @delete="deleteApplicationType"/>
  </div>
</template>