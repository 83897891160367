<template>
    <Layout>

      <router-view></router-view>
    </Layout>
</template>
<script>
import Layout from "../layouts/main";

export default {
  components: {
    Layout,
  },
    props: {
        component: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: () => ""
        }
    },
    computed: {
        component_name() {
            return !!this.$root.$options.components[this.component] ? this.component : "pages-404";
        }
    }
};
</script>
