<script>
import axios from 'axios';
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import AddDocument from "./components/add-document.vue";
/**
 * Projects-overview component
 */
export default {
  components: { 
    Layout, 
    PageHeader,
    Multiselect,
    AddDocument,
    ckeditor: CKEditor.component,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      config: {
        placeholder: 'Entrer votre commentaire'
      },
      content: null,
      title: "",
      items: [
        {
          text: "Demandes",
          to: "/applications",
        },
        {
          text: "Demande",
          active: true
        }
      ],
      currentUserId: null,
      application: null,
      links: null,
      steps: null,
      files: null,
      comments: null,
      currentFile: null,
      stepFile: null,
      assignee: null,
      assignees: null,
      currentStep: null,
      nextStep: null,
      hasPreviousStep: false,
      validationValues: null,
      canValidate: false,
      finalDecision: null,
      decision: null,
      decisions: [
        'REFUSÉ',
        'ACCEPTÉ'
      ],
      currentDocumentsIds: null,
      showAddFile: false,
      errors: null,
      submitted: false
    };
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      axios.get('/api/application/edit/' + this.$route.params.id).then(response => {
        this.application = response.data.application;
        this.links = response.data.links;
        this.nextStep = response.data.next_step;
        this.hasPreviousStep = response.data.has_previous_step;
        this.files = response.data.files;
        this.assignees = response.data.assignees;
        this.validationValues = response.data.validation_values;
        this.canValidate = response.data.can_validate;
        this.comments = response.data.comments;
        this.currentUserId = response.data.current_user.id;
        this.steps = this.application.validation_step.validation.validation_steps.map(step => step.name);
        this.currentStep = this.application.validation_step;
        this.currentFile = this.application.document;
        this.finalDecision = this.application.decision;
      });
    },
    saveComment() {
      if (this.content === null || this.content === '') {
        this.$toaster.error("Merci d'entrer un commentaire");
        return;
      }
  
      axios.post(
        '/api/application/'+ this.$route.params.id + '/comment/save',
        {
          content: this.content
        })
      .then(response => {
        this.comments = response.data;
        this.$toaster.success("Votre commentaire a bien été enregistré");
      })
      .catch(error => {
        this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
      });
    },
    editComment(id) {
      this.$toaster.warn("Votre commentaire a bien été supprimé");
    },
    deleteComment(id) {
      axios.get('/api/application/'+ this.$route.params.id + '/comment/delete/' + id)
      .then(response => {
        this.comments = response.data;
        this.$toaster.success("Votre commentaire a bien été supprimé");
      })
      .catch(error => {
          this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
      });
    },
    deleteDocument(document) {
      Swal.fire({
        title: "Êtes-vous sûr.e ?",
        text: "Le document " + document.name + " sera supprimé",
        showCancelButton: true,
        confirmButtonColor: "#003da5",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler"
      }).then(result => {
        if (result.value) {
          axios.get('/api/document/delete/' + document.id)
          .then(response => {
            this.files = this.files.filter((item) => item.id != document.id);
          })
          .catch(error => {
            if (parseInt(error.response.status) == 409) {
              this.$toaster.error(error.response.data.message);
            } else {
              this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
            }
          });
        }
      });
    },
    canBeDelete(file){
      if (this.currentDocumentsIds === null || this.currentDocumentsIds == [])
        return false;
      return this.currentDocumentsIds.includes(file.id);
    },
    updateDocumentsList(){
      this.showAddFile = false;

      axios.get('/api/application/'+ this.application.id +'/documents').then(response => {
        this.files = response.data;
      });
    },
    addFile(){
      this.showAddFile = true;
    },
    onLoadFile(e){
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.stepFile = e.target.files[0];
    },
    cancelStep() {
      const self = this;
      Swal.fire({
        title: "Êtes-vous sûr.e ?",
        text: "La validation de l'étape " + this.currentStep.name + " sera annulée",
        showCancelButton: true,
        confirmButtonColor: "#003da5",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler"
      }).then(result => {
        if(result.value) {
          let data = new FormData();
          self.submitted = true;

          axios.post('/api/application/cancel-validation/' + self.$route.params.id, data)
              .then(response => {
                this.$toaster.success("La demande a bien été annulée");
                this.load()
              })
              .catch( error => {
                this.errors = error.response.data;
                this.$toaster.error("La demande n'a pu être annulée.");;
              })
        }
      })

    },
    validateStep(){
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };

      var data = new FormData();

      if (this.assignee)
        data.append('assignee_id', this.assignee.id);
      
      if (this.currentStep.is_file_required != 0 && this.stepFile)
        data.append('step_file', this.stepFile, this.stepFile.name);

      if (this.decision)
        data.append('decision', this.decision);

      this.submitted = true;
      axios.post('/api/application/update/' + this.$route.params.id, data, config)
        .then(response => {
          this.$toaster.success("La validation a bien été prise en compte");
          axios.get('/api/application/edit/' + this.$route.params.id).then(response => {
            this.application = response.data.application;
            this.links = response.data.links;
            this.nextStep = response.data.next_step;
            this.hasPreviousStep = response.data.has_previous_step;
            this.files = response.data.files;
            this.assignees = response.data.assignees;
            this.validationValues = response.data.validation_values;
            this.canValidate = response.data.can_validate;
            this.comments = response.data.comments;
            this.currentUserId = response.data.current_user.id;
            this.currentStep = this.application.validation_step;
            this.finalDecision = this.application.decision;
    });
        })
        .catch( error => {
          this.errors = error.response.data;
          this.$toaster.error("La demande n'a pu être validée.");;
        });
    }
  }
};
</script>

<template>
  <div>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-8">
        <div class="card"  v-if="application">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-end">
                <h3 v-if="finalDecision"><span :class="{
                          'badge bg-success': finalDecision === 'ACCEPTÉ',
                          'badge bg-dark': finalDecision === 'REFUSÉ',
                        }">{{ finalDecision }}</span></h3>
              </div>
              <div class="media-body overflow-hidden" style="float: left;">
                <div class="text-muted" style="margin-bottom: 5px;">{{ application.type }}</div>
                <h5 class="text-truncate" style="font-size: 20px;">{{ application.metadata.numero }} - {{ application.metadata.objet }}</h5>
                <h5><span :class="{
                          'badge bg-info': application.status === 'en attente',
                          'badge bg-warning': application.status === 'en cours',
                          'badge bg-danger': application.status === 'en cours de validation',
                          'badge bg-dark': application.status === 'complet',
                        }">{{ application.status.toUpperCase() }}</span></h5>
              </div>
              
            </div>

            <h5 class="font-size-15 mt-4" style="margin-bottom: 15px;">Informations générales</h5>
            <div class="row" v-for="(value, index) in application.metadata" :key="index">
               <div class="text-muted col-lg-2">{{ index.charAt(0).toUpperCase() + index.slice(1) }}</div>
               <div class="text-muted col-lg-10">{{ application.metadata[index] }}</div>
            </div>
            <div class="row">
               <div class="text-muted col-lg-2">Créé par </div>
               <div class="text-muted col-lg-10">
                 {{ application.user.dgdi_id + ' - ' +  application.user.firstname + ' ' + application.user.lastname }}
               </div>
            </div>
            <div class="row" style="margin-top: 30px;" v-if="currentFile">
              <div class="text-muted col-lg-3">
                <h5 class="font-size-14 mb-1">
                  <a :href="'/storage/' + currentFile.path" class="text-dark" target="_blank">{{ currentFile.name }}</a>
                </h5>
                <small>Taille : {{ currentFile.size / 1000 }} KB</small>
              </div>
              <div class="text-muted col-lg-1">
                <div class="text-center">
                  <a :href="'/storage/' + currentFile.path" class="text-dark" target="_blank">
                    <i class="bx bx-download h3 m-0"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card"  v-if="application">
          <div class="card-body">
            <h5 class="font-size-15" style="margin-bottom: 15px;">Signatures</h5>
            <div class="row" v-for="value in validationValues" :key="value.id">
               <div class="text-muted" style="margin-bottom: 0.5rem;">
                Validé le {{ value.date }} par {{ value.user.dgdi_id + ' - ' +  value.user.firstname + ' ' + value.user.lastname }}
               </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="application">
          <div class="card-body">
            <h5 v-if="comments" class="font-size-15">{{ comments.length }} Commentaire{{ (comments.length > 1) ? 's' : '' }}</h5>
            <div style="padding: 1rem 0">
              <div v-for="comment in comments" :key="comment.id" style="margin-bottom: 1rem;">
                <h6>{{ comment.author.full_name }}</h6>
                <div v-html="comment.content" style="margin-bottom: -0.5rem;"></div>
                <small>
                  <span v-if="currentUserId === comment.author_id">
                    <!-- <a href="javascript: void(0);" @click="editComment(comment.id)">Modifier</a> |  -->
                    <a href="javascript: void(0);" @click="deleteComment(comment.id)">Supprimer</a> | 
                  </span>
                  {{ comment.created_at | moment("from", "now", true) }}
                  </small>
              </div>
            </div>
            <ckeditor v-model="content" :editor="editor" :config="config"></ckeditor>
            <b-button variant="primary" @click="saveComment()" style="margin-top: 1rem;">
              Envoyer
              <i class="fab fa-telegram-plane ms-1"></i>
            </b-button>
          </div>
        </div>
      </div>
      <!-- end col -->
      <div class="col-lg-4">
        <div class="card" v-if="canValidate">
          <div class="card-body">
            <h4 class="card-title" style="margin-bottom: 1rem;">Validations</h4>
            <b-form>
              <div class="row">
                <div v-if="nextStep && assignees">
                  <label class="col-sm-3 col-form-label">Assigné à</label>
                  <multiselect
                    :show-labels="false" 
                    class="col"
                    label="full_name"
                    v-model="assignee" 
                    :options="assignees"
                    placeholder="Sélectionnez un utilisateur" >
                  </multiselect>
                </div>
                <div class="mt-3" style="margin-bottom: 0.5rem;" v-if="currentStep">
                  <input v-if="parseInt(currentStep.is_file_required) !== 0" type="file" @input="onLoadFile" id="stepFile" class="form-control">
                  <div v-if="submitted && errors !== null" style="color: red;">
                    <span v-if="errors.step_file">{{ errors.step_file[0] }}</span>
                  </div>
                </div>
                <div v-if="!nextStep" class="mb-3">
                  <label class="col-sm-3 col-form-label">Décision</label>
                  <multiselect
                    :show-labels="false" 
                    class="col"
                    v-model="decision" 
                    :options="decisions"
                    placeholder="Sélectionnez un utilisateur" >
                  </multiselect>
                </div>
                <b-button variant="primary" @click="validateStep()">Valider</b-button>
                <b-button variant="outline-danger" class="mt-2" v-if="hasPreviousStep" @click="cancelStep()">Annuler</b-button>
              </div>
            </b-form>
          </div>
        </div>
        <div class="card">
          <div class="card-body" v-if="currentStep">
            <h4 class="card-title" style="margin-bottom: 1rem;">Étapes de validation</h4>
            <div class>
              <ul v-for="step in steps" :key="step.id" class="verti-timeline list-unstyled">
                <li class="event-list">
                  <div class="event-timeline-dot">
                    <i :class="{ 
                        'bx': true, 
                        'bx-right-arrow-circle' : true, 
                        'bx-fade-right' : step == currentStep.name && application.status !== 'complet'
                      }">
                    </i>
                  </div>
                  <div class="media">
                    <div class="media-body">
                      <div>
                        <h5 class="font-size-15">
                          <a href="javascript: void(0);" class="text-dark">{{ step }}</a>
                        </h5>
                        <!-- <span class="text-primary">2011 - 13</span> -->
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-end">
                <div class="input-group input-group-sm" v-if="canValidate && (application.status != 'complet')">
                  <a href="#" @click.prevent="addFile()">
                    <i class="fa fa-plus"></i>
                  </a>
                </div>
              </div>
              <h4 class="card-title mb-4">Documents attachés</h4>
            </div>
            <div class="table-responsive mb-0">
              <table class="table table-nowrap table-centered table-hover mb-0">
                <tbody>
                  <tr v-for="file in files" :key="file.id" style="min-height: 65px;">
                    <td>
                      <h5 class="font-size-14 mb-1">
                        <a :href="'/storage/' + file.path" class="text-dark" target="_blank">{{ file.name }}</a>
                      </h5>
                      <small>Taille : {{ file.size / 1000 }} KB</small>
                    </td>
                    <td style="display: inline-block; min-height: 65px; cursor: pointer;">
                      <div class="text-center">
                        <a :href="'/storage/' + file.path" class="text-dark" target="_blank">
                          <i class="bx bx-download h3 m-0"></i>
                        </a>
                      </div>
                      <div class="text-center" v-if="canValidate && (application.status != 'complet')">
                         <a @click="deleteDocument(file)" class="text-dark" target="_blank">
                          <i class="bx bx-x-circle h3 m-0"></i>
                        </a>       
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Demandes liées</h4>
            <div class>
              <div v-for="link in links" :key="link.id" style="margin-bottom: 10px;">
                <a :href="`/application/edit/${link.id}`">
                  <div class="text-muted">{{ link.type }}</div>
                  <div>{{ link.metadata.numero }} - {{ link.metadata.objet }}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <b-modal hide-footer size="lg" v-model="showAddFile" title="Ajouter un document" centered>
      <AddDocument :id="this.$route.params.id" @documentsUpdated="updateDocumentsList" />
    </b-modal>
    <!-- end row -->
  </div>
</template>
