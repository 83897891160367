const basicFields = [{
	"name": "date",
	"type": {
		"type": 'date',
		"display_type": 'Date'
	},
	"types": [
		{
			"type": 'date',
			"display_type": 'Date'
		}
	],
	"placeholder": "Date",
	"required": true,
	"basic": true,
	"can_update": false
},
{
	"name": "objet",
	"type": {
		"type": 'text',
		"display_type": 'Texte'
	},
	"types": [
		{
			"type": 'text',
			"display_type": 'Texte'
		},
		{
			"type": 'select',
			"display_type": 'Liste'
		}
	],
	"placeholder": "Objet",
	"required": true,
	"basic": true,
	"can_update": true
},
{
	"name": "nom",
	"type": {
		"type": 'text',
		"display_type": 'Texte'
	},
	"types": [
		{
			"type": 'text',
			"display_type": 'Texte'
		}
	],
	"placeholder": "Nom du dossier",
	"required": true,
	"basic": true,
	"can_update": false
}
];

export { basicFields };