<script>
import axios from "axios";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect
    },
    props: {
        selectedCareerItem: {
            type: Object,
            required: false,
        },
        showForm: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data() {
        return {
            selectPlaceholder: "Choisissez une entité",
            displayForm: this.showForm,
            departments: [],
            careerItem: {
                id: '',
                department: null,
                job_title: null,
                salary: null,
                start_dt: null,
                end_dt: null,
            }
        };
    },
    created() {
        console.log("career-item-form");
        this.careerItem = (this.selectedCareerItem) ? this.selectedCareerItem : this.careerItem;
        
        axios.get("/api/departments/list").then((response) => {
            this.departments = response.data
        });
    },
    methods: {
        addCareerItem() {
            var base = `/api/user/${this.$route.params.id}/career/update`;
            var uri = (this.careerItem.id) ? base + `/${this.careerItem.id}` : base;
            
            axios.post(uri, {
                department_id: this.careerItem.department.id,
                job_title: this.careerItem.job_title,
                salary: this.careerItem.salary,
                start_dt: this.careerItem.start_dt,
                end_dt: this.careerItem.end_dt,
            }).then((response) => {
                if (response.status == 200) {
                    this.$toaster.success(response.data.message);
                    this.$emit('careerItemAdded');
                    this.displayForm = false;
                    this.resetForm();
                }
            }).catch((error) => {
                this.$toaster.error(error.response.data.message);
                this.fields = error.response.data.reason;
                });
        },
        resetForm() {
            this.careerItem = {
                department: null,
                job_title: null,
                salary: null,
                start_dt: null,
                end_dt: null,
            };
        },
        hideForm(){
            this.resetForm();
            this.displayForm = false;
            this.$emit('hideForm');
        },
        haveError(label) {
        },
    },
};
</script>
<template>
<b-modal v-model="displayForm" @hide="hideForm()" size="lg" title="Nouvelle expérience" centered>
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <b-form>
                    <b-form-group
                    class="mb-4"
                    label="Département *"
                    label-for="Département"
                    label-cols-sm="3">
                        <multiselect
                            :placeholder="selectPlaceholder"
                            :show-labels="false"
                            class="col"
                            label="name"
                            v-model="careerItem.department"
                            :options="departments"
                            track-by="name">
                        </multiselect>
                        <div v-if="haveError('department')">
                            <p class="error-message">{{ showError("department") }}</p>
                        </div>
                    </b-form-group>
                    <b-form-group
                    class="mb-4"
                    label="Titre *"
                    label-for="horizontal-jobtitle-input"
                    label-cols-sm="3">
                        <b-form-input
                            v-model="careerItem.job_title"
                            id="horizontal-jobtitle-input"
                            type="text"
                            required
                        >
                        </b-form-input>
                        <div v-if="haveError('job_title')">
                            <p class="error-message">{{ showError("job_title") }}</p>
                        </div>
                    </b-form-group>
                    <b-form-group
                    class="mb-3"
                    label="Salaire *"
                    label-for="horizontal-salary-input"
                    label-cols-sm="3">
                        <b-form-input
                            id="horizontal-salary-input"
                            type="number"
                            name="salary"
                            v-model="careerItem.salary"
                            min="100000"
                        ></b-form-input>
                        <div v-if="haveError('salary')">
                            <p class="error-message">{{ showError("salary") }}</p>
                        </div>
                    </b-form-group>
                    <b-form-group
                    class="mb-3"
                    label="Date de début *"
                    label-for="horizontal-start-input"
                    label-cols-sm="3">
                        <b-form-input
                            id="horizontal-start-input"
                            type="date"
                            name="start_dt"
                            v-model="careerItem.start_dt"
                            required
                            :value="careerItem.start_dt"
                        ></b-form-input>
                        <div v-if="haveError('start_dt')">
                            <p class="error-message">{{ showError("start_dt") }}</p>
                        </div>
                    </b-form-group>
                    <b-form-group
                    class="mb-3"
                    label="Date de fin "
                    label-for="horizontal-end-input"
                    label-cols-sm="3"
                    >
                        <b-form-input
                            id="horizontal-end-input"
                            type="date"
                            name="end_dt"
                            v-model="careerItem.end_dt"
                        ></b-form-input>
                        <div v-if="haveError('end_dt')">
                            <p class="error-message">{{ showError("end_dt") }}</p>
                        </div>
                    </b-form-group>
                </b-form>
            </div>
        </div>
    </div>
    <template v-slot:modal-footer>
        <b-button variant="secondary" @click="hideForm()"
          >Fermer</b-button
        >
        <b-button variant="primary" @click.prevent="addCareerItem"
          >Enregistrer</b-button
        >
      </template>
</b-modal>
</template>
