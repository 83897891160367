<script>
import axios from 'axios';
import Multiselect from "vue-multiselect";
/**
 * Page-header component
 */
export default {
  name:'childComponent',
  components: {
    Multiselect
  },
  created(){
    axios.get('/api/roles').then( response => {
      response.data.forEach(role => {
        this.roles.push(role.name);
      });
    });
    axios.get('/api/user').then( response => {
        this.userPerms = response.data.user_permissions.map(currentUserPerms => currentUserPerms.name);
        this.roleUserPerms = response.data.role_permissions.map(currentUserPerms => currentUserPerms.name);
        this.currentUserPerms = this.userPerms.concat(this.roleUserPerms);
    });
  },
  methods:{
     currentUserCan(namePermission){
      return this.currentUserPerms.includes(namePermission);
    }
  },
  data() {
    return {
      roles:[],
      dgdi_id:null,
      lastname:null,
      firstname:null,
      email:null,
      password:null,
      password_confirmation:null,
      role:null,
      showModal: false,
      value: null,
      value1: null,
      currentUserPerms: [],
      userPerms:null,
      roleUserPerms:null,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    button_label: {
      type: String,
      default: 'Add',
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">{{ title }}</h4>
        <div class="page-title-right">
          <b-button :disabled="!currentUserCan('role-create')" href="role-create" class="btn btn-success ms-auto mb-2">{{ button_label }}</b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
