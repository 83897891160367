<script>
import axios from 'axios';
import chartsConfig from "../../../assets/js/charts-configs.js";

export default {
	data() {
		return {
			year: new Date().getFullYear(),
			years: [],
			donutChartSeries: null,
			donutChartData: null,
			donutChartOptions: chartsConfig.donutCharts
		}
	},
	created() {
		this.getApplicationsByYear();
	},
	methods: {
		getApplicationsByYear() {
			this.donutChartOptions.colors = [];
			this.donutChartData = null;
			this.donutChartSeries = null

			axios.get(`/api/dashboard/applications/charts/donut/year/${this.year}`).then(response => {
				this.donutChartData = response.data.series;

				// Extrait les couleurs et les étiquettes du tableau de séries
				this.donutChartOptions.colors = this.donutChartData.map(series => series.type_color);
				this.donutChartOptions.labels = this.donutChartData.map(series => series.type);
				this.donutChartSeries = this.donutChartData.map(series => parseFloat(series.number))

				// Extrait les années du tableau years
				this.years = response.data.years.map(item => parseInt(item.year));
			});
		},

	},
}
</script>
<template>
	<div>
		<div class="card">
			<div class="card-body">
				<div class="clearfix">
					<div class="float-end">
						<div class="input-group input-group-sm">
							<select class="form-select form-select-sm" v-model="year" @change="getApplicationsByYear()">
								<option v-for="year of years" :key="year">{{ year }}</option>
							</select>
							<label class="input-group-text">Année</label>
						</div>
					</div>
					<h4 class="card-title mb-4">Toutes les demandes</h4>
				</div>
				<div v-if="donutChartSeries">
					<apexchart class="apex-charts" :options="donutChartOptions" :series="donutChartSeries" dir="ltr" height="240" />
				</div>
				<div class="text-center text-muted">
					<div class="row">
						<div class="col-4" v-for="(data, index)  in donutChartData" :key="data.id">
							<div class="mt-4">
								<p class="mb-2 text-truncate">
									<i class="mdi mdi-circle me-1" :style="{ color: data.type_color }"></i>
									{{ data.type }}
								</p>
								<h5>{{ data.number }}</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
