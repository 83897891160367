<script>
import {debounce, debounceLeading, formatBytes, objectToFormData} from '../../../utils'
const axios = require('axios');
import Swal from "sweetalert2";

/**
 * Element component
 */
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: false,
    },
    click: {
      type: Function,
      required: false,
    },
    dbclick: {
      type: Function,
      required: false,
    },
    unselect: {
      type: Function,
      required: false,
    },
    canWrite: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      timeOutId: null,
      focused: false,
      newName: this.name,
      processing: false,
      error: null,
    }
  },
  computed: {
    isFolder() {
      return this.type === 'folder'
    },
    formattedSize() {
      return formatBytes(this.size ?? 0)
    },
    renameModalId() {
      return `rename-modal-${this.id}`
    },
    deleteModalId() {
      return `delete-modal-${this.id}`
    },
    hasError() {
      return this.error !== null
    },
    elementType() {
      return this.isFolder ? 'dossier' : 'fichier'
    },
    openActionName() {
      return this.isFolder ? 'Ouvrir' : 'Télécharger'
    }
  },
  created() {

  },
  methods: {
    /**
     * On click event
     */
    onClick() {
      if (this.timeOutId !== null) {
        clearTimeout(this.timeOutId)
        this.timeOutId = null
        this.dbclick && this.dbclick()
      } else {
        this.timeOutId = setTimeout(() => {
          this.timeOutId = null
          this.click && this.click()
          this.onFocus()
        }, 200)
      }
    },
    onFocus() {
      console.log('focus')
      this.focused = true
    },
    onBlur() {
      this.focused = false
      this.unselect && this.unselect()
    },
    handleCancelRename() {
      this.newName = this.name
      this.error = null
      this.$bvModal.hide(this.renameModalId)
    },
    handleValidateRename() {
      this.processing = true
      this.error = null

      const id = this.id;

      console.log('id', id)

      const url = '/api/filesmanager/rename/' + id;

      axios.post(url, objectToFormData({
        name: this.newName
      }, {
        'Content-Type': 'application/json'
      })).then(response => {
        this.$toaster.success('Le ' + this.elementType + ' a été renommé avec succès !')
        this.processing = false
        this.$bvModal.hide(this.renameModalId)
        this.$emit('reload', 'renamed-element')
        this.$bvModal.hide(this.renameModalId)
      }).catch(error => {
        if(error.response && error.response.status === 403) {
          this.$toaster.error('Vous n\'avez pas les permissions nécessaires pour faire cette action.')
        }
        this.processing = false
        this.error = error.response.data.message
      })
    },
    handleCancelDelete() {
      this.error = null
      this.$bvModal.hide(this.deleteModalId)
    },
    handleDelete() {
      this.processing = true;
      this.error = null;

      const id = this.id;

      const url = '/api/filesmanager/delete/' + id;

      axios.post(url, objectToFormData({}), {
        'Content-Type': 'application/json'
      }).then(response => {
        this.$toaster.success('Le ' + this.elementType + ' a été supprimé avec succès !')
        this.processing = false
        this.$bvModal.hide(this.deleteModalId)
        this.$emit('reload', 'deleted-element')
      }).catch(error => {
        if(error.response && error.response.status === 403) {
          this.$toaster.error('Vous n\'avez pas les permissions nécessaires pour faire cette action.')
        }
        this.processing = false
        this.error = error.response.data.message
      })
    }
  }
}
</script>

<template>
  <div
      class="card border folder-component"
      v-bind:class="{ 'border-primary': focused}"
      @click="onClick"
      v-click-outside="onBlur"
  >
    <div class="card-body p-3">
      <div class="float-end ms-2">
        <b-dropdown
            toggle-class="font-size-16 text-muted p-0"
            class="mb-2"
            variant="white"
            menu-class="dropdown-menu-end"
            right
        >
          <template #button-content>
            <i class="mdi mdi-dots-horizontal"></i>
          </template>

          <b-dropdown-item href="#" @click="$emit('open')">{{openActionName}}</b-dropdown-item>
          <b-dropdown-item v-if="canWrite" href="#" @click="$bvModal.show(renameModalId)">Renommer</b-dropdown-item
          >
          <b-dropdown-divider v-if="canWrite"></b-dropdown-divider>
          <b-dropdown-item v-if="canWrite" href="#" @click="$bvModal.show(deleteModalId)">Supprimer</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div class="avatar-xs me-3 mb-3">
        <div class="avatar-title bg-transparent rounded">
          <i v-if="isFolder"
              class="bx bxs-folder font-size-24 text-warning"
          ></i>
          <i v-else
              class="mdi mdi-text-box font-size-24 text-primary"></i>
        </div>
      </div>
      <div class="d-flex">
        <div class="overflow-hidden me-auto">
          <h5 class="font-size-13 text-truncate mb-1">
            <a href="javascript:void(0)" class="text-body">{{ name }}</a>
          </h5>
        </div>
        <div v-if="!isFolder" class="align-self-end ms-2">
          <p class="text-muted mb-0 font-size-12 text-nowrap">{{formattedSize}}</p>
        </div>
      </div>
    </div>
    <b-modal :id="renameModalId" hide-footer hide-header>
      <h5>Renommer</h5>
      <hr>
      <div class="form-group">
        <input type="text" class="form-control" id="rename-input" placeholder="Nouveau nom" v-model="newName" autofocus autocapitalize="words">
      </div>
      <br>
      <div>
        <button class="btn btn-secondary" @click="handleCancelRename">Annuler</button>
        <button class="btn btn-info" @click="handleValidateRename" :disabled="processing">
          Renommer
          <b-spinner v-if="processing" small class="ms-1" />
        </button>
      </div>
      <div v-if="hasError">
        <br>
        <p class="text-danger">{{error}}</p>
      </div>
    </b-modal>
    <b-modal :id="deleteModalId" hide-footer hide-header>
      <h5>Supprimer</h5>
      <p>Voulez-vous vraiment supprimer ce {{elementType}} ?</p>
      <hr>
      <div>
        <button class="btn btn-secondary" @click="handleCancelDelete">Annuler</button>
        <button class="btn btn-danger" @click="handleDelete">
          Supprimer
          <b-spinner v-if="processing" small class="ms-1" />
        </button>
      </div>
      <div v-if="hasError">
        <br>
        <p class="text-danger">{{error}}</p>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
  .folder-component {
    cursor: pointer;
  }
</style>