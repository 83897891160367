<script>
import Multiselect from "vue-multiselect";

const axios = require('axios')
const _ = require('lodash')

export default {
  components: {
    Multiselect,
  },
  props: {
    value: String
  },
  data() {
    return {
      tags: [],
      searchedTags: [],
      sharedBy: null,
      searchedUsers: [],
      dateRange: [],
      filters: {
        tags: [],
        sharedBy: null,
        dateRange: []
      },
    }
  },
  computed: {
    hasFilters() {
      return this.filters.tags.length > 0 || this.filters.sharedBy !== null || this.filters.dateRange.length > 0
    },
    hasSearchQuery() {
      return this.value.trim().length > 0
    }
  },
  methods: {
    onSelectTag(tag) {
      if(!this.tags.some(t => t.id === tag.id)) {
        this.tags.push(tag)
      }
    },
    onSelectUser(user) {
      this.sharedBy = user;
    },
    searchUsers: _.debounce(function(value) {
      axios.get('/api/users/search', {
        params: {
          q: value
        }
      })
          .then(response => {
            this.searchedUsers = response.data
          })
          .catch(error => {
            console.log(error)
            alert('Erreur innatendue');
          })
    }, 300),
    searchTag: _.debounce(function (value) {
      axios.get('/api/filesmanager/tags/search', {
        params: {
          q: value
        }
      })
          .then(response => {
            this.searchedTags = response.data
          })
          .catch(error => {
            console.log(error)
            alert('Erreur innatendue');
          })
    }, 300),
    removeTag(tag) {
      this.tags = this.tags.filter(t => t.id !== tag.id)
    },
    handleCloseModal() {
      this.$bvModal.hide('advanced-filters')
    },
    handleOpenModal() {
      this.tags = this.filters.tags;
      this.sharedBy = this.filters.sharedBy;
      this.dateRange = this.filters.dateRange;
      this.$bvModal.show('advanced-filters')
    },
    handleValidateFilters() {
      this.filters.tags = this.tags;
      this.filters.sharedBy = this.sharedBy;
      this.filters.dateRange = this.dateRange;
      if(this.hasFilters && this.hasSearchQuery) {
        this.$emit('input', this.value, this.filters)
      }
      this.handleCloseModal()
    },
    clearFilters() {
      this.filters.tags = [];
      this.filters.sharedBy = null;
      this.filters.dateRange = [];
      this.$emit('input', this.value, this.filters)
    }
  },
}

</script>

<template>
  <div class="search-box mb-2 me-2">
    <div class="position-relative">
      <input
          type="text"
          class="form-control bg-light border-light rounded"
          placeholder="Recherche..."
          :value="value"
          @input="$emit('input', $event.target.value, filters)"
      />
      <i class="bx bx-search-alt search-icon"></i>
      <i class="mdi mdi-close clear-search-icon" v-if="hasSearchQuery" @click="$emit('input', '', filters)"></i>
      <i class="bx bx-slider-alt search-filters-icon" @click="handleOpenModal"></i>
    </div>
    <div v-if="hasFilters" class="mt-2">
      <h5 class="font-size-14 d-flex flex-row justify-content-between align-items-center mb-2">
        <span>Filtres de recherche</span>
        <b-button
            variant="primary"
            size="sm"
            class="font-size-12"
            @click="clearFilters"
        >
          <i class="mdi mdi-close"></i>
        </b-button>
      </h5>
      <div v-if="this.filters.tags.length > 0" class="d-flex">
        <span class="me-3">Étiquettes: </span>
        <div class="flex-fill">
          <span class="badge bg-primary rounded-pill me-1 font-size-12" v-for="tag in this.filters.tags" :key="tag.id">
            {{ tag.name}}
          </span>
        </div>
      </div>
      <div v-if="this.filters.sharedBy !== null" class="d-flex">
        <span class="me-3">Partagé par: </span>
        <div class="flex-fill">
          <span class="badge bg-dark rounded-pill me-1 font-size-12">
            {{ this.filters.sharedBy.full_name}}
          </span>
        </div>
      </div>
      <div v-if="this.filters.dateRange.length > 0" class="d-flex">
        <span class="me-3">Rangée de dates: </span>
        <div class="flex-fill">
          <span class="badge bg-info rounded-pill me-1 font-size-12">
            {{ this.filters.dateRange[0] | moment('DD MMM YYYY') }} - {{ this.filters.dateRange[1] | moment('DD MMM YYYY') }}
          </span>
        </div>
      </div>
      <hr>
    </div>
    <b-modal id="advanced-filters" hide-footer hide-header>
      <h5>Recherche avancée</h5>
      <p>Sélectionner des filtres: </p>
      <hr>
      <h6 class="font-size-14">Par étiquettes:</h6>
      <multiselect
          label="name"
          track-by="id"
          :value="tags"
          :options="searchedTags"
          :hide-selected="true"
          :internal-search="false"
          :limit="4"
          :reset-after="true"
          :taggable="true"
          :multiple="true"
          open-direction="bottom"
          placeholder="Ajouter des étiquettes"
          @search-change="searchTag"
          @select="onSelectTag"
          @remove="removeTag"
        >
        <template v-slot="noResult">
          <span>Aucun résultat trouvé.</span>
        </template>
      </multiselect>
      <hr>
      <h6 class="font-size-14">Partagé par (utilisateur):</h6>
      <multiselect
        :multiple="false"
        label="full_name"
        track-by="id"
        :options="searchedUsers"
        :value="sharedBy"
        :internal-search="false"
        :limit="10"
        :taggable="true"
        :reset-after="true"
        placeholder="Sélectionner un utilisateur"
        @select="onSelectUser"
        @search-change="searchUsers"
        >
        <template v-slot="noResult">
          <span>Aucun résultat trouvé.</span>
        </template>
      </multiselect>
      <hr>
      <h6 class="font-size-14">Par rangée de dates:</h6>
      <date-picker :range="true" v-model="dateRange"/>
      <hr>
      <div>
        <button class="btn btn-secondary" @click="handleCloseModal">Annuler</button>
        <button class="btn btn-danger" @click="handleValidateFilters">
          Valider
        </button>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
  .search-box .form-control {
    padding-right: 80px;
    height: 38px;
  }
  .search-filters-icon, .clear-search-icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 40px;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    line-height: 38px;
    vertical-align: middle;
    text-align: center;
  }
  .search-filters-icon {
    right: 0;
  }
  .clear-search-icon {
    right: 40px;
  }
</style>