<script>
import Layout from "../../layouts/main";
import PageHeader from "./components/page-header-with-button.vue";
import ApplicationForm from "./components/form";
import LinkApplicationsForm from "./components/links.vue";
import SearchApplicationsForm from "./components/search.vue";
import AlertApplicationsForm from "./components/alerts.vue";

import Swal from "sweetalert2";
import StepProgress from 'vue-step-progress';
import Multiselect from "vue-multiselect";
import axios from 'axios';
import moment from 'moment';

import '../../../css/step-progress.css';

/**
 * Projects-list component
 */
export default {
  components: { 
    Layout, 
    PageHeader,
    ApplicationForm,
    LinkApplicationsForm,
    SearchApplicationsForm,
    AlertApplicationsForm,
    Multiselect,
    'step-progress': StepProgress
  },
  created(){
    this.searchParameters = window.location.search.replace('?','&');

    axios.get(`/api/applications?page=${this.currentPage}${this.searchParameters}`).then(response => {
      this.applications = response.data.data;
      this.startIndex = response.data.from;
      this.endIndex = response.data.to;
      this.perPage = response.data.per_page;
      this.currentPage = response.data.current_page;
      this.total = response.data.total;
    });
     axios.get('/api/user').then( response => {
        this.userPerms = response.data.user_permissions.map(currentUserPerms => currentUserPerms.name);
        this.roleUserPerms = response.data.role_permissions.map(currentUserPerms => currentUserPerms.name);
        this.currentUserPerms = this.userPerms.concat(this.roleUserPerms);
    });
  },
  data() {
    return {
      title: "Suivi des demandes",
      items: [],
      button_label: "Nouvelle demande",
      applications: [],
      currentStep: 0,
      steps: [],
      showEdit: false,
      showLink: false,
      showAlert: false,
      applicationSelected: null,
      // pagination
      currentPage: 1,
      perPage: 10,
      startIndex: 1,
      endIndex: 1,
      total: null,
      searchParameters: null,
      currentUserPerms: [],
      userPerms:null,
      roleUserPerms:null,
    };
  },
  methods: {
    clearApplication(application){
      Swal.fire({
        title: "Êtes-vous sûr.e ?",
        text: "La demande sera réinitialisée. Tous les éléments liés à la demande seront supprimés.",
        showCancelButton: true,
        confirmButtonColor: "#003da5",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler"
      }).then(result => {
        if (result.value) {
          axios.get('/api/application/clear/' + application.id)
          .then(response => {
            axios.get('/api/applications?page=' + this.currentPage).then(response => {
              this.applications = response.data.data;
              this.startIndex = response.data.from;
              this.endIndex = response.data.to;
              this.perPage = response.data.per_page;
              this.currentPage = response.data.current_page;
              this.total = response.data.total;

              this.$toaster.success('La demande a été réinitialisée avec succès.');
            });
          })
          .catch(error => {
            if (parseInt(error.response.status)  == 409) {
              this.$toaster.error(error.response.data.message);
            } else {
              this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
            }
          });
        }
      });
    },
    activeAlert(alerts){
      if (alerts !== null || alerts != [])
        return alerts.some(element => moment(element.due_at).isBefore(moment().format()));
      return false;
    },
    search(parameters){
      this.searchParameters = parameters;
      this.currentPage = 1;
      this.onPageChange();
    },
    onPageChange() {
      var uri = '/api/applications?page=';
      axios.get(uri + this.currentPage, this.searchParameters).then(response => {
        this.applications = response.data.data;
        this.startIndex = response.data.from;
        this.endIndex = response.data.to;
        this.perPage = response.data.per_page;
        this.currentPage = response.data.current_page;
        this.total = response.data.total;
      });
    },
    setCurrentStep(application) {
      return (application.status !== 'complet') ? application.validation_step.order : application.validation_step.order + 1;
    },
    setSteps(application) {
      return application.validation_step.validation.validation_steps.map(step => step.name);
    },
    editApplication(application){
      if (application.status == "en attente") {
        this.showEdit = true;
        this.applicationSelected = application.id;
      }
      else {
        this.$toaster.info("Vous ne pouvez pas modifier une demande en cours.");
      }
    },
    linkApplications(application){
      this.showLink = true;
      this.applicationSelected = application.id;
    },
    alertApplications(application){
      this.showAlert = true;
      this.applicationSelected = application.id;
    },
    updateApplicationsList(){
      this.showEdit = false;
      this.showLink = false;
      this.showAlert = false;

      this.currentPage = 1;
      axios.get('/api/applications?page=' + this.currentPage).then(response => {
        this.applications = response.data.data;
        this.startIndex = response.data.from;
        this.endIndex = response.data.to;
        this.perPage = response.data.per_page;
        this.currentPage = response.data.current_page;
        this.total = response.data.total;
      });
    },
    deleteApplication(application) {
      Swal.fire({
        title: "Êtes-vous sûr.e ?",
        text: "La demande sera supprimée.",
        showCancelButton: true,
        confirmButtonColor: "#003da5",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler"
      }).then(result => {
        if (result.value) {
          axios.get('/api/application/delete/' + application.id)
          .then(response => {
            this.updateApplicationsList();
          })
          .catch(error => {
            if (parseInt(error.response.status) == 409) {
              this.$toaster.error(error.response.data.message);
            } else {
              this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
            }
          });
        }
      });
    },
    currentUserCan(namePermission){
      return this.currentUserPerms.includes(namePermission);
    },
  },
};
</script>

<template>
  <div>
    <PageHeader :title="title" :items="items" :button_label="button_label" @applicationAdded="updateApplicationsList"/>
    <div class="row">
      <div class="col-lg-12">
        <SearchApplicationsForm @searchApplications="search" />
        <div class>
          <div class="table-responsive">
            <table
              class="table project-list-table table-nowrap table-centered table-borderless align-middle"
            >
              <thead>
                <tr>
                  <th scope="col" style="width: 5%;">#</th>
                  <th scope="col" style="width: 20%;">Objet</th>
                  <th scope="col" style="text-align: center; width: 10%;">Type</th>
                  <th scope="col" style="text-align: center; width: 5%;">Statut</th>
                  <th scope="col" style="text-align: center; width: 10%;">Décision</th>
                  <th scope="col" style="text-align: center; width: 35%;">Étapes</th>
                  <th scope="col" style="text-align: center; width: 10%;">Date</th>
                  <th scope="col" style="text-align: center; width: 5%;">Actions</th>
                  <!-- <th scope="col">Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="application in applications" :key="application.id" 
                  :style="{ 'background-color': activeAlert(application.alerts) ? '#ffdada': '' }">
                  <td style="font-weight:800;">
                    {{ application.metadata.numero }}
                  </td>
                  <td>
                    <h5 class="text-truncate font-size-14">
                      <router-link :to="`application/edit/${application.id}`" class="text-dark">
                        {{ application.metadata.objet }}
                      </router-link>
                    </h5>
                    <p class="text-muted mb-0">
                      <span style="font-size: 0.75rem;">
                        <i class="fas fa-link" style="padding-right: 0.25rem;" v-if="application.links.length > 0"></i>
                        <i class="fas fa-bell" v-if="application.alerts.length > 0"></i>
                      </span>
                    </p>
                  </td>
                  
                  <td style="text-align: center; font-weight: 600;">{{ application.type }}</td>
                  <td style="text-align: center; font-weight: 600;">
                      <div 
                        :class="{
                          'badge bg-info': application.status === 'en attente',
                          'badge bg-warning': application.status === 'en cours',
                          'badge bg-danger': application.status === 'en cours de validation',
                          'badge bg-dark': application.status === 'complet',
                        }" style="text-transform: uppercase;">{{ application.status }}
                      </div>
                  </td>
                  <td style="text-align: center; font-weight: 600;">{{ application.decision }}</td>
                  <td>
                    <div>
                      <step-progress 
                        :current-step="setCurrentStep(application)"
                        :steps="setSteps(application)"
                        icon-class="fa fa-check" 
                        active-color="#003da5"></step-progress>
                    </div>
                  </td>
                  <td style="text-align: center;">{{ application.created_at | moment("DD MMM YYYY") }}</td>
                  <td style="text-align: center;">
                    <b-dropdown
                      toggle-class="nav-btn"
                      menu-class="dropdown-menu-end"
                      right
                      variant="white">
                      <template v-slot:button-content>
                          <i class="fas fa-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item @click="editApplication(application)"><i class="fas fa-edit"></i> Modifier</b-dropdown-item>
                      <b-dropdown-item @click="linkApplications(application)"><i class="fas fa-link"></i> Lier</b-dropdown-item>
                      <b-dropdown-item @click="alertApplications(application)"><i class="fas fa-bell"></i> Alertes</b-dropdown-item>
                      <b-dropdown-item @click="clearApplication(application)"><i class="fas fa-sync-alt"></i> Réinitialiser</b-dropdown-item>
                      <b-dropdown-item 
                        @click="deleteApplication(application)" 
                        :disabled="!currentUserCan('application-delete')">
                        <i class="far fa-trash-alt"></i> Supprimer
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>   
      </div>
      <b-modal hide-footer size="lg" v-model="showEdit" title="Modifier une demande" centered>
        <ApplicationForm :id="applicationSelected" @applicationUpdated="updateApplicationsList" />
      </b-modal>
      <b-modal hide-footer size="lg" v-model="showLink" title="Lier une demande" centered>
        <LinkApplicationsForm :id="applicationSelected" @applicationUpdated="updateApplicationsList" />
      </b-modal>
      <b-modal hide-footer size="lg" v-model="showAlert" title="Alertes" centered>
        <AlertApplicationsForm :id="applicationSelected" @applicationUpdated="updateApplicationsList" />
      </b-modal>
    </div>

    <div class="row justify-content-md-between align-items-md-center" v-if="applications">
      <div class="col-xl-7">{{startIndex}} - {{endIndex}} of {{total}}</div>
      <div class="col-xl-5">
        <div class="text-md-end float-xl-end mt-2 pagination-rounded">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            @input="onPageChange()">
          </b-pagination>
        </div>
      </div>
    </div>


  </div>
</template>
