<script>
import axios from 'axios';
import Multiselect from "vue-multiselect";
/**
 * Page-header component
 */
export default {
  name:'childComponent',
  components: {
    Multiselect
  },
  created(){
    axios.get('/api/roles/list').then( response => {
      response.data.forEach(role => {
        this.roles.push(role);
      });
    });
    axios.get('/api/user').then( response => {
        this.userPerms = response.data.user_permissions.map(currentUserPerms => currentUserPerms.name);
        this.roleUserPerms = response.data.role_permissions.map(currentUserPerms => currentUserPerms.name);
        this.currentUserPerms = this.userPerms.concat(this.roleUserPerms);
      });
    
  },
  methods:{
    resetForm(){
      this.dgdi_id = "";
      this.lastname = "";
      this.firstname = "";
      this.email = "";
      this.password = "";
      this.password_confirmation = "";
      this.role = null;
    },
    addUser(){
      axios.post('/api/users/store', {matricule : this.dgdi_id, noms: this.lastname, prénoms:this.firstname, email:this.email, mot_de_passe:this.password, confirmation_de_mot_de_passe:this.password_confirmation, role: this.role} )
      .then( response => {
           if(response.status == 200){
            this.$toaster.success(response.data.message);
            this.showModal = false;
            this.full_name = this.firstname+' '+this.lastname;
            this.$emit('userAdded',this.full_name, this.dgdi_id, this.lastname, this.firstname, this.role);
            this.showModal = false;
            this.resetForm();
          }
        }
      ).catch((error) => { 
       this.$toaster.error(error.response.data.message);
       this.fields = error.response.data.reason;
      })
      
    },
    haveError(label){
      if(this.fields.hasOwnProperty(label)){
       return true;
      }
    },
    showError(label){
       return this.fields[label][0];
    },
    test(show){
      if(show){
        return true
      }
      this.fields = [];
      this.resetForm();
      return false;

    },
    currentUserCan(namePermission){
      return this.currentUserPerms.includes(namePermission);
    }
  },
  data() {
    return {
      message_from_api:null,
      roles:[],
      dgdi_id:null,
      lastname:null,
      firstname:null,
      email:null,
      password:null,
      password_confirmation:null,
      role:null,
      showModal: false,
      full_name:null,
      value: null,
      value1: null,
      fields: [],
      reason:null,
      currentUserPerms: [],
      userPerms:null,
      roleUserPerms:null,
      selectRolePlaceholder: "Choisissez un rôle",

    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    button_label: {
      type: String,
      default: 'Add',
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">{{ title }}</h4>
        <div class="page-title-right">
          <button :disabled="!currentUserCan('user-create')"  class="btn btn-success ms-auto mb-2" @click="showModal = test(true)">{{ button_label }}</button>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal" size="lg" title="Nouvel utilisateur" centered >
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-form>
              <b-form-group
                class="mb-4"
                label="Matricule *"
                label-for="horizontal-email-input"
                label-cols-sm="3"
              >
                <b-form-input
                  id="horizontal-register-id-input"
                  type="text"
                  name ="matricule"
                  v-model="dgdi_id"
                ></b-form-input>
                <div v-if="haveError('matricule')">
                <p class="error-message">{{showError('matricule')}}</p>
              </div>
              </b-form-group>
              <b-form-group
                class="mb-3"
                label="Noms *"
                label-for="horizontal-lastname-input"
                label-cols-sm="3"
              >
                <b-form-input
                  id="horizontal-lastname-input"
                  name = "noms"
                  v-model="lastname"
                  type="text"
                  required
                ></b-form-input>
                <div v-if="haveError('noms')">
                <p class="error-message" >{{showError('noms')}}</p>
              </div>
              </b-form-group>
              <b-form-group
                class="mb-3"
                label="Prénoms *"
                label-for="horizontal-firstname-input"
                label-cols-sm="3"
              >
                <b-form-input
                  id="horizontal-firstname-input"
                  type="text"
                  name = "prénoms"
                  v-model="firstname"
                  required
                ></b-form-input>
                <div v-if="haveError('prénoms')">
                  <p class="error-message">{{showError('prénoms')}}</p>
                </div>
              </b-form-group>
              <b-form-group
                class="mb-4"
                label="Email *"
                label-for="horizontal-email-input"
                label-cols-sm="3">
                <b-form-input
                  id="horizontal-email-input"
                  name = "email"
                  v-model="email"
                  type="email">
                </b-form-input>
                <div v-if="haveError('email')">
                 <p class="error-message">{{showError('email')}}</p>
                </div>
              </b-form-group>
          
              <b-form-group
                class="mb-4"
                label="Mot de passe *"
                label-for="password"
                label-cols-sm="3"
              >
                <b-form-input
                  id="password"
                  type="password"
                  name = "mot_de_passe"
                  v-model= password
                ></b-form-input>
                <div v-if="haveError('mot_de_passe')">
                 <p class="error-message">{{showError('mot_de_passe')}}</p>
               </div>
              </b-form-group>
              <b-form-group
                class="mb-4"
                label="Entrer le mot de passe à nouveau *"
                label-for="password-again"
                label-cols-sm="3"
              >
                <b-form-input
                  id="password-again"
                  name = "confirmation_de_mot_de_passe"
                  v-model="password_confirmation"
                  type="password"
                ></b-form-input>
                <div v-if="haveError('confirmation_de_mot_de_passe')">
                  <p class="error-message">{{showError('confirmation_de_mot_de_passe')}}</p>
                </div>
              </b-form-group>
              <b-form-group
                class="mb-4"
                label="Rôle *"
                label-for="Rôle"
                label-cols-sm="3"
              >
                <multiselect 
                  :placeholder="selectRolePlaceholder"
                  :show-labels="false" 
                  class="col" 
                  label="name" 
                  v-model="role" 
                  :options="roles"
                  >
                </multiselect>
                <div v-if="haveError('role')">
                  <p class="error-message">{{showError('role')}}</p>
                </div>
              </b-form-group>
            </b-form>
          </div>
        </div>
      </div>
      <template  v-slot:modal-footer>
              <b-button variant="secondary" @click="showModal = test(false)">Fermer</b-button>
              <b-button variant="primary" @click.prevent="addUser">Enregistrer</b-button>
            </template>
    </b-modal>
  </div>
  <!-- end page title -->
</template>
<style scoped>
 .error-message {
   color: red;
 }
</style>