<script>
import Multiselect from "vue-multiselect";
const axios = require('axios');

/**
 * Page-header component
 */
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      name: null,
      description: null,
      showCreate: false,
      steps: [],
      submitted: false,
      errors: null,
      users:[],
      usersAttached:[],
      currentUserPerms: [],
      userPerms:null,
      roleUserPerms:null,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    button_label: {
      type: String,
      default: 'Add',
    },
  },
  created(){
    axios.get('/api/users/list',{
        params: {
              paginate: false,
          }}).then( response => {
      var usersByRole = [];

      response.data.forEach(item => {
        if (! usersByRole[item.roles[0].name]) {
          usersByRole[item.roles[0].name] = [item];
        } else {
          usersByRole[item.roles[0].name].push(item)
        }
      });

      Object.keys(usersByRole).forEach(key => {
        this.users.push({
          role: key,
          users: usersByRole[key]
        });
      });
    });
     axios.get('/api/user').then( response => {
        this.userPerms = response.data.user_permissions.map(currentUserPerms => currentUserPerms.name);
        this.roleUserPerms = response.data.role_permissions.map(currentUserPerms => currentUserPerms.name);
        this.currentUserPerms = this.userPerms.concat(this.roleUserPerms);
    });
  },

  methods: {
    closeForm() {
      this.resetFormFields();
      this.showCreate = false;
    },
    resetFormFields() {
      this.name = null;
      this.description = null;
      this.showCreate = false;
      this.steps = [];
      this.submitted = false;
      this.errors = null;
      this.usersAttached = [];
    },
    createValidation() {
      this.submitted = true;
      this.errors = {};

      if (this.steps.length < 2) {
        this.$toaster.error("Une validation doit comporter au minimum deux étapes !");
        console.log('Error');
        return;
      }
      axios.post('/api/validation/store', {
          name: this.name,
          description: this.description,
          steps: this.steps,
        })
        .then( response => {
          this.closeForm();
          this.updateValidationsList();
          this.$toaster.success(response.data.message);
        })
        .catch(error => {
          this.errors = error.response.data;
         
          this.$toaster.error(error.response.data.message);
        });
    },
    updateValidationsList() {
      this.$emit('updateValidations');
    },
    addStep() {
      this.steps.push({ name: "", delay: null, is_file_required: 0, selectedUsers: [], users: []});
    },
    removeStep(index) {
      if (confirm("Voulez-vous vraiment supprimer cette étape ?"))
        this.steps.splice(index, 1);
    },
    currentUserCan(namePermission){
      return this.currentUserPerms.includes(namePermission);
    }
  }
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">{{ title }}</h4>
        <div class="page-title-right">
          <button :disabled="!currentUserCan('validation-create')" class="btn btn-success ms-auto mb-2" @click="showCreate = true">{{ button_label }}</button>
        </div>
      </div>
    </div>
    <b-modal size="lg" v-model="showCreate" title="Nouveau workflow de validation" centered>
      <form>
        <div class="mb-3">
          <input  type="text"  v-model="name" class="form-control" placeholder="Nom du workflow" />
          <div v-if="submitted && errors !== null" style="color: red;">
            <span  v-if="errors.name">{{ errors.name[0] }}</span>
          </div>
        </div>
        <div class="mb-3">
          <input type="text"  v-model="description" class="form-control" placeholder="Description du worflow" />
          <div v-if="submitted && errors !== null" style="color: red;">
            <span v-if="errors.description">{{ errors.description[0] }}</span>
          </div>
        </div>
        <h6 style="margin-top: 30px;margin-bottom: 20px;">Étapes</h6>
        <div>
          <div v-for="(step, index) in steps" :key="step.id" class="row" style="margin-bottom: 20px;">
            <div class="mb-3">
              <input id="name" type="text" v-model="step.name" class="form-control" placeholder="Nom de l'étape" required/>
              <div v-if="submitted && errors !== null" style="color: red;">
                <span v-if="errors['steps.'+ index + '.name']">{{ errors['steps.'+ index + '.name'][0] }}</span>
              </div>
            </div>
            <div class="mb-3">
              <input id="delay" type="number" v-model="step.delay" class="form-control" placeholder="Délai" min="0" required/>
              <div v-if="submitted && errors !== null" style="color: red;">
                <span v-if="errors['steps.'+ index + '.delay']">{{ errors['steps.'+ index + '.delay'][0] }}</span>
              </div>
            </div>
            <div class="mb-3">
              <multiselect 
                :multiple="true"
                :taggable="true"
                :show-labels="false"
                track-by="id"
                :options="users"
                group-values="users"
                group-label="role"
                class="col"
                v-model="step.selectedUsers" 
                label="full_name"
                placeholder="Choix de l'utilisateur"
                required>
              </multiselect>
            </div>
          
            <div class="mb-3">
              <b-form-checkbox class="mb-3" v-model="step.is_file_required" value=1 unchecked-value=0 checked plain>
                Fichier requis
              </b-form-checkbox>
            </div>
            <div class="col-lg-2 align-self-center">
                <div class="d-grid">
                  <input type="button" class="btn btn-primary btn-block" value="Supprimer" @click="removeStep(index)"/>
                </div>
            </div>
          </div>
        </div>
          <input
            type="button"
            class="btn btn-success mt-3 mt-lg-0"
            value="Ajouter"
            @click="addStep"
          />
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="closeForm()">Fermer</b-button>
        <b-button variant="primary" @click="createValidation()">Enregistrer</b-button>
      </template>
    </b-modal>
  </div>
  <!-- end page title -->
</template>
