<script>
export default {
  name:'application-types-table',
  props: {
    applicationTypesData: {
			type: Object,
			def: true
		}
  },
  computed: {
    applicationTypes: {
      get() { return this.applicationTypesData.data; },
    },
    currentPage: {
      get() { return this.applicationTypesData.current_page; },
      set(value) { return value },
    },
    perPage: {
      get() { return this.applicationTypesData.per_page; },
    },
    startIndex: {
      get() { return this.applicationTypesData.from; },
    },
    endIndex: {
      get() { return this.applicationTypesData.to; },
    },
    total: {
      get() { return this.applicationTypesData.total; },
    }
  },
  methods : {
    onPageClick(event, page){
      this.$emit('onPageClick', page);
    },

		displayForm(applicationType) {
			this.$emit('displayForm', applicationType)
		}
  },
};
</script>
<template>
	<div class="row">
		<div class="col-lg-12">
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table class="table table-centered table-nowrap table-hover align-middle">
							<thead class="table-light">
								<tr>
									<th scope="col" class="width-20">Code</th>
									<th scope="col" class="width-70">Nom</th>
									<th scope="col" class="width-10 table-text-center">Couleur</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="applicationType in applicationTypes" :key="applicationType.id"
									v-on:click.prevent="displayForm(applicationType)">
									<td class="width-20 table-bolder-text">{{ applicationType.code }}</td>
									<td class="width-70">{{ applicationType.name }}</td>
									<td class="width-10 table-text-center">
										<span class="acl-dot" :style="{ 'background-color': applicationType.color }"></span></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="row justify-content-md-between align-items-md-center" v-if="applicationTypes">
						<div class="col-xl-7">{{ startIndex }} - {{ endIndex }} sur {{ total }}</div>
						<div class="col-xl-5">
							<div class="text-md-end float-xl-end mt-2 pagination-rounded">
								<b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"
									@page-click="onPageClick"></b-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	tr {
		cursor: pointer;
	}
  .table-bolder-text {
    font-weight: 500;
  }

	.acl-dot {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    display: inline-block;
  }

	.width-10 {
		width: 10%;
	}

	.width-20 {
		width: 20%;
	}

	.width-70 {
		width: 70%;
	}

  .table-text-center{
    text-align: center;
	}
</style>