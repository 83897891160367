/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require("./bootstrap");
import Vue from "vue";
window.Vue = Vue;

import { BootstrapVue } from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import VueRouter from "vue-router"
import VueCompositionAPI from '@vue/composition-api'
import {createPinia,PiniaVuePlugin} from 'pinia'
import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import 'vue2-datepicker/locale/fr';

import i18n from "./i18n";

const moment = require('moment')
require('moment/locale/fr')

Vue.prototype.$isDev = process.env.MIX_APP_ENV !== "production";
Vue.config.devtools = Vue.prototype.$isDev;
Vue.config.debug = Vue.prototype.$isDev;
Vue.config.silent = !Vue.prototype.$isDev;
import tinymce from "vue-tinymce-editor";


Vue.use(VueRouter)
Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
        libraries: "places"
    },
    installComponents: true
});
Vue.use(require('vue-moment'), {
    moment
});



Vue.component("apexchart", VueApexCharts);
Vue.component("tinymce", tinymce);
Vue.component('vue-bootstrap-autocomplete', VueBootstrapAutocomplete)
Vue.component(
    "dynamic-component",
    require("./components/dynamic-component").default
);
Vue.component("date-picker", DatePicker);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import "./views";
import Layouts from "./mixins/layouts.mixin";
import router from "./Router";

import Toaster from 'v-toaster'
 
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'v-toaster/dist/v-toaster.css'
 
// optional set default imeout, the default is 10000 (10 seconds).
Vue.use(Toaster, {timeout: 5000})

import LaravelPermissions from 'laravel-permissions';
import {findAncestorWithAttribute, findAttribute, hasAncestorWithAttribute} from "./utils";

Vue.use(LaravelPermissions);
Vue.use(VueCompositionAPI)
Vue.use(PiniaVuePlugin)

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            let except = hasAncestorWithAttribute(event.target, 'click-outside-except');

            // here I check that click was outside the el and his children
            if (!(el === event.target || el.contains(event.target))) {
                if(except) {
                    return;
                }
                if(el.hasClickInside) {
                    if(typeof vnode.context[binding.expression] === 'function'){
                        vnode.context[binding.expression](event);
                    }

                }
                el.hasClickInside = false;
            } else {
                el.hasClickInside = true;
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
        el.hasClickInside = false;
    },
});

const pinia = createPinia()

const app = new Vue({
    el: "#app",
    pinia,
    router,
    mixins: [Layouts],
    i18n
});
