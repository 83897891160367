export const elementsTree = [
    {
        "id": 1,
        "name": "Root",
        "parent_id": null,
        "element_type": "folder",
        "mime_type": null,
        "size": null,
        "owner_id": 1,
        "created_at": "2023-06-19 02:44:08",
        "updated_at": "2023-06-19 02:44:08",
        "children": [
            {
                "id": 2,
                "name": "Folder 1",
                "parent_id": 1,
                "element_type": "folder",
                "mime_type": null,
                "size": null,
                "owner_id": 1,
                "created_at": "2023-06-19 02:44:08",
                "updated_at": "2023-06-19 02:44:08",
                "children": [
                    {
                        "id": 5,
                        "name": "File 1",
                        "parent_id": 2,
                        "element_type": "file",
                        "mime_type": "application/pdf",
                        "size": 1280943,
                        "owner_id": 1,
                        "created_at": "2023-06-19 02:44:08",
                        "updated_at": "2023-06-19 02:44:08"
                    },
                    {
                        "id": 6,
                        "name": "File 2",
                        "parent_id": 2,
                        "element_type": "file",
                        "mime_type": "image/jpeg",
                        "size": 102345,
                        "owner_id": 1,
                        "created_at": "2023-06-19 02:44:08",
                        "updated_at": "2023-06-19 02:44:08"
                    },
                    {
                        "id": 7,
                        "name": "Folder 2",
                        "parent_id": 2,
                        "element_type": "folder",
                        "mime_type": null,
                        "size": null,
                        "owner_id": 1,
                        "created_at": "2023-06-19 02:44:08",
                        "updated_at": "2023-06-19 02:44:08",
                        "children": [
                            {
                                "id": 8,
                                "name": "File 3",
                                "parent_id": 7,
                                "element_type": "file",
                                "mime_type": "text/plain",
                                "size": 5321,
                                "owner_id": 1,
                                "created_at": "2023-06-19 02:44:08",
                                "updated_at": "2023-06-19 02:44:08"
                            },
                            {
                                "id": 9,
                                "name": "File 4",
                                "parent_id": 7,
                                "element_type": "file",
                                "mime_type": "application/msword",
                                "size": 20480,
                                "owner_id": 1,
                                "created_at": "2023-06-19 02:44:08",
                                "updated_at": "2023-06-19 02:44:08"
                            }
                        ]
                    }
                ]
            },
            {
                "id": 3,
                "name": "Folder 3",
                "parent_id": 1,
                "element_type": "folder",
                "mime_type": null,
                "size": null,
                "owner_id": 1,
                "created_at": "2023-06-19 02:44:08",
                "updated_at": "2023-06-19 02:44:08",
                "children": [
                    {
                        "id": 10,
                        "name": "File 5",
                        "parent_id": 3,
                        "element_type": "file",
                        "mime_type": "image/png",
                        "size": 512345,
                        "owner_id": 1,
                        "created_at": "2023-06-19 02:44:08",
                        "updated_at": "2023-06-19 02:44:08"
                    }
                ]
            },
            {
                "id": 4,
                "name": "File 6",
                "parent_id": 1,
                "element_type": "file",
                "mime_type": "application/pdf",
                "size": 1029384,
                "owner_id": 1,
                "created_at": "2023-06-19 02:44:08",
                "updated_at": "2023-06-19 02:44:08"
            }
        ]
    },
    {
        "id": 14,
        "name": "File 16",
        "parent_id": null,
        "element_type": "file",
        "mime_type": "application/pdf",
        "size": 1029384,
        "owner_id": 1,
        "created_at": "2023-06-19 02:44:08",
        "updated_at": "2023-06-19 02:44:08"
    },
    {
        "id": 12,
        "name": "Folder 32D",
        "parent_id": null,
        "element_type": "folder",
        "mime_type": null,
        "size": null,
        "owner_id": 1,
        "created_at": "2023-06-19 02:44:08",
        "updated_at": "2023-06-19 02:44:08",
        "children": [
            {
                "id": 15,
                "name": "File 15",
                "parent_id": 12,
                "element_type": "file",
                "mime_type": "application/pdf",
                "size": 1280943,
                "owner_id": 1,
                "created_at": "2023-06-19 02:44:08",
                "updated_at": "2023-06-19 02:44:08"
            },
            {
                "id": 16,
                "name": "File 16",
                "parent_id": 12,
                "element_type": "file",
                "mime_type": "image/jpeg",
                "size": 102345,
                "owner_id": 1,
                "created_at": "2023-06-19 02:44:08",
                "updated_at": "2023-06-19 02:44:08"
            },
            {
                "id": 17,
                "name": "Folder 17",
                "parent_id": 12,
                "element_type": "folder",
                "mime_type": null,
                "size": null,
                "owner_id": 1,
                "created_at": "2023-06-19 02:44:08",
                "updated_at": "2023-06-19 02:44:08",
                "children": [
                    {
                        "id": 18,
                        "name": "File 18",
                        "parent_id": 17,
                        "element_type": "file",
                        "mime_type": "text/plain",
                        "size": 5321,
                        "owner_id": 1,
                        "created_at": "2023-06-19 02:44:08",
                        "updated_at": "2023-06-19 02:44:08"
                    },
                    {
                        "id": 19,
                        "name": "File 42",
                        "parent_id": 17,
                        "element_type": "file",
                        "mime_type": "application/msword",
                        "size": 20480,
                        "owner_id": 1,
                        "created_at": "2023-06-19 02:44:08",
                        "updated_at": "2023-06-19 02:44:08"
                    }
                ]
            }
        ]
    }
]