const documentTypesTemplates = [
    {
        "id": "AJ",
        "name": "Avis Juridique",
        "color": "#ADD8E6",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "FT",
        "name": "Fiche Technique",
        "color": "#00008B",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "select",
                "placeholder": "Sélectionnez un objet",
                "options": [
                    "Demande d’établissement de passeports",
                    "Demande de renouvellement de passeports",
                    "Demande d’établissement de carte de séjour",
                    "Demande de renouvellement de carte de séjour",
                    "Demande d’établissement de CNI",
                    "Demande de renouvellement de CNI"
                ],
                "required": true
            },
            {
                "order": 3,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "DN",
        "name": "Dénonciation",
        "color": "#DC143C",
        "fields": [{
            "order": 1,
            "name": "date",
            "type": "date",
            "placeholder": "Date",
            "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "interesse",
                "type": "text",
                "placeholder": "Nom de l'intéressé",
                "required": true
            },
            {
                "order": 4,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 5,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "NINT",
        "name": "Note interne",
        "color": "#8B0000",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "NAD",
        "name": "Note administrative",
        "color": "#FF1493",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "LAD",
        "name": "Lettre administrative",
        "color": "#FF4500",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "PD/PA",
        "name": "Projet de Décret/Arrêté",
        "color": "#FFA500",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "administration émettrice",
                "type": "text",
                "placeholder": "Administration émettrice",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "DS",
        "name": "Décision",
        "color": "#E6E6FA",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "CPS",
        "name": "Contrat de Prestation",
        "color": "#EE82EE",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "nom du prestataire",
                "type": "text",
                "placeholder": "Nom du Prestataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "PAA/RC",
        "name": "Protocole d'Accord Amiable/Résiliation de Contrat",
        "color": "#BA55D3",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "nom du prestataire",
                "type": "text",
                "placeholder": "Nom du Prestataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "CW",
        "name": "Contrat de Travail",
        "color": "#8A2BE2",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "nom de l'agent",
                "type": "text",
                "placeholder": "Nom de l'agent",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "AC",
        "name": "Analyse de Contrat",
        "color": "#4B0082",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "NOTIF",
        "name": "Notifications",
        "color": "#32CD32",
        "fields": [

            {
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "select",
                "placeholder": "Sélectionnez un objet",
                "options": [
                    "Sanction",
                    "Licenciement"
                ],
                "required": true
            },
            {
                "order": 3,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "CR",
        "name": "Compte-Rendu",
        "color": "#2E8B57",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "select",
                "placeholder": "Sélectionnez un objet",
                "options": [
                    "Mission",
                    "Réunion"
                ],
                "required": true
            },
            {
                "order": 3,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "PV",
        "name": "Procès verbal",
        "color": "#6B8E23",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "PLT",
        "name": "Plainte",
        "color": "#00CED1",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "destinataire",
                "type": "text",
                "placeholder": "Destinataire",
                "required": true
            },
            {
                "order": 4,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "TDL",
        "name": "Texte de loi",
        "color": "#FFE4C4",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "DJ",
        "name": "Décision de Justice",
        "color": "#8B4513",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    },
    {
        "id": "AUTRE",
        "name": "Autre",
        "color": "#71645A",
        "fields": [{
                "order": 1,
                "name": "date",
                "type": "date",
                "placeholder": "Date",
                "required": true
            },
            {
                "order": 2,
                "name": "objet",
                "type": "text",
                "placeholder": "Objet",
                "required": true
            },
            {
                "order": 3,
                "name": "nom",
                "type": "text",
                "placeholder": "Nom du dossier",
                "required": true
            }
        ]
    }
];

export { documentTypesTemplates };