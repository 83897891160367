<script>
import {formatBytes} from "../../utils";
import ManageAccess from "./components/manage-access.vue";
import ManageVersions from "./components/manage-versions.vue";
import ManageTags from "./components/manage-tags.vue";
const axios = require('axios');

/**
 * Details section component
 */
export default {
  components: {ManageTags, ManageVersions, ManageAccess},
  props: {
    details: {
      type: Object,
      required: false,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      data: null,
      loading: false,
    }
  },
  created() {
    if(this.details !== null) {
      this.fetch()
    }
  },
  watch: {
    details(newValue, oldValue) {
      if(newValue !== null && newValue !== oldValue) {
        this.fetch()
      }
    }
  },
  computed: {
    hasDetails() {
      return this.details !== null;
    },
    hasPermittedUsers() {
      return this.hasDetails && this.data.access.permittedUsers.length > 0
    },
    hasPermittedDepartments() {
      return this.hasDetails && this.data.access.permittedDepartments.length > 0
    }
  },
  methods: {
    formatBytes,
    fetch() {
      this.loading = true

      axios.get(`/api/filesmanager/details/${this.details.id}`)
        .then(response => {
          console.log('response data', response.data.access)
          this.data = response.data
        })
        .catch(error => {
          console.log(error)
          alert('Erreur innatendue');
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<template>
  <div class="card" ref="self" click-outside-except>
    <div class="card-body">
      <div class="d-flex flex-column min-vh-75">
        <div class="row mb-3">
          <div class="mt-2">
            <h5>Détails</h5>
            <p v-if="!hasDetails" class="p-2 text-center">Sélectionner un élement pour en afficher les détails</p>
            <div v-if="loading" class="p-2 text-center">
              <b-spinner></b-spinner>
            </div>
            <div v-if="hasDetails && !loading">
              <strong>Nom:</strong>
              <p>{{details.name}}</p>
              <strong>Type:</strong>
              <p>{{details.element_type === 'folder' ? "Dossier" : "Fichier"}}</p>
              <div v-if="details.element_type === 'file'">
                <strong>Taille:</strong>
                <p>{{formatBytes(details.size)}}</p>
              </div>
              <hr>
              <h6 class="font-size-16 d-flex flex-row justify-content-between align-items-center">
                <span>Qui a accès</span>
                <ManageAccess :access="data.access" :element="details" @updated="fetch" v-if="data.access.owner.isCurrentUser || data.access.is_current_user_admin" />
              </h6>
              <span>Propriétaire:</span>
              <p><strong>{{ data.access.owner.firstname }} {{ data.access.owner.firstname }}</strong></p>
              <div v-if="hasPermittedDepartments" class="mb-2">
                <span>Départements:</span>
                <div v-for="department in data.access.permittedDepartments" :key="department.id">
                  <p class="mb-1"><strong>{{ department.name }}</strong></p>
                </div>
              </div>
              <div v-if="hasPermittedUsers">
                <span>Autres:</span>
                <div v-for="user in data.access.permittedUsers" :key="user.id">
                  <p class="mb-1"><strong>{{ user.full_name }} <span v-if="user.isCurrentUser">(vous)</span></strong></p>
                </div>
              </div>

              <div v-if="details.element_type === 'file' && details.latest_version">
                <hr>
                <h6 class="font-size-16 d-flex flex-row justify-content-between align-items-center">
                  <span>Versions</span>
                  <ManageVersions :element="details" :access="data.access" />
                </h6>
                <span>Dernière modification:</span>
                <p>{{ details.latest_version.updated_at | moment("DD MMM YYYY à HH:mm:ss") }}</p>
              </div>
              <hr>
              <ManageTags v-if="details.element_type === 'file'" :element="details" :access="data.access" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>
