const routes = [
    {
        path: '',
        component: () => import('../views/dashboard/index.vue'),
        name: 'Home'
    },
    {
        path: '/home',
        redirect: '/'
    },
    {
        path: '/applications',
        component: () => import('../views/applications/list.vue'),
        name: 'Applications'
    },
    {
        path: '/application/edit/:id',
        component: () => import('../views/applications/edit.vue'),
        name: 'Application-Edit'
    },
    {
        path: '/application-types',
        component: () => import('../views/application-types/index.vue'),
        name: 'Application-Types'
    },
    {
        path: '/filesmanager',
        component: () => import('../views/filesmanager/index.vue'),
        name: 'FilesManager'
    },
    {
        path: '/messages',
        component: () => import('../views/message/inbox.vue'),
        children: [
            {
              path: '',
              component: () => import('../views/message/component/list.vue'),
            },
            {
                path: ':section',
                component: () => import('../views/message/component/list.vue'),
            },
            {
              path: 'view/:id',
              component: () => import('../views/message/component/view.vue'),
            },
          ],
        name: 'Messages'
    },
    {
        path: '/users',
        component: () => import('../views/contacts/list.vue'),
        name: 'Users'
    },
    {
        path: '/user/view/:id',
        component: () => import('../views/contacts/update.vue'),
        name: 'User'
    },
    {
        path: '/me',
        component: () => import('../views/contacts/update.vue'),
        name: 'Me'
    },
    {
        path: '/roles',
        component: () => import('../views/roles/list.vue'),
        name: 'Roles'
    },
    {
        path: '/edit/role/:id',
        component: () => import('../views/roles/update.vue'),
        name: 'Edit-role'
    },
    {
        path: '/role-create',
        component: () => import('../views/roles/create.vue'),
        name: 'Create-role'
    },
    {
        path: '/validations',
        component: () => import('../views/validations/list.vue'),
        name: 'Validations'
    },
    {
        path: '/logs',
        component: () => import('../views/logs/index.vue'),
        name: 'Logs'
    },
    {
        path: '/departments',
        component: () => import('../views/departments/index.vue'),
        name: 'Departments'
    },
    {
        path: '/department/view/:id',
        component: () => import('../views/departments/update.vue'),
        name: 'Department'
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('../views/utility/404.vue'),
        name: 'Not-Found'
    },
]

export default routes
