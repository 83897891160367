<script >
export default {
  props: {
    mime: {
      type: String,
      required: true
    }
  },
  computed: {
    isImage() {
      return this.mime.startsWith('image')
    },
    isPdf() {
      return this.mime === 'application/pdf'
    },
    isVideo() {
      return this.mime.startsWith('video')
    },
    isAudio() {
      return this.mime.startsWith('audio')
    },
    isText() {
      return this.mime.startsWith('text')
    },
    isMsWord() {
      return this.mime === 'application/msword' || this.mime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    },
    isSpreadsheet() {
      return this.mime === 'application/vnd.ms-excel'
          || this.mime === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          || this.mime === 'application/msexcel'
    },
    isPowerpoint() {
      return this.mime === 'application/vnd.ms-powerpoint'
          || this.mime === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    },
    isUnknown() {
      return !this.isImage
          && !this.isPdf
          && !this.isVideo
          && !this.isAudio
          && !this.isText
          && !this.isMsWord
          && !this.isSpreadsheet
          && !this.isPowerpoint
    },
    icon() {
      if (this.isImage) {
        return 'fa-file-image'
      } else if (this.isPdf) {
        return 'fa-file-pdf'
      } else if (this.isVideo) {
        return 'fa-file-video'
      } else if (this.isAudio) {
        return 'fa-file-audio'
      } else if (this.isText) {
        return 'fa-file-alt'
      } else if (this.isMsWord) {
        return 'fa-file-word'
      } else if (this.isSpreadsheet) {
        return 'fa-file-excel'
      } else if (this.isPowerpoint) {
        return 'fa-file-powerpoint'
      } else if (this.isUnknown) {
        return 'fa-file'
      }
    }
  }
}
</script>

<template>
  <div class="mime-icon text-primary">
    <i class="far" v-bind:class="icon"></i>
  </div>
</template>

<style scoped>
.mime-icon {
  font-size: 20px;
}
</style>