<script>
import axios from "axios";
import careerItemForm from "./career-item-form.vue";

export default {
    components: {
      careerItemForm
    },
    props: {
        careerItems: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedCareerItem: null,
            showCareerItemForm: false
        };
    },
    created() {
    },
    methods: {
      editCareerItem(item){
        this.selectedCareerItem = item;
        this.displayCareerItemForm();
      },
      displayCareerItemForm(){
            this.showCareerItemForm = true;
        },
      hideCareerItemForm(){
          this.showCareerItemForm = false;
      }
    },
};
</script>
<template>
    <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover align-middle">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Début</th>
                    <th scope="col">Fin</th>
                    <th scope="col">Titre</th>
                    <th scope="col">Département</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in careerItems" :key="item.id" class="item-row">
                    <td> {{item.start_dt}}</td>
                    <td> {{item.end_dt}}</td>
                    <td> {{item.job_title}}</td>
                    <td> {{item.department.name}}</td>
                    <td>
                        <a class="edit-button" @click="editCareerItem(item)"><i class="fas fa-edit"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <careerItemForm v-if="showCareerItemForm" :selectedCareerItem="selectedCareerItem" :showForm="showCareerItemForm" @hideForm="hideCareerItemForm"/>
        </div>
</template>

<style lang="scss" scoped>


.edit-button {
            color: inherit;
            cursor: pointer;
        }
</style>