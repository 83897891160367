<script>
import MimeIcon from "./mime-icon.vue";
import CircularProgressBar from "./circular-progress-bar.vue";

export default {
  components: {CircularProgressBar, MimeIcon},
  props: {
    item: {
      type: Object,
      required: true
    },
    onStopClick: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      mouseEntered: false
    }
  }
}
</script>

<template>
  <li
      class="px-3 py-2 d-flex align-items-center flex-row justify-content-between"
      @mouseenter="mouseEntered = true"
      @mouseleave="mouseEntered = false"
  >
    <MimeIcon :mime="item.mime" class="me-3" />
    <p class="m-0 flex-fill text-truncate">{{item.filename}}</p>
    <div style="max-width: 20px" v-if="!mouseEntered">
      <CircularProgressBar :percentage="item.uploadProgress" v-if="item.status !== 'complete'" />
      <span v-if="item.status === 'complete'" class="font-size-20 text-success"><i class="mdi mdi-check-circle"></i></span>
    </div>
    <a v-if="mouseEntered" role="button" @click="onStopClick" class="font-size-20 text-info"><i class="fas fa-stop-circle"></i></a>
  </li>
</template>

<style scoped>

</style>