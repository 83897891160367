<script>
const axios = require('axios');

/**
 * Manage versions component
 */
export default {
  props: {
    element: {
      type: Object,
      required: true
    },
    access: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      versions: [],
      hasWriteAccess: false,
      loading: true,
    }
  },
  computed: {
    hasVersions() {
      return this.versions.length > 0
    }
  },
  methods: {
    openModal() {
      this.$bvModal.show('manage-versions')
      this.fetch()
    },
    fetch() {
      this.loading = true;

      axios.get(`/api/filesmanager/versions/${this.element.id}`)
          .then(response => {
            console.log(response.data)
            this.versions = response.data.items;
            this.hasWriteAccess = response.data.has_write_access;
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
            alert('Erreur innatendue');
          }).finally(() => {
            this.loading = false;
          })
    },
    openWindow(version) {
      window.open(`/api/filesmanager/versions/${version.id}/download`, '_blank');
    },
    deleteVersion(version) {
      const response = confirm('Voulez-vous vraiment supprimer cette version ?')

      if(!response) {
        return;
      }
      axios.delete(`/api/filesmanager/versions/${version.id}/delete`)
        .then(response => {
          this.fetch()
        })
        .catch(error => {
          console.log(error)
          alert('Erreur innatendue');
        })
    }
  }
}
</script>

<template>
  <div>
    <b-button
        variant="primary"
        size="sm"
        class="font-size-12"
        @click="openModal"
      >
      Gérer les versions
    </b-button>
    <b-modal id="manage-versions" click-outside-except hide-footer hide-header>
      <h5>Gérer les versions</h5>
      <p>Nom du fichier: {{ element.name }}</p>
      <hr>
      <div v-if="loading" class="d-flex min-h-100p justify-content-center align-items-center">
        <b-spinner type="grow" variant="primary" v-if="loading"/>
      </div>
      <div v-if="hasVersions">
        <div class="version-item" v-for="(version, index) in versions" :key="version.id">
          <div class="d-flex flex-row justify-content-between align-items-start">
            <div>
              <p class="mb-0">
                <i class="mdi mdi-clock-outline"></i>
                {{ version.created_at | moment("DD MMM YYYY à HH:mm:ss") }}
                <strong v-if="index === 0">version actuelle</strong></p>
              <p class="mb-0"><i class="mdi mdi-account"></i> {{ version.owner.full_name }}</p>
            </div>
            <div>
              <b-dropdown
                  toggle-class="font-size-16 text-muted p-0"
                  class="mb-2"
                  variant="white"
                  menu-class="dropdown-menu-end"
                  right
              >
                <template #button-content>
                  <i class="mdi mdi-dots-horizontal"></i>
                </template>

                <b-dropdown-item @click="() => openWindow(version)" >Télécharger</b-dropdown-item>
                <b-dropdown-item v-if="hasWriteAccess" @click="() => deleteVersion(version)">Supprimer</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div>
        <button class="btn btn-secondary" @click="() => $bvModal.hide('manage-versions')">Fermer</button>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
  .version-item {
    border-bottom: 1px solid #eaeaea;
    margin: 8px 16px;
    padding: 8px 0;
  }
</style>
