<script>
import axios from 'axios';
import Toaster from 'v-toaster';
import Multiselect from "vue-multiselect";
import { documentTypesTemplates } from "../templates.js";
import Swal from "sweetalert2";

/**
 * Page-header component
 */
export default {
  components: { Multiselect },
  data() {
    return {
      templates: null,
      template: null,
      templateFields: null,
      showModal: false,
      showTemplateFields: false,
      metadata: {},
      file: null,
      currentFile: null,
      deletedFileId: null,
      workflows: [],
      workflow: null,
      errors: null,
      submitted: false,
      currentUserPerms: [],
      userPerms:null,
      roleUserPerms:null,
    };
  },
  created(){
    this.templates = documentTypesTemplates.sort((a, b) => a.name > b.name ? 1 : -1);

    axios.get('/api/application/edit/meta/' + this.id)
    .then(response => {
      this.template = this.templates.filter(element => {
          return element.name === response.data.application.type
        })[0];
      this.metadata = response.data.application.metadata;
      this.workflow = response.data.application.validation_step.validation;
      this.workflows = response.data.workflows;
      this.showTemplateFields = true;
      this.currentFile = response.data.application.document;
    })
    .catch( error => {
      this.$toaster.error(error.response.data);
    });
     axios.get('/api/user').then( response => {
        this.userPerms = response.data.user_permissions.map(currentUserPerms => currentUserPerms.name);
        this.roleUserPerms = response.data.role_permissions.map(currentUserPerms => currentUserPerms.name);
        this.currentUserPerms = this.userPerms.concat(this.roleUserPerms);
    });
  },
  methods:{
    resetFormFields() {
        this.template = null;
        this.templateFields = null;
        this.showTemplateFields = false;
        this.metadata = {};
    },
    checkFormFields() {
      var result = true;

      if (this.file === null && this.deletedFileId !== null ) {
          this.$toaster.warning('Vous devez télécharger au moins un fichier.');
          result = false;
        }

        if (this.workflow === null ) {
          this.$toaster.warning('Vous devez sélectionner un workflow de validation.');
          result = false;
        }
        return result;
    },
    save(){
        if (!this.checkFormFields()) {
          return;
        }

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        };

        var data = new FormData();
        
        data.append('metadata', JSON.stringify(this.metadata));
        data.append('workflow', this.workflow.id);
        data.append('type', this.template.name);
        
        if (this.file !== null) {
          data.append('file', this.file, this.file.name);

          if (this.currentFile !== null) {
            data.append('file_to_delete', this.currentFile.id);
          }
        }

        if (this.deletedFileId !== null) {
          this.$toaster.warning('Le nouveau document écrasera le précédent.');
          data.append('file_to_delete', this.deletedFileId);
        }

        this.submitted = true;
        axios.post('/api/application/update/meta/' + this.id, data, config)
        .then(response => {
          this.$emit('applicationUpdated');
          this.$toaster.success(response.data.message);
        })
        .catch(error => {
          console.log(error.response.data);
          this.errors = error.response.data;
          this.$toaster.error("La demande n'a pu être enregistrée.");
        });
    },
    loadTemplate(){
        if (this.template !== null) {
            this.metadata = {};
            this.showTemplateFields = true;
        }
    },
    onLoadFile(e){
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.file = e.target.files[0];
    },
    deleteCurrentFile() {
      Swal.fire({
        title: "Êtes-vous sûr.e ?",
        text: "Le document sera supprimé.",
        showCancelButton: true,
        confirmButtonColor: "#003da5",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler"
      }).then(result => {
        if (result.value) {
          this.deletedFileId = this.currentFile.id;
          this.currentFile = null;
        }
      });
    },
    currentUserCan(namePermission){
      return this.currentUserPerms.includes(namePermission);
    },
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    button_label: {
      type: String,
      default: 'Nouvelle demande',
    },
    id: {
      type: Number,
      required: false,
    },
  },
}
</script>

<template>
    
  <form>
      <div class="mb-3" v-if="templates !== null" style="margin-bottom: 10px;">
          <multiselect 
              class="col" :show-labels="false" label="name" placeholder="Choisissez un type de document" 
              v-model="template" :options="templates" @input="loadTemplate" :disabled="!currentUserCan('application-update')" >
          </multiselect>
      </div>
      <div v-if="showTemplateFields === true">
          <div style="margin-top: 30px; margin-bottom: 10px; font-size: 15px;">Informations générales</div>
          <div style="margin-bottom: 10px;">Numéro</div>
          <div class="mb-3">
              <input :disabled="!currentUserCan('application-update')" type="text" v-model="metadata['numero']" class="form-control" readonly="true"/>
          </div>
          <div v-for="field in template.fields" :key="field.order">
              <div style="margin-bottom: 10px;">{{ field.placeholder }}</div>
              <div v-if="field.type !== 'select'" class="mb-3">
                  <input :disabled="!currentUserCan('application-update')" :type="field.type" v-model="metadata[field.name]" class="form-control"/>
                  <div v-if="submitted && errors !== null" style="color: red;">
                    <span v-if="errors[field.name]">{{ errors[field.name][0] }}</span>
                  </div>
              </div>
              <div v-else class="mb-3">
                  <multiselect 
                      class="col" :show-labels="false" placeholder="Sélectionnez une option" 
                      v-model="metadata[field.name]" :options="field.options" :disabled="!currentUserCan('application-update')">
                  </multiselect>
                  <div v-if="submitted && errors !== null" style="color: red;">
                    <span v-if="errors[field.name]">{{ errors[field.name][0] }}</span>
                  </div>
              </div>
          </div>
              
          <div class="mb-3">
              <div style="margin-bottom: 10px;">Document</div>
              <input :disabled="!currentUserCan('application-update')" type="file" @input="onLoadFile" id="file" class="form-control" />
              <div  v-if="currentFile" style="font-weight: 600; margin-top: 0.85rem;">
                <a v-if="currentUserCan('application-update')" href="#" @click.prevent="deleteCurrentFile()">
                  <i  class="fa fa-times" style="color: red; margin-right: 0.5rem;"></i>
                </a>
                {{ currentFile.name }}
              </div>
          </div>

          <div style="margin-top: 30px; margin-bottom: 10px; font-size: 15px;">Validation</div>
              <div class="mb-3">
                  <multiselect 
                      class="col"
                      track-by="id"
                      :show-labels="false" 
                      placeholder="Sélectionnez une option"
                      label="name"
                      v-model="workflow" 
                      :options="workflows"
                      :disabled="!currentUserCan('application-update')">
                  </multiselect>
                  <div v-if="submitted && errors !== null" style="color: red;">
                    <span v-if="errors['workflow']">{{ errors['workflow'][0] }}</span>
                  </div>
              </div>
      </div>
      <div class="modal-footer">
    <button  :disabled="!currentUserCan('application-update')"  type="button" class="btn btn-primary" @click="save()">Enregistrer</button>
  </div>
  </form>
</template>
