<script>
import axios from 'axios';
import chartsConfig from "../../../assets/js/charts-configs.js";

export default {
  data() {
    return {
      loaded: false,
      types: null,
      years: [],
      year: new Date().getFullYear(),
      series: [],
      chartOptions: chartsConfig.lineCharts,
    };
  },
  created() {
    this.getApplicationsByMonth();
  },
  methods: {
    getApplicationsByMonth(){
      this.loaded = false;
      this.chartOptions.colors = [];
      this.series = [];
      this.types = [];
      
      axios.get(`/api/dashboard/applications/charts/per-month/month/${this.year}`).then( response => {
        this.types  = [...new Set(response.data.series.map(item => item.type))];
        this.types.forEach(type => {
          var temp = [];
          temp['name'] = type;
          temp['data'] = [];
          for (let index = 1; index < 13; index++) {
            var typeObjectArray = response.data.series.filter(function (typeObject) {
                return typeObject.type == type && typeObject.month == index;
            }, type, index);

            temp['data'][index - 1] = (typeObjectArray.length == 0 || typeObjectArray == undefined) ? 0 : typeObjectArray[0].number;
          }
        
          this.series.push(temp);
          
        }, this.series, this.chartOptions);

        this.years = response.data.years.map((item) => item.year);
        this.chartOptions.colors = response.data.series.map(item => item.type_color);
        this.loaded = true;
      });
    }
  },
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <div class="float-end">
        <div class="input-group input-group-sm">
            <select 
              class="form-select form-select-sm" 
              v-model="year" 
              @change="getApplicationsByMonth()">
                <option v-for="year of years" :key="year">{{ year }}</option>
            </select>
            <label class="input-group-text"
                >Année</label
            >
        </div>
      </div>
      <div class="d-sm-flex flex-wrap">
        <h4 class="card-title mb-4">Demandes par type </h4>          
      </div>

      <apexchart
        v-if="loaded"
        class="apex-charts"
        type="bar"
        dir="ltr"
        height="360"
        :series="series"
        :options="chartOptions"
      ></apexchart>
    </div>
  </div>
</template>
