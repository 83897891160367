<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const axios = require('axios');
import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import Sidepanel from "./sidepanel";
import List from "./component/list";
import Message from "./component/view";

import { emailData } from "./data-inbox";

/**
 * Email-inbox component
 */
export default {
  components: {
    Layout,
    Sidepanel,
  },
  data() {
    return {
      sectionTitle: "Boîte de réception",
      emailData: emailData,
      paginatedEmailData: emailData,
      title: "Inbox",
      items: [
        {
          text: "Email",
          href: "/",
        },
        {
          text: "Inbox",
          active: true,
        },
      ],
      messagesData: null,
      messages: null,
      section: 'inbox',
      total: 0,
      // page number
      currentPage: 1,
      // default page size
      perPage: null,
      emailIds: [],
      // start and end index
      startIndex: 0,
      endIndex: null,
    };
  },
  computed: {
    rows() {
      return this.emailData.length;
    },
  },
  created() {
    axios.get('/api/messages?page=' + this.currentPage + '&section=' + this.section).then(response => {
      this.messagesData = response.data;
      this.messages = this.messagesData.data;
      this.startIndex = this.messagesData.from;
      this.endIndex = this.messagesData.to;
      this.perPage = this.messagesData.per_page;
      this.currentPage = this.messagesData.current_page;
      this.total = this.messagesData.total;
    });
  },
  methods: {
    setStar(id){
      axios.get('/api/message/update/' + id + '/star').then(response => {
        let message = response.data;
        this.messages = this.messages.map((item) => {
          if (item.id === message.id)
            return {...item, is_favory: message.is_favory};
          return item;
        });
      });
    },
    toTrash(id){
      Swal.fire({
        title: "Êtes-vous sûr.e ?",
        showCancelButton: true,
        confirmButtonColor: "#003da5",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer",
        cancelButtonText: "Annuler"
      }).then(result => {
        if (result.value) {
          axios.get('/api/message/update/' + id + '/trash').then(response => {
            this.messages = this.messages.filter((item) => item.id !== id);
          })
          .catch(error => {
            this.$toaster.error("Une erreur inattendue est survenue. Merci de contacter l'administrateur.");
          });
        }
      });
    },
    onPageChange() {
      axios.get('/api/messages?page=' + this.currentPage + '&section=' + this.section).then(response => {
        this.messagesData = response.data;
        this.messages = this.messagesData.data;
        this.startIndex = this.messagesData.from;
        this.endIndex = this.messagesData.to;
        this.perPage = this.messagesData.per_page;
        this.currentPage = this.messagesData.current_page;
        this.total = this.messagesData.total;
      });
    },
    updateEmailsList(section, title) {
      this.section = section;
      this.sectionTitle = title;
      this.currentPage = 1;
      axios.get('/api/messages?page=' + this.currentPage + '&section=' + this.section).then(response => {
        this.messagesData = response.data;
        this.messages = this.messagesData.data;
        this.startIndex = this.messagesData.from;
        this.endIndex = this.messagesData.to;
        this.perPage = this.messagesData.per_page;
        this.currentPage = this.messagesData.current_page;
        this.total = this.messagesData.total;
      });
    }
  },
};
</script>

<template>
    <div class="row">
      <!-- Right Sidebar -->
      <div class="col-12">
        <Sidepanel :section='section' @getEmails="updateEmailsList"/>
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
</template>
