<script>


export default {
  props: {
    percentage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isMounted: false
    }
  },
  mounted() {
    this.isMounted = true
    this.drawProgress()
  },
  watch: {
    percentage() {
      if(this.isMounted) {
        this.drawProgress()
      }
    }
  },
  methods: {
    drawProgress() {

      const circle = this.$refs.progressBar.querySelector('.progress')
      console.log('drawProgress', circle.style.strokeDashoffset)
      const percentage = circle.dataset.percentage
      circle.style.strokeDashoffset = -51 - (percentage * 51 / 100)
      console.log('drawProgress', circle.style.strokeDashoffset)
    }
  }
}
</script>

<template>
  <svg ref="progressBar" class="circular-progress-bar" viewBox="0 0 20 20">
    <circle class="bg" cx="10" cy="10" r="8"/>
    <circle class="progress" cx="10" cy="10" r="8" :data-percentage="percentage"/>
  </svg>
</template>

<style scoped>
.circular-progress-bar {
  min-width: 20px;
  max-width: 100%;
}
.circular-progress-bar circle {
  fill: none;
  stroke: #f1f1f1;
  stroke-width: 2;
}
.circular-progress-bar circle.progress
{
  fill: none;
  stroke: #4285f4;
  stroke-width: 2;
  stroke-dasharray: 51 51;
  stroke-dashoffset: -51;
  stroke-linecap: round;
}
</style>